import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Company, EventInvite } from "../DBTypes";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { StandardButton } from "./StandardButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { usePageNavigation } from "../hooks/usePageNavigation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

function getNameFromId(id: string, companies: Company[]) {
	const company = companies.find((c) => c.id === id);

	return company?.name;
}
function IconRender(invite: EventInvite) {
	if (invite.answer === -1) return <CloseIcon style={{ color: "red" }} />;
	else if (invite.answer === 1)
		return <CheckIcon style={{ color: "green" }} />;
}

function generate(invited: EventInvite[], companies: Company[]) {
	return invited.map((row) => (
		<TableRow
			key={row.sponsor_id}
			sx={{
				"&:last-child td, &:last-child th": {
					border: 0,
				},
			}}
		>
			<TableCell component="th" scope="row">
				<button onClick={() => {
					console.log(row.sponsor_id)
				}}>{getNameFromId(row.sponsor_id, companies)}</button>
			</TableCell>
			<TableCell align="right">{IconRender(row)}</TableCell>
		</TableRow>
	));
}

interface Props {
	invited: EventInvite[];
	companies: Company[];
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InvitationList(props: Props) {
	const {
		navigateToCompanyPage,
	} = usePageNavigation();
	const { invited, companies } = props

	function generate() {
		return invited.map((row) => (
			<TableRow
				key={row.sponsor_id}
				sx={{
					"&:last-child td, &:last-child th": {
						border: 0,
					},
				}}
			>
				<TableCell component="th" scope="row">
					<button onClick={() => {
						navigateToCompanyPage(row.sponsor_id)
					}}>{getNameFromId(row.sponsor_id, companies)}</button>
				</TableCell>
				<TableCell align="right">{IconRender(row)}</TableCell>
			</TableRow>
		));
	}
	if (props.open) {
		return (
			<div>
				<BootstrapDialog
					onClose={() => props.setOpen(false)}
					aria-labelledby="customized-dialog-title"
					open={props.open}
				>
					<div className="min-w-[30vw]">
						<BootstrapDialogTitle
							id="customized-dialog-title"
							onClose={() => props.setOpen(false)}
						></BootstrapDialogTitle>

						<DialogContent>
							<div>
								<Box sx={{ flexGrow: 1 }}>
									<Grid item xs={12} md={6}>
										<Typography
											sx={{ mt: 4, mb: 2 }}
											variant="h6"
											component="div"
										>
											Inbjudna sponsorer
										</Typography>

										<Paper
											sx={{
												width: "100%",
												overflow: "hidden",
											}}
										>
											<TableContainer
												sx={{
													maxHeight: 440,
												}}
											>
												<Table
													stickyHeader
													aria-label="sticky table"
												>
													<TableHead>
														<TableRow>
															<TableCell>
																<b>
																	Namn
																</b>
															</TableCell>
															<TableCell align="right">
																<b>
																	Svar
																</b>
															</TableCell>


														</TableRow>
													</TableHead>
													<TableBody>
														{generate()}
													</TableBody>
												</Table>
											</TableContainer>

										</Paper>
									</Grid>
								</Box>
							</div>
						</DialogContent>
					</div>
				</BootstrapDialog>
			</div>
		);
	} else {
		return (
			<StandardButton
				text="Visa inbjudna"
				onClick={() => {
					props.setOpen(true);
				}}
			/>
		);
	}
}
