import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Notification } from "../DBTypes";

interface Props {
	notifications: Notification[];
	onReadClick: any;
	onRemoveClick: any;
}

export const NotificationIcon = ({
	notifications,
	onReadClick,
	onRemoveClick,
}: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

	const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const [filtered, setFiltered] = useState<Notification[]>([]);

	useEffect(() => {
		let filtered: Notification[] = [];
		for (const notis of notifications) {
			if (!notis.read) {
				filtered.push(notis);
			}
		}
		setFiltered(filtered);
	}, [notifications]);

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const generateKey = (notification: Notification) => {
		if (notification.type == "event") {
			const { event_name, event_club } = notification;
			const key = `${event_club}${event_name}`.replace(/\s/g, "");
			return key;
		}
		if (notification.type == "ad") {
			const { ad_title, ad_company } = notification;
			const key = `${ad_title}${ad_company}`.replace(/\s/g, "");
			return key;
		}
		if (notification.type == "response") {
			const { event_name, responding_company, responding_user } =
				notification;
			const key =
				`${event_name}${responding_company}${responding_user}`.replace(
					/\s/g,
					""
				);
			return key;
		}
	};

	const getAnswer = (answerIndex: number) => {
		if (answerIndex === -1) return "Kommer inte";
		if (answerIndex === 0) return "Inget svar";
		if (answerIndex === 1) return "Kommer";
	};

	{
		if (notifications[0].type === "event") {
			return (
				<>
					<Box
						sx={{
							borderRadius: "50%",
							backgroundColor: "rgb(212, 32, 32)",
							width: "32px",
							height: "32px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginLeft: "4px",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "rgb(228, 103, 103)",
								transform: "scale(1.05)",
							},
						}}
						onClick={handleButtonClick}
					>
						<span style={{ fontSize: "18px", color: "white" }}>
							{filtered.length}
						</span>
					</Box>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						sx={{
							padding: "30px",
							borderRadius: "10px",
							minWidth: "50rem",
						}}
					>
						{notifications.map((notification) =>
							// For the unread notifications
							!notification.read ? (
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{!notification.isUpdate ? (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<span
												style={{
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												Ny Eventinbjudan
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Förening:{" "}
												{notification.event_club}
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Event: {notification.event_name}
											</span>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<span
												style={{
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												Eventuppdatering
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Förening:{" "}
												{notification.event_club}
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Event: {notification.event_name}
											</span>
										</Box>
									)}

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onReadClick(
												notification.event_name,
												notification.event_club,
												undefined,
												undefined
											)
										}
									>
										Markera som läst
									</Button>
									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												notification.event_name,
												notification.event_club,
												undefined,
												undefined
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							) : (
								// For the read notifications
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span
											style={{
												fontSize: "14px",
												opacity: "0.7",
												color: "grey",
											}}
										>
											{notification.event_name}
										</span>
										<span
											style={{
												opacity: "0.7",
												color: "grey",
												fontSize: "14px",
											}}
										>
											{notification.event_club}
										</span>
									</Box>

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												notification.event_name,
												notification.event_club,
												undefined,
												undefined
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							)
						)}
					</Menu>
				</>
			);
		} else if (notifications[0].type === "ad") {
			return (
				<>
					<Box
						sx={{
							borderRadius: "50%",
							backgroundColor: "rgb(212, 32, 32)",
							width: "30px",
							height: "30px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginLeft: "4px",
							marginRight: "4px",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "rgb(228, 103, 103)",
								transform: "scale(1.05)",
							},
						}}
						onClick={handleButtonClick}
					>
						<span style={{ fontSize: "18px", color: "white" }}>
							{filtered.length}
						</span>
					</Box>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						sx={{
							padding: "30px",
							borderRadius: "10px",
							minWidth: "50rem",
						}}
					>
						{notifications.map((notification) =>
							// For the unread notifications
							!notification.read ? (
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{!notification.isUpdate ? (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<span
												style={{
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												Nytt sponsorerbjudande
											</span>
											<span
												style={{
													fontSize: "14px",
													color: "grey",
												}}
											>
												{notification.ad_company} har
												ett nytt erbjudande för dig{" "}
												<br /> som sponsrar samma
												förening.
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Gäller föreningarna:{" "}
												{notification.ad_clubs?.join(
													", "
												)}
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Erbjudande:{" "}
												{notification.ad_title}
											</span>
											<span
												style={{
													fontSize: "14px",
													color: "grey",
												}}
											>
												Klicka på er gemensamma förening
												för att hitta erbjudandet.
											</span>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<span
												style={{
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												Uppdaterat erbjudande
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Sponsor:{" "}
												{notification.ad_company}
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Erbjudande:{" "}
												{notification.ad_title}
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Gäller föreningarna:{" "}
												{notification.ad_clubs?.join(
													", "
												)}
											</span>
										</Box>
									)}

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onReadClick(
												undefined,
												undefined,
												notification.ad_title,
												notification.ad_company
											)
										}
									>
										Markera som läst
									</Button>
									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												undefined,
												undefined,
												notification.ad_title,
												notification.ad_company
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							) : (
								// For the read notifications
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span
											style={{
												fontSize: "14px",
												opacity: "0.7",
												color: "grey",
											}}
										>
											{notification.ad_title}
										</span>
										<span
											style={{
												opacity: "0.7",
												color: "grey",
												fontSize: "14px",
											}}
										>
											{notification.ad_company}
										</span>
									</Box>

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												undefined,
												undefined,
												notification.ad_title,
												notification.ad_company
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							)
						)}
					</Menu>
				</>
			);
		} else if (notifications[0].type === "response") {
			return (
				<>
					<Box
						sx={{
							borderRadius: "50%",
							backgroundColor: "rgb(212, 32, 32)",
							width: "30px",
							height: "30px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginLeft: "4px",
							marginRight: "4px",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "rgb(228, 103, 103)",
								transform: "scale(1.05)",
							},
						}}
						onClick={handleButtonClick}
					>
						<span style={{ fontSize: "18px", color: "white" }}>
							{filtered.length}
						</span>
					</Box>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						sx={{
							padding: "30px",
							borderRadius: "10px",
							minWidth: "50rem",
						}}
					>
						{notifications.map((notification) =>
							// For the unread notifications
							!notification.read ? (
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{!notification.isUpdate && (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<span
												style={{
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												Nytt eventsvar
											</span>
											<span
												style={{
													fontSize: "14px",
												}}
											>
												Event: {notification.event_name}
											</span>

											<span
												style={{
													fontSize: "14px",
												}}
											>
												Sponsor:{" "}
												{
													notification.responding_company
												}
											</span>
											{notification.response && (
												<span
													style={{
														fontSize: "14px",
													}}
												>
													Svar:{" "}
													{getAnswer(
														notification.response
													)}
												</span>
											)}
										</Box>
									)}

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onReadClick(
												notification.event_name,
												notification.responding_company
											)
										}
									>
										Markera som läst
									</Button>
									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												notification.event_name,
												notification.responding_company
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							) : (
								// For the read notifications
								<MenuItem
									key={generateKey(notification)}
									sx={{
										padding: "10px",
										margin: "5px",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span
											style={{
												fontSize: "14px",
												opacity: "0.7",
												color: "grey",
											}}
										>
											{notification.event_name}
										</span>
										<span
											style={{
												opacity: "0.7",
												color: "grey",
												fontSize: "14px",
											}}
										>
											{notification.responding_company}
										</span>
									</Box>

									<Button
										sx={{
											borderRadius: "6px",
											padding: "10px",
											marginLeft: "24px",
											backgroundColor: "rgb(31,158,221)",
											color: "white",
											"&:hover": {
												backgroundColor:
													"rgb(18, 120, 221)",
											},
											fontSize: "12px",
										}}
										onClick={() =>
											onRemoveClick(
												notification.event_name,
												notification.responding_company
											)
										}
									>
										Ta Bort
									</Button>
								</MenuItem>
							)
						)}
					</Menu>
				</>
			);
		} else {
			return null;
		}
	}
};
