import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface Props {
	label: string;
	elements: string[];
	parentCallback?: any;
	defaultValues?: string[];
	error?: boolean;
}

export function CheckBoxDropdown(props: Props) {
	const [elements, setElements] = useState<string[]>(
		props.defaultValues ? props.defaultValues : []
	);

	const handleChange = (event: SelectChangeEvent<typeof elements>) => {
		const {
			target: { value },
		} = event;
		setElements(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	useEffect(() => {
		if (props.defaultValues) {
			setElements(props.defaultValues);
		}
	}, [props.defaultValues]);

	useEffect(() => {
		props.parentCallback(elements);
	}, [elements]);

	return (
		<div>
			<FormControl sx={{ width: "100%" }}>
				<InputLabel id="demo-multiple-checkbox-label">
					{props.label}
				</InputLabel>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={elements}
					onChange={handleChange}
					input={<OutlinedInput label={props.label} />}
					renderValue={(selected) => selected.join(", ")}
					MenuProps={MenuProps}
				>
					{props.elements.map((element, index) => (
						<MenuItem key={index} value={element}>
							<Checkbox
								checked={elements.indexOf(element) > -1}
							/>
							<ListItemText primary={element} />
						</MenuItem>
					))}
				</Select>
				<FormHelperText
					sx={{
						color: "var(--color-primary-red)",
					}}
				>
					{props.error && elements.length < 1
						? "*Vänligen välj minst ett alternativ från listan"
						: " "}
				</FormHelperText>
			</FormControl>
		</div>
	);
}
