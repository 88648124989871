import React, { useState } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormHelperText from "@mui/material/FormHelperText";

interface Props {
	parentCallback: any;
	title: string;
	error?: boolean;
	edit?: boolean;
	infoText?: string;
}

/**
 * File input component.
 *
 */
export function FileInput(props: Props) {
	const [fileName, setFileName] = useState<string>();

	const MAX_FILE_SIZE = 4096; // 4MB

	function handleInput(event: any) {
		let file = event.target.files[0];
		if (file) {
			const fileSizeKiloBytes = file.size / 1024;

			if (fileSizeKiloBytes > MAX_FILE_SIZE) {
				alert("Filens storlek får inte överstiga 4 MB");
			} else {
				setFileName(file.name);
				props.parentCallback(file);
			}
		}
	}
	/* clearable?
	function handleClear() {
		setFileName("");
		props.parentCallback();
	}*/

	return (
		<div className="flex items-center space-x-4">
			<div className="flex flex-col items-center">
				<Button
					variant="outlined"
					component="label"
					startIcon={<AddCircleIcon />}
				>
					{fileName || props.edit
						? "Ändra " + props.title
						: "Lägg till " + props.title}
					<input
						hidden
						accept="image/*"
						type="file"
						onChange={handleInput}
					/>
				</Button>
				{props.infoText && props.infoText}
			</div>
			<div className="flex items-center">
				{fileName ? (
					<div>
						{fileName}
						{/** clearable?
						<ClearIcon
							sx={{
								color: "#757575",
								marginLeft: "5px",
								fontSize: "15px",
							}}
							onClick={handleClear}
                        
						/>**/}
					</div>
				) : (
					props.error &&
					!props.edit && (
						<FormHelperText
							sx={{
								color: "var(--color-primary-red)",
							}}
						>
							*Lägg till en {props.title}
						</FormHelperText>
					)
				)}
			</div>
		</div>
	);
}
