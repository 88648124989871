import React from "react";
import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { InputField } from "../components/InputField";
import { Nav } from "../components/Nav";
import { StandardButton } from "../components/StandardButton";
import { useAuth } from "../hooks/useAuth";
import { useErrorMessages } from "../hooks/useErrorMessages";
import { useFirestore } from "../hooks/useFirestore";
import { usePageNavigation } from "../hooks/usePageNavigation";
import { httpsCallable } from "firebase/functions";
import { functions } from "../Firebase";

export const UpdateUserPage = () => {
	const { getUser, updateUser } = useFirestore();
	const { user } = useAuth();

	const {
		navigateToMainPage,
		navigateToCompanyHomePage,
		navigateToSuperSponsors,
		navigateToClubHomePage,
		navigateToPolicyPage,
	} = usePageNavigation();
	const [error, setError] = useState("");
	const { logout } = useAuth();

	window.onpopstate = function (event) {
		handleLogout();
	};

	const handleLogout = () => {
		logout();
		navigateToMainPage();
		window.location.reload();
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const email = e.target.email.value;
		const firstName = e.target.firstName.value;
		const lastName = e.target.lastName.value;
		const acceptTerms = e.target.acceptTerms.checked;
		try {
			if (!email || !firstName || !lastName) {
				setError("Var vänlig fyll i samtliga fält");
				return;
			} else if (!acceptTerms) {
				setError(
					"Acceptera användarvillkoren för att använda tjänsten"
				);
				return;
			} else if (email !== user?.email) {
				setError("Emailadressen matchar inte användarens email");
				return;
			}
			if (user) {
				let dbUser = await getUser(user.uid);
				await updateUser(user.uid, email, firstName, lastName);
				alert("Dina uppgifter är nu uppdaterade!");
				if (dbUser.user_type === 1) {
					navigateToCompanyHomePage();
				} else if (dbUser.user_type === 2) {
					navigateToSuperSponsors();
				} else if (dbUser.user_type === 3) {
					navigateToClubHomePage();
				}
			}
		} catch (error) {
			console.error(error);
			alert(
				"Sparandet av uppgifter misslyckades, var vänlig försök igen"
			);
		}
	};

	return (
		<div>
			<div
				style={{ width: "100%", marginTop: "70px" }}
				className="flex justify-center flex"
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						maxWidth: "40%",
						justifyContent: "center",
					}}
				>
					<b className="text-2xl">Välkommen tillbaka!</b>
					<p className="text-lg" style={{ textAlign: "center" }}>
						För att du som medlem ska kunna ta del av den
						fullständiga användarupplevelsen här på Parter.se
						behöver vi kompletterande information om dig.
					</p>
				</div>
			</div>
			<div style={{ width: "100%" }} className="flex justify-center flex">
				<form
					onSubmit={handleSubmit}
					className="flex flex-col align-middle m-5 p-10 login-form"
				>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Bekräfta email"
							type="email"
							name="email"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Förnamn"
							type="text"
							name="firstName"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Efternamn"
							type="text"
							name="lastName"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<input
							type="checkbox"
							style={{ marginRight: "10px", padding: "20px" }}
							name="acceptTerms"
						/>
						<span>
							Jag accepterar{" "}
							<span className="text-[#1e9ede]">
								<a
									href="https://parter.se/privacy_policy"
									target="_blank"
								>
									användarvillkoren
								</a>
							</span>
						</span>
					</div>
					{error && <p className="color-primary-red">{error}</p>}
					<StandardButton text="Spara" />
				</form>
			</div>
			<div style={{ width: "100%" }} className="flex justify-center flex">
				<StandardButton
					text="Fortsätt som utloggad"
					onClick={() => handleLogout()}
				/>
			</div>
		</div>
	);
};
