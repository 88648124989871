import { InputField } from "../InputField";
import { DropdownButton } from "../DropdownButton";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import { useState, useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
	onPageSearch?: boolean;
	placeholder?: string;
	parentCallback?: any;
	value?: string;
}

/**
 * Search bar with dropdown to choose search category.
 * Navigates to results page on search submit.
 *
 */
export const SearchBar = (props: Props) => {
	const { navigateToSearchResultsPage } = usePageNavigation();
	const [searchParams] = useSearchParams();

	const [searchText, setSearchText] = useState("");

	const [defaultDropdownVaule, setDefaultDropdownVaule] = useState<number>(0);

	const searchCategories = ["Föreningar", "Sponsorer"];
	const placeholders = ["Sök efter din förening", "Sök efter sponsorer"];

	const [placeholderText, setPlaceholderText] = useState<string>(
		placeholders[0]
	);
	const [categoryIndex, setCategoryIndex] = useState<number>(0);

	const searchPhrase = searchParams.get("q");
	const searchCategory = searchParams.get("category");

	const handleChange = (e: any) => {
		setSearchText(e.target.value);
	};

	const clearSearch = () => {
		props.parentCallback("");
		setSearchText("");
	};

	const handleSearchClick = (e: any) => {
		e.preventDefault();

		if (props.parentCallback) {
			props.parentCallback(searchText);
		} else {
			navigateToSearchResultsPage(
				createSearchParams({
					q: searchText,
					category: searchCategories[categoryIndex],
				}).toString()
			);
		}
	};

	useEffect(() => {
		if (props.value || props.value === "") {
			setSearchText(props.value);
		}
	}, [props.value]);
	useEffect(() => {
		setPlaceholderText(placeholders[categoryIndex]);
	}, [categoryIndex]);

	useEffect(() => {
		if (searchPhrase) {
			setSearchText(searchPhrase);
		}

		if (searchCategory) {
			let index: number = searchCategories.findIndex(
				(obj) => obj === searchCategory
			);

			if (index > -1) {
				setDefaultDropdownVaule(index);
				setCategoryIndex(index);
				setPlaceholderText(placeholders[index]);
			}
		}
	}, [searchPhrase, searchCategory]);

	return (
		<div>
			<form
				className="input-group flex items-stretch w-full place-content-center "
				onSubmit={handleSearchClick}
			>
				{!props.onPageSearch && (
					<div className="self-center">
						<DropdownButton
							elements={searchCategories}
							height={42}
							parentCallback={setCategoryIndex}
							getIndex
							default={defaultDropdownVaule}
						/>
					</div>
				)}

				<InputField
					placeholderText={
						props.placeholder ? props.placeholder : placeholderText
					}
					type="text"
					name="search"
					onChange={handleChange}
					searchBar
					value={searchText}
				/>
				{props.onPageSearch && searchText && (
					<div className="absolute flex h-full items-center right-20">
						<button>
							<ClearIcon onClick={clearSearch} />
						</button>
					</div>
				)}

				<button
					className="btn px-6 py-2 search-button shadow-md bg-color-primary-blue hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
					type="submit"
					id="button-addon2"
				>
					<svg
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="search"
						className="w-4"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path
							fill="currentColor"
							d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
						></path>
					</svg>
				</button>
			</form>
		</div>
	);
};
