import "./css/reset.css";
import "./css/colors.css";
import "./css/values.css";
import "./css/index.css";

import { AuthProvider } from "./hooks/useAuth";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { UpdatePasswordPage } from "./pages/UpdatePasswordPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { CompanyPage } from "./pages/Company/CompanyPage";
import { ClubPage } from "./pages/Club/ClubPage";
import { SearchResultsPage } from "./pages/SearchResultsPage";
import { CompanyHomePage } from "./pages/Company/CompanyHomePage";
import { ClubHomePage } from "./pages/Club/ClubHomePage";
import { ClubAdminPage } from "./pages/Club/ClubAdminPage";
import { CompanyClubPage } from "./pages/Company/CompanyClubPage";
import { CompanyInfoPage } from "./pages/Company/CompanyInfoPage";
import { ManageAdsPage } from "./pages/ManageAdsPage";
import { ManageEventsPage } from "./pages/Club/ManageEventsPage";
import { Pages } from "./hooks/usePageNavigation";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { SignupPage } from "./pages/SignupPage";
import { AboutPage } from "./pages/AboutPage";
import { Sponsors } from "./pages/Superadmin/Sponsors";
import { Clubs } from "./pages/Superadmin/Clubs";
import { AddSponsor } from "./pages/Superadmin/AddSponsor";
import { AddClub } from "./pages/Superadmin/AddClub";
import { SuperSponsorPage } from "./pages/Superadmin/SuperSponsorPage";
import { SuperClubPage } from "./pages/Superadmin/SuperClubPage";
import CookieConsent, {
	getCookieConsentValue,
	Cookies,
} from "react-cookie-consent";
import { getAnalytics } from "firebase/analytics";
import { app } from "./Firebase";
import { UpdateUserPage } from "./pages/UpdateUserPage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

/*const handleAcceptCookie = () => {
	getAnalytics(app);
};*/

function App() {
	/*useEffect(() => {
		const isConsent = getCookieConsentValue();
		if (isConsent === "true") {
			handleAcceptCookie();
		}
	}, []);*/
	return (
		<AuthProvider>
			{/*<CookieConsent enableDeclineButton onAccept={handleAcceptCookie}>
				Den här sidan använder cookies för att förbättra
				användarupplevelsen.
	</CookieConsent>*/}
			<BrowserRouter>
				<div>
					<Routes>
						<Route path={Pages.MAIN_PAGE} element={<HomePage />} />
						<Route
							path={Pages.POLICY_PAGE}
							element={<PrivacyPolicy />}
						/>
						<Route
							path={Pages.LOGIN_PAGE}
							element={<LoginPage />}
						/>
						<Route
							path={Pages.UPDATE_PASSWORD_PAGE}
							element={<UpdatePasswordPage />}
						/>

						<Route
							path={Pages.FORGOT_PASSWORD_PAGE}
							element={<ForgotPasswordPage />}
						/>
						<Route
							path={Pages.UPDATE_USER_PAGE}
							element={<UpdateUserPage />}
						/>

						<Route path={Pages.COMPANY_PAGE + "/:company_id"}>
							<Route path=":ad_id" element={<CompanyPage />} />
							<Route path="" element={<CompanyPage />} />
						</Route>
						<Route path={Pages.CLUB_PAGE + "/:club_id"}>
							<Route path=":ad_id" element={<ClubPage />} />
							<Route path="" element={<ClubPage />} />
						</Route>

						<Route
							path={Pages.SEARCH_RESULTS}
							element={<SearchResultsPage />}
						/>
						<Route
							path={Pages.SIGNUP_PAGE}
							element={<SignupPage />}
						/>
						<Route
							path={Pages.ABOUT_PAGE}
							element={<AboutPage />}
						/>

						{/* COMPANY ADMIN PAGES */}
						<Route
							element={
								<ProtectedRoute
									valid_user_type={1}
									valid_user_type2={3}
								/>
							}
						>
							<Route
								path={Pages.COMPANY_CLUB + "/:club_id"}
								element={<CompanyClubPage />}
							/>
							<Route
								path={Pages.MANAGE_ADS}
								element={<ManageAdsPage />}
							/>
							<Route
								path={Pages.MANAGE_ADS + "/:ad_id"}
								element={<ManageAdsPage />}
							/>
							<Route
								path={Pages.MANAGE_EVENTS}
								element={<ManageEventsPage />}
							/>
							<Route
								path={Pages.COMPANY_HOME}
								element={<CompanyHomePage />}
							/>
							<Route
								path={Pages.COMPANY_INFO}
								element={<CompanyInfoPage />}
							/>
						</Route>
						{/* CLUB ADMIN PAGES */}
						<Route element={<ProtectedRoute valid_user_type={3} />}>
							<Route
								path={Pages.CLUB_HOME}
								element={<ClubHomePage />}
							/>
							<Route
								path={Pages.CLUB_ADMIN}
								element={<ClubAdminPage />}
							/>
							<Route
								path={Pages.MANAGE_EVENTS}
								element={<ManageEventsPage />}
							/>
							<Route
								path={Pages.MANAGE_EVENTS + "/:event_id"}
								element={<ManageEventsPage />}
							/>
						</Route>

						{/* ADMIN PAGES */}
						<Route
							element={
								<ProtectedRoute
									valid_user_type={2}
									valid_user_type2={3}
								/>
							}
						>
							<Route
								path={Pages.SUPER_ADD_SPONSOR}
								element={<AddSponsor />}
							/>
						</Route>
						<Route element={<ProtectedRoute valid_user_type={2} />}>
							<Route
								path={Pages.SUPER_SPONSORS}
								element={<Sponsors />}
							/>

							<Route
								path={Pages.SUPER_CLUBS}
								element={<Clubs />}
							/>

							<Route
								path={Pages.SUPER_ADD_CLUB}
								element={<AddClub />}
							/>

							<Route
								path={Pages.SUPER_SPONSOR + "/:company_id"}
								element={<SuperSponsorPage />}
							/>

							<Route
								path={Pages.SUPER_CLUB + "/:club_id"}
								element={<SuperClubPage />}
							/>
						</Route>
					</Routes>
				</div>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
