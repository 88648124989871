import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomDropdownField } from "../../components/CustomDropdownField";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { ColorPicker } from "../../components/ColorPicker";
import { PostClub, SponsorGroup } from "../../DBTypes";
import { useFirestore } from "../../hooks/useFirestore";
import { REGIONS } from "../../Regions";
import { SuperAdminTemplate } from "./SuperadminTemplate";
import { MultipleInputFields } from "../../components/MultipleInputFields";

export const AddClub = () => {
	const { uploadClub } = useFirestore();
	const [organisationNumbers, setOrganisationNumbers] = useState<string[]>(
		[]
	);

	const [sponsorGroups, setSponsorGroups] = useState<SponsorGroup[]>([
		{
			name: "",
			prioritizationNumber: 1,
			organisationNumbers: [],
		},
	]);
	const [contactEmail, setContactEmail] = useState<string>();
	const [region, setRegion] = useState<string>("");
	const [clubName, setClubName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [memberUrl, setMemberUrl] = useState("");
	const [sponsorUrl, setSponsorUrl] = useState("");
	const [image, setImage] = useState<File>();
	const [backgroundImage, setBackgroundImage] = useState<File>();
	const [partnerBackgroundImage, setPartnerBackgroundImage] =
		useState<File>();
	const [isLoading, setIsLoading] = useState(false);
	const [backgroundColor, setBackgroundColor] = useState<string>();

	useEffect(() => {
		if (sponsorGroups) {
			let numbers = extractNumbers(sponsorGroups);
			setOrganisationNumbers(numbers);
		}
	}, [sponsorGroups]);

	const extractNumbers = (groups: SponsorGroup[]) => {
		const numberArray: string[] = [];
		groups.forEach((group: SponsorGroup) => {
			group.organisationNumbers.forEach((number) => {
				numberArray.push(number);
			});
		});
		return numberArray;
	};

	const handleOnSubmit = () => {
		if (region && contactEmail && clubName && image !== undefined) {
			setIsLoading(true);
			const newClub: PostClub = {
				name: clubName,
				region: region,
				description: description,
				email: contactEmail,
				member_url: memberUrl,
				sponsor_url: sponsorUrl,
				organisationNumbers: organisationNumbers,
				image: image,
				backgroundImage: backgroundImage,
				partnerBackgroundImage: partnerBackgroundImage,
				background_color: backgroundColor,
				sponsor_groups: sponsorGroups,
			};

			uploadClub(newClub)
				.then(() => {
					setIsLoading(false);
					alert("Förening är uppladdad");
					window.location.reload();
				})
				.catch((error) => {
					setIsLoading(false);
					alert(error);
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			alert(
				"Föreningens namn, kontaktperson,region och bild måste finnas med!"
			);
		}
	};

	return (
		<SuperAdminTemplate title="Lägg till förening">
			<div className="flex flex-col align-top w-96 mb-12">
				<CustomInputField
					label="Föreningens namn"
					parentCallback={setClubName}
				/>
				<CustomInputField
					label="Kontaktpersonens email"
					parentCallback={setContactEmail}
				/>
				<CustomInputField
					label="Beskrivning"
					multiline
					parentCallback={setDescription}
				/>
				<CustomDropdownField
					label="Välj region"
					elements={REGIONS}
					parentCallback={setRegion}
				/>
				<CustomInputField
					label="Länk för att bli medlem (frivilligt)"
					parentCallback={setMemberUrl}
					defaultValue={memberUrl}
				/>
				<CustomInputField
					label="Länk för att bli sponsor (frivilligt)"
					parentCallback={setSponsorUrl}
					defaultValue={sponsorUrl}
				/>
				<p className="mb-1">
					<span className="text-red-600">Notera:</span> Separera
					organisationsnummer med hjälp av radbyte. <br /> Upp till 10
					NYA organisationsnummer kan läggas till samtidigt.
				</p>
				<div className="mb-8">
					<MultipleInputFields
						groups={sponsorGroups}
						setGroups={setSponsorGroups}
					/>
				</div>
				<div className="mb-3">
					<FileInput parentCallback={setImage} title={"logga"} />
				</div>
				<b className="pt-4">Bakgrundsbilder</b>
				<p className="pb-4">
					Bakgrundsbilder som visas för privatpersoner respektive
					föreningens sponsorer. Bildförhållande 5:2 (t.ex. 1920x768).
					Notera att detaljer långt ut på sidorna inte syns på telefon
					och surfplatta.
				</p>
				<div className="mb-3">
					<FileInput
						parentCallback={setBackgroundImage}
						title={"bakgrundsbild"}
					/>
				</div>
				<div className="mb-3">
					<FileInput
						parentCallback={setPartnerBackgroundImage}
						title={"bakgrundsbild (sponsor)"}
					/>
				</div>
				<div className="mb-6 flex flex-col space-y-2">
					<b>Bakgrundsfärg</b>
					<ColorPicker
						defaultColor={backgroundColor}
						parentCallback={setBackgroundColor}
					/>
				</div>

				{isLoading ? (
					<CircularProgress color="inherit" size={20} />
				) : (
					<StandardButton
						text="Lägg till förening"
						onClick={handleOnSubmit}
					/>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
