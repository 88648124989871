import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pages } from "../../hooks/usePageNavigation";
import { Nav } from "../Nav";

export const PageLocked = () => {
	const [isVerifyingLogin, setIsVerifyingLogin] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setIsVerifyingLogin(false);
		}, 3000);
	}, []);

	return (
		<div>
			<Nav />
			<div className="flex justify-center">
				{isVerifyingLogin ? (
					<div className="flex flex-row align-middle space-x-3">
						<p>Verifierar inloggning</p>
						<CircularProgress color="inherit" size={20} />
					</div>
				) : (
					<p>
						Du verkar inte vara inloggad.{" "}
						<Link
							className="color-primary-blue"
							to={Pages.LOGIN_PAGE}
						>
							Klicka här
						</Link>{" "}
						för att gå till inloggningssidan
					</p>
				)}
			</div>
		</div>
	);
};
