import { ParterCard } from "./ParterCard";
import { ParterData } from "../ParterData";
import { useEffect, useState } from "react";
import Dialog from "../Dialog";
import CompanyDialog from "../CompanyDialog";
import { Ad, Company, Club, Event } from "../../DBTypes";
import { useParams } from "react-router-dom";
import EventDialog from "../EventDialog";

interface Props {
	cards: ParterData[];
	fromLink?: boolean;
	setFromLink?: any;
	loggedIn?: boolean;
}

/**
 * Grid view of cards
 * TODO: improve alignment when scaling window. Use queried data instead.
 */
export const ParterGrid = (props: Props) => {
	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [showCompanyDialog, setShowCompanyDialog] = useState<boolean>(false);
	const [showEventDialog, setShowEventDialog] = useState<boolean>(false);

	const { ad_id } = useParams<string>();

	const initialDialogObject: Ad = {
		id: "",
		category: "",
		club_ids: [],
		club_names: [],
		description: "",
		image_url: "",
		clubOfferInstructions: [],
		title: "",
		company_id: "",
		company_name: "",
		isForCompanies: false,
		regions: [],
	};

	const initialCompany: Company = {
		id: "",
		isOnFrontPage: false,
		name: "",
		description: "",
		image_url: "",
		relatedClubs: [],
		organisationNumber: "",
	};

	const initialEvent: Event = {
		id: "",
		club_id: "",
		club_name: "",
		title: "",
		date: new Date(),
		street: "",
		area: "",
		description: "",
		sponsor_ids: [],
		invited: [],
		image_url: "",
	};

	const [dialogObject, setDialogObject] = useState<Ad>(initialDialogObject);
	const [companyDialogObject, setCompanyDialogObject] =
		useState<Company>(initialCompany);

	const [eventDialogObject, setEventDialogObject] =
		useState<Event>(initialEvent);

	useEffect(() => {
		if (ad_id) {
			const card = props.cards.find((c) => {
				return c.object.id === ad_id;
			});
			if (props.fromLink && card && isAd(card.object)) {
				setDialogObject(card.object);
				setShowDialog(true);
			}
		}
	}, [ad_id]);

	const handleCloseDialog = () => {
		if (props.setFromLink) {
			props.setFromLink(false);
		}
		setShowDialog(false);
		setShowCompanyDialog(false);
		setShowEventDialog(false);
	};
	const handleOpenDialog = (param: any) => {
		setShowDialog(true);
		setDialogObject(param);
	};
	const handleOpenCompanyDialog = (param: any) => {
		setShowCompanyDialog(true);
		setCompanyDialogObject(param);
	};
	const handleOpenEventDialog = (param: any) => {
		setShowEventDialog(true);
		setEventDialogObject(param);
	};
	const isAd = (card: Ad | Club | Company | Event): card is Ad => {
		return true;
	};
	const isEvent = (card: Ad | Club | Company | Event): card is Event => {
		return true;
	};

	return (
		<div className="overflow-hidden">
			<div
				className={
					"grid grid-cols-1 gap-6 sm:grid-cols-2 mymd:grid-cols-3 py-10"
				}
			>
				{props.cards.map((card, index) => (
					<ParterCard
						key={index}
						type={card.type}
						companyDialog={card.companyDialog}
						object={card.object}
						onClick={() => {
							if (
								card.type === "ad" ||
								card.type === "editable_ad"
							) {
								handleOpenDialog(card.object);
							} else if (card.type === "company") {
								handleOpenCompanyDialog(card.object);
							} else if (
								(card.type === "event" ||
									card.type === "editable_event") &&
								isEvent(card.object)
							) {
								handleOpenEventDialog(card.object);
							}
						}}
					/>
				))}
			</div>
			<Dialog
				open={showDialog}
				handleClose={handleCloseDialog}
				object={dialogObject}
				loggedIn={props.loggedIn}
			/>
			<CompanyDialog
				open={showCompanyDialog}
				handleClose={handleCloseDialog}
				object={companyDialogObject}
			/>
			<EventDialog
				open={showEventDialog}
				handleClose={handleCloseDialog}
				object={eventDialogObject}
			/>
		</div>
	);
};
