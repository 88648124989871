import ad_IMG from "../icons/placeholder-ad.jpg";

interface Props {
	show?: boolean;
}
export function BannerAd(props: Props) {
	return (
		<div className={"ad-container"}>
			{props.show ? (
				<img
					className={"object-scale-down cursor-hover px-[3%] py-[2%]"}
					src={ad_IMG}
					alt="Annons"
				/>
			) : (
				<div className={"h-0 sm:h-6"}></div>
			)}
		</div>
	);
}
