import background from "../../icons/Backgrounds/header-background.jpg";
import { HeaderTitle } from "./HeaderTitle";
import { SearchBar } from "./SearchBar";
import { Company, Club } from "../../DBTypes";
import { useState, useEffect } from "react";

interface Props {
	object?: Company | Club;
	title?: string;
	noTitle?: boolean;
	type?: string;
	parentCallback?: any;
	searchValue?: string;
	backgroundImage?: string;
}

const isCompany = (object: Club | Company): object is Company => {
	return true;
};
const isClub = (object: Club | Company): object is Club => {
	return true;
};

/**
 * Header with title and search bar.
 * Has different styling if passed a Club or Company object as props
 *
 */
export const Header = (props: Props) => {
	const hideLogo = () => {
		if (props.object && isClub(props.object) && props.object?.hideLogo)
			return true;

		return false;
	};

	let centerText: boolean = props.type ? false : true;

	let placeholder: string =
		props.type === "company"
			? "Sök efter förening"
			: props.type === "club" || props.type === "club-company"
			? "Sök efter sponsor"
			: "";

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [image_url, setImage_url] = useState<string>("");

	useEffect(() => {
		if (props.object) {
			setName(props.object.name);
			setDescription(props.object.description);
			setImage_url(props.object.image_url);
		}
	}, [props.object]);
	return (
		<div
			className={
				"overflow-hidden flex flex-col bg-center min-h-[250px]" +
				(props.type
					? " flex flex-col-reverse sm:flex-row items-center justify-between "
					: " items-center justify-center") +
				(props.backgroundImage
					? " w-full aspect-auto sm:aspect-[5/2] "
					: " h-full header")
			}
			style={{
				backgroundImage: props.backgroundImage
					? `url(${props.backgroundImage})`
					: `url(${background})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
			}}
		>
			<div
				className={
					"flex flex-col " +
					(props.type
						? " items-center sm:items-start w-[55%] sm:w-[100vw] pl-0 sm:pl-[5%] my-6 "
						: " items-center w-[50%]")
				}
			>
				<div className={props.type ? "hidden sm:block" : ""}>
					{!props.noTitle && (
						<HeaderTitle
							text={
								props.title
									? props.title
									: "Alla dina föreningserbjudanden samlade på en plats!"
							}
							centerText={centerText}
						/>
					)}
				</div>
				<div
					className={
						"mt-[5%] scale-75 sm:scale-75 md:scale-100 " +
						(props.type
							? "origin-center sm:origin-top-left"
							: " origin-center")
					}
				>
					{props.type !== "company-home" &&
						props.type !== "club-home" && (
							<SearchBar
								onPageSearch={props.type ? true : false}
								placeholder={placeholder}
								parentCallback={props.parentCallback}
								value={props.searchValue}
							/>
						)}
				</div>
			</div>

			{props.type &&
				!(
					hideLogo() &&
					props.object?.description === "" &&
					props.type !== "club-company"
				) && (
					<div className="flex w-full sm:h-full justify-end ">
						<div className="bg-black bg-opacity-70 w-[100%] sm:w-[80%] pb-10 sm:min-h-[300px]">
							<div className="w-full flex flex-col whitespace-pre-wrap">
								<div className="flex w-full justify-between items-center">
									<p className="text-white mx-4 mt-2 xl:mt-6 lg:mx-5 xl:mx-10 text-sm sm:text-base lg:text-lg xl:text-2xl">
										{name}
									</p>
									{image_url && !hideLogo() && (
										<div>
											<img
												className={
													"p-2 max-w-[100px] max-h-[50px] md:max-w-[150px] md:max-h-[75px] xl:max-w-[250px] xl:max-h-[150px] object-scale-down justify-self-end m-2 md:m-3 lg:m-5 " +
													(props.object &&
													isCompany(props.object) &&
													props.object.whiteLogo
														? ""
														: "bg-white")
												}
												src={image_url}
												alt={
													props.object
														? props.object.name
														: "sss"
												}
											/>
										</div>
									)}
								</div>
								<p className="text-white mx-4 mt-0 xl:mt-2 lg:mx-5 xl:mx-10 text-xs md:text-xs lg:text-sm xl:text-base">
									{description}
								</p>
							</div>
						</div>
					</div>
				)}
		</div>
	);
};
