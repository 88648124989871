import { CircularProgress } from "@mui/material";
import {
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
	User,
	UserCredential,
} from "firebase/auth";
import { doc, getDoc, getDocs } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DBUser, FullUser } from "../DBTypes";
import { auth, db } from "../Firebase";
import { useFirestore } from "./useFirestore";
import { usePageNavigation } from "./usePageNavigation";

const AuthenticationContext = createContext<FullUser | null>(null);

type UseAuthProps = {
	user: FullUser | null;
	login: (email: string, password: string) => Promise<UserCredential>;
	logout: () => Promise<void>;
	resetPassword: (email: string) => Promise<void>;
	updateUserCompanyID: (newCompanyID: string) => void;
};

const useAuth = (): UseAuthProps => {
	const login = async (
		email: string,
		password: string
	): Promise<UserCredential> => {
		return signInWithEmailAndPassword(auth, email, password);
	};

	const logout = async (): Promise<void> => {
		return signOut(auth);
	};

	const resetPassword = async (email: string): Promise<void> => {
		return sendPasswordResetEmail(auth, email);
	};

	const user = useContext(AuthenticationContext);

	const updateUserCompanyID = (newCompanyID: string) => {
		if (user) {
			user.firestoreUser.company_id = newCompanyID;
		}
	};

	return { user, login, logout, resetPassword, updateUserCompanyID };
};

interface AuthProviderProps {
	children: any;
}

/**
 * AuthProvider keeps the current user in a context which can be retrieved through the whole application
 */
const AuthProvider = (props: AuthProviderProps) => {
	let firestore = useFirestore();
	const [currentUser, setCurrentUser] = useState<FullUser | null>(null);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const companyID = urlParams.get("company_id"); // Använd dessa
	const clubID = urlParams.get("club_id");
	const [loading, setLoading] = useState<boolean>(true);

	/**
	 * Updates user automatically when auth state changes
	 */
	useEffect(() => {

		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				const docRef = doc(db, "users", user.uid);
				// If document is loaded, check if there is data
				// 	If there is data, add it to currentUser
				//	Else, create new user in firestore
				// Else, set user to null
				getDoc(docRef)
					.then((resp) => {
						if (resp.exists()) {
							const firestoreUser: DBUser = resp.data() as DBUser;
							const fullUser: FullUser = {
								firestoreUser: firestoreUser,
								uid: user.uid,
								email: user.email!,
							};
							setCurrentUser(fullUser);
						}
					})
					.catch((error) => {
						setCurrentUser(null);
					}).finally(() => {
						setLoading(false)
					});
			} else {
				setCurrentUser(null);
				setLoading(false)
			}
		});

		return unsubscribe;
	}, []);

	return (
		<AuthenticationContext.Provider value={currentUser}>
			{loading ? (
				<div className="flex h-screen items-center justify-center">
					<CircularProgress />
				</div>
			) : (props.children)}

		</AuthenticationContext.Provider>
	);
};

export { AuthProvider, useAuth };
