import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomDropdownField } from "../../components/CustomDropdownField";
import { MultipleInputFields } from "../../components/MultipleInputFields";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { ColorPicker } from "../../components/ColorPicker";
import { Club, SponsorGroup, Company, SponsorDealInfo } from "../../DBTypes";
import { useFirestore } from "../../hooks/useFirestore";
import { REGIONS } from "../../Regions";
import { SuperAdminTemplate } from "../Superadmin/SuperadminTemplate";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import { useAuth } from "../../hooks/useAuth";
import { InputField } from "../../components/InputField";
import { FindClubSponsors } from "../../hooks/frontendSearch";
import "react-confirm-alert/src/react-confirm-alert.css";
import { SponsorDealDialog } from "../../components/SponsorDealDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export const ClubAdminPage = () => {
	const auth = useAuth();
	const user = auth.user;

	const [club, setClub] = useState<Club>();

	const { club_id } = useParams<string>();
	const {
		navigateToCompanyHomePage,
		navigateToSuperAddSponsor,
		navigateToUpdatePasswordPage,
	} = usePageNavigation();
	const {
		getClubs,
		updateClub,
		getCompaniesFromClub,
		getAllCompanies,
		setUserCompanyID,
		uploadClubDeal,
		updateClubDeal,
		getDealByCompanyId,
	} = useFirestore();

	const [errorLoadingClub, setErrorLoadingClub] = useState<string>("");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingEdit, setIsLoadingEdit] = useState(false);
	const [gettingSponsors, setGettingSponsors] = useState(true);

	const [clubName, setClubName] = useState("");
	const [description, setDescription] = useState("");
	const [email, setEmail] = useState<string>();
	const [region, setRegion] = useState("");
	const [memberUrl, setMemberUrl] = useState("");
	const [sponsorUrl, setSponsorUrl] = useState("");
	const [organisationNumbers, setOrganisationNumbers] = useState<string[]>();
	const [sponsorGroups, setSponsorGroups] = useState<SponsorGroup[]>([]);

	const [image, setImage] = useState<File>();
	const [hideLogo, setHideLogo] = useState<boolean>(false);
	const [backgroundImage, setBackgroundImage] = useState<File | string>();
	const [partnerBackgroundImage, setPartnerBackgroundImage] = useState<
		File | string
	>();
	const [backgroundColor, setBackgroundColor] = useState<string>();

	const [searchInput, setSearchInput] = useState<string>("");
	const [companies, setCompanies] = useState<Company[]>([]);
	const [clubCompanies, setClubCompanies] = useState<Company[]>([]);
	const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
	const [selectedTitle, setSelectedTitle] = useState<number>(0);

	// Hooks for the sponsor deal info
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [currentDeal, setCurrentDeal] = useState<SponsorDealInfo>();
	// const [price, setPrice] = useState<number>();
	// const [expiryDate, setExpiryDate] = useState<Date>();
	// const [clubObligations, setClubObligations] = useState<string>();
	// const [other, setOther] = useState<string>();
	const [companyId, setCompanyId] = useState<string>();

	useEffect(() => {
		if (user && user.firestoreUser.club_id) {
			getClubs([user.firestoreUser.club_id])
				.then((resp) => {
					setClub(resp[0]);
				})
				.catch((error) => {
					setIsLoading(false);
					setErrorLoadingClub(error);
				});
		} else {
			setErrorLoadingClub("Kunde inte ladda in förening, försök igen");
		}
	}, []);

	const handleOnSearchBarChange = (e: any) => {
		setSearchInput(e.target.value);
	};
	useEffect(() => {
		if (searchInput === "") {
			setFilteredCompanies([]);
		}
	}, [searchInput]);

	const filterCompanies = () => {
		const searchInputLowerCase = searchInput.toLowerCase();
		const tmpFilteredCompanies: Company[] = [];

		companies.forEach((company) => {
			const companyName = company.name.toLowerCase();
			if (companyName.includes(searchInputLowerCase)) {
				tmpFilteredCompanies.push(company);
			}
		});

		setFilteredCompanies(tmpFilteredCompanies);
	};

	useEffect(() => {
		if (sponsorGroups) {
			let numbers = extractNumbers(sponsorGroups);
			setOrganisationNumbers(numbers);
		}
	}, [sponsorGroups]);

	useEffect(() => {
		if (club) {
			getCompaniesFromClub(club.id)
				.then((resp) => {
					setClubCompanies(resp);
					setGettingSponsors(false);
				})
				.catch((error) => {
					setGettingSponsors(false);
					console.log(error);
				});

			setClubName(club.name);
			setDescription(club.description);
			setRegion(club.region);
			setBackgroundColor(club.background_color);

			setOrganisationNumbers(club.organisationNumbers);
			if (club.member_url) setMemberUrl(club.member_url);
			if (club.sponsor_url) setSponsorUrl(club.sponsor_url);

			if (club.hideLogo) {
				setHideLogo(club.hideLogo);
			}

			if (club.sponsor_groups) {
				setSponsorGroups(club.sponsor_groups);
			} else if (club.organisationNumbers.length > 0) {
				setSponsorGroups([
					{
						name: "",
						prioritizationNumber: 1,
						organisationNumbers: club.organisationNumbers,
					},
				]);
			} else {
				setSponsorGroups([
					{
						name: "",
						prioritizationNumber: 1,
						organisationNumbers: [],
					},
				]);
			}

			setIsLoading(false);
		}
	}, [club]);

	const extractNumbers = (groups: SponsorGroup[]) => {
		const numberArray: string[] = [];
		groups.forEach((group: SponsorGroup) => {
			group.organisationNumbers.forEach((number) => {
				numberArray.push(number);
			});
		});
		return numberArray;
	};

	const handleOnSubmit = () => {
		if (club && region && clubName) {
			setIsLoadingEdit(true);
			updateClub(
				club.id,
				clubName,
				description,
				region,
				image,
				club.image_url,
				organisationNumbers,
				club.organisationNumbers,
				memberUrl,
				sponsorUrl,
				backgroundImage,
				partnerBackgroundImage,
				club.background_url,
				club.partner_background_url,
				backgroundColor,
				sponsorGroups,
				email,
				hideLogo
			)
				.then(() => {
					setIsLoadingEdit(false);
					alert("Föreningen har uppdaterats");
					if (club_id)
						getClubs([club_id]).then((resp) => setClub(resp[0]));
					window.location.reload();
				})
				.catch((error) => {
					setIsLoadingEdit(false);
					alert(error);
				});
		} else {
			alert("Föreningens namn och region måste finnas med!");
		}
	};
	const onGoToCompanyPageClick = (companyID: string) => {
		if (user) {
			setUserCompanyID(user.uid, companyID)
				.then(() => {
					auth.updateUserCompanyID(companyID);
					navigateToCompanyHomePage();
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const handleSearchClick = (e: any) => {
		if (companies.length < 1 && searchInput.length > 1) {
			getAllCompanies().then((resp) => {
				setCompanies(resp);
			});
		}
		filterCompanies();
		e.preventDefault();
	};
	useEffect(() => {
		filterCompanies();
	}, [companies]);

	const toggleDealInfo = async (
		clubId: string | undefined,
		companyId: string
	) => {
		if (clubId) {
			const resp = await getDealByCompanyId(clubId, companyId);
			if (resp) {
				setCurrentDeal(resp);
			} else {
				setCurrentDeal(undefined);
			}
			setCompanyId(companyId);
			setOpenDialog(true);
		}
	};

	const handleDialogSubmit = async (
		price: number,
		expiry_date: Date,
		clubObligations: string,
		other: string,
		pdf: File
	) => {
		if (club?.id && companyId) {
			const newDeal: SponsorDealInfo = {
				companyId: companyId,
				price: price,
				expiry_date: expiry_date as Date,
				clubObligations: clubObligations,
				other: other,
			};
			const resp = await getDealByCompanyId(club.id, companyId);
			if (!resp) {
				try {
					await uploadClubDeal(club.id, newDeal, pdf).then(() => {
						setOpenDialog(false);
					});
				} catch (err) {
					console.log(err);
					setOpenDialog(false);
				}
			} else if (resp) {
				try {
					await updateClubDeal(club.id, newDeal, pdf).then(() => {
						setOpenDialog(false);
					});
				} catch (err) {
					console.log(err);
				}

				setOpenDialog(false);
			}
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	return (
		<SuperAdminTemplate title={club ? club.name : "Hämtar förening"}>
			<div className="flex flex-col align-top w-[50vw]">
				{isLoading && !errorLoadingClub ? (
					<CircularProgress color="inherit" />
				) : (
					<>
						{errorLoadingClub ? (
							<p>{errorLoadingClub}</p>
						) : (
							<div className="flex w-full space-x-8">
								<div className="flex flex-col align-top w-[55%] pb-12">
									<CustomInputField
										label="Föreningens namn"
										parentCallback={setClubName}
										defaultValue={clubName}
									/>
									<CustomInputField
										label="Beskrivning"
										multiline
										parentCallback={setDescription}
										defaultValue={description}
									/>
									<CustomDropdownField
										label="Välj region"
										elements={REGIONS}
										parentCallback={setRegion}
										defaultValue={region}
									/>
									<CustomInputField
										label="Länk för att bli medlem (frivilligt)"
										parentCallback={setMemberUrl}
										defaultValue={memberUrl}
									/>
									<CustomInputField
										label="Länk för att bli sponsor (frivilligt)"
										parentCallback={setSponsorUrl}
										defaultValue={sponsorUrl}
									/>
									<p className="mb-1">
										<span className="text-red-600">
											Notera:
										</span>{" "}
										Separera organisationsnummer med hjälp
										av radbyte. <br /> Upp till 10 NYA
										organisationsnummer kan läggas till
										samtidigt.
									</p>
									<div className="mb-8">
										<MultipleInputFields
											groups={sponsorGroups}
											setGroups={setSponsorGroups}
										/>
									</div>
									<div className="mb-3">
										<div className="flex">
											<FileInput
												edit={true}
												parentCallback={setImage}
												title={"logga"}
											/>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={hideLogo}
															onChange={(e) =>
																setHideLogo(
																	e.target
																		.checked
																)
															}
														/>
													}
													label="Visa inte logga"
												/>
											</FormGroup>
										</div>
									</div>
									<b className="pt-4">Bakgrundsbilder</b>
									<p className="pb-4">
										Bakgrundsbilder som visas för
										privatpersoner respektive föreningens
										sponsorer. Bildförhållande 5:2 (t.ex.
										1920x768). Notera att detaljer långt ut
										på sidorna inte syns på telefon och
										surfplatta.
									</p>

									<div className="mb-3 flex">
										<FileInput
											edit={
												club && club.background_url
													? true
													: false
											}
											parentCallback={setBackgroundImage}
											title={"bakgrundsbild"}
										/>
										{club &&
											club.background_url &&
											!backgroundImage && (
												<div className="flex space-x-2">
													<img
														className={
															"max-w-[60px] max-h-[60px]"
														}
														src={
															club.background_url
														}
														alt={"s"}
													/>
													<button
														onClick={() => {
															setBackgroundImage(
																"NONE"
															);
														}}
													>
														Ta bort
													</button>
												</div>
											)}
									</div>
									<div className="mb-3 flex pb-4">
										<FileInput
											edit={
												club &&
												club.partner_background_url
													? true
													: false
											}
											parentCallback={
												setPartnerBackgroundImage
											}
											title={"bakgrundsbild (sponsor)"}
										/>
										{club &&
											club.partner_background_url &&
											!partnerBackgroundImage && (
												<div className="flex space-x-2">
													<img
														className={
															"max-w-[60px] max-h-[60px]"
														}
														src={
															club.partner_background_url
														}
														alt={"s"}
													/>
													<button
														onClick={() => {
															setPartnerBackgroundImage(
																"NONE"
															);
														}}
													>
														Ta bort
													</button>
												</div>
											)}
									</div>
									<div className="mb-6 flex flex-col space-y-2">
										<b>Bakgrundsfärg</b>
										<div className="flex">
											<ColorPicker
												defaultColor={backgroundColor}
												parentCallback={
													setBackgroundColor
												}
											/>
											<div></div>
										</div>
									</div>
									{isLoadingEdit ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : (
										<StandardButton
											text="Ändra föreningens uppgifter"
											onClick={handleOnSubmit}
										/>
									)}
									<div className="mb-16"></div>
									<div>
										<StandardButton
											text="Byt lösenord"
											onClick={
												navigateToUpdatePasswordPage
											}
										/>
									</div>
								</div>

								<div className="flex flex-col align-top w-[50%] ">
									<div className="flex space-x-8 pb-8">
										<button
											className={
												"font-bold text-sm sm:text-lg mymd:text-xl mx-4" +
												(selectedTitle === 0
													? ""
													: " color-text-gray")
											}
											onClick={() => setSelectedTitle(0)}
										>
											Mina sponsorer
										</button>
										<button
											className={
												"font-bold text-sm sm:text-lg mymd:text-xl mx-4" +
												(selectedTitle === 1
													? ""
													: " color-text-gray")
											}
											onClick={() => setSelectedTitle(1)}
										>
											Alla sponsorer
										</button>
									</div>
									{selectedTitle === 0 ? (
										<div>
											{clubCompanies.length > 0 ? (
												<div className="w-80 striped">
													{clubCompanies.map(
														(company) => {
															return (
																<div
																	key={
																		company.id
																	}
																	className="pl-3 text-lg cursor-hover standard-hover-opacity"
																	style={{
																		display:
																			"flex",
																		justifyContent:
																			"space-between",
																		alignItems:
																			"center",
																	}}
																>
																	<p
																		onClick={() =>
																			onGoToCompanyPageClick(
																				company.id
																			)
																		}
																	>
																		{
																			company.name
																		}
																	</p>
																	<span
																		style={{
																			color: "blue",
																		}}
																		onClick={() =>
																			toggleDealInfo(
																				club?.id,
																				company.id
																			)
																		}
																	>
																		<InfoRoundedIcon
																			sx={{
																				color: "#4C9CD8",
																				"&:hover":
																					{
																						transform:
																							"scale(1.2)",
																					},
																			}}
																		/>
																	</span>
																</div>
															);
														}
													)}
												</div>
											) : (
												<div>
													{gettingSponsors ? (
														<div className="flex items-center justify-center">
															<CircularProgress />
														</div>
													) : (
														<div>
															<div className="w-80">
																<p className=" text-sm">
																	Hitta eller
																	lägg till
																	sponsorer
																	under "Alla
																	sponsorer"
																	och lägg
																	sedan till
																	deras
																	organisationsnummer
																</p>
															</div>
														</div>
													)}
												</div>
											)}
										</div>
									) : (
										<div>
											<form
												className="input-group flex items-stretch w-full"
												onSubmit={handleSearchClick}
											>
												<InputField
													placeholderText="Sök efter sponsor..."
													type="text"
													onChange={
														handleOnSearchBarChange
													}
												/>
												<button
													className="btn px-6 py-2 search-button shadow-md bg-color-primary-blue hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
													type="submit"
													id="button-addon2"
												>
													<svg
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="search"
														className="w-4"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
													>
														<path
															fill="currentColor"
															d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
														></path>
													</svg>
												</button>
											</form>

											<div className="my-2 flex justify-start">
												<StandardButton
													text="Ny sponsor"
													onClick={
														navigateToSuperAddSponsor
													}
												/>
											</div>
											<div className="w-80 striped">
												{filteredCompanies.map(
													(company) => {
														return (
															<div
																key={company.id}
																onClick={() =>
																	navigator.clipboard.writeText(
																		company.organisationNumber
																	)
																}
																className="pl-3 text-lg cursor-hover standard-hover-opacity"
															>
																<p>
																	{
																		company.name
																	}
																</p>
																<div className="flex w-full space-x-2 items-center">
																	<p>
																		{
																			company.organisationNumber
																		}
																	</p>
																	<p className="text-sm">
																		(Klicka
																		för att
																		kopiera)
																	</p>
																</div>
															</div>
														);
													}
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<SponsorDealDialog
				open={openDialog}
				handleClose={handleClose}
				handleSubmit={handleDialogSubmit}
				deal={currentDeal}
			/>
		</SuperAdminTemplate>
	);
};
