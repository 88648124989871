import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState, useEffect } from "react";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { PostCompany } from "../../DBTypes";
import { useFirestore } from "../../hooks/useFirestore";
import { SuperAdminTemplate } from "./SuperadminTemplate";
import { CATEGORIES } from "../../Categories";
import { CheckBoxDropdown } from "../../components/CheckBoxDropdown";
import { useAuth } from "../../hooks/useAuth";

export const AddSponsor = () => {
	const [companyName, setCompanyName] = useState<string>();
	const [description, setDescription] = useState<string>("");
	const [contactEmail, setContactEmail] = useState<string>();
	const [organisationNumber, setOrganisationNumber] = useState<string>();
	const [showOnStartPage, setShowOnStartPage] = useState<boolean>(false);
	const [whiteLogo, setWhiteLogo] = useState<boolean>(false);
	const [image, setImage] = useState<File>();
	const [categories, setCategories] = useState<string[]>([]);
	const [address, setAddress] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [websiteURL, setWebsiteURL] = useState<string>("");

	const [isLoading, setIsLoading] = useState(false);

	const [userType, setUserType] = useState<number>();

	const { uploadCompany, getUser } = useFirestore();

	const auth = useAuth();
	const user = auth.user;

	useEffect(() => {
		if (user) {
			getUser(user.uid).then((user) => {
				setUserType(user.user_type);
			});
		}
	}, [user]);

	const handleOnSubmit = () => {
		if (companyName && contactEmail && organisationNumber && image) {
			setIsLoading(true);

			const company: PostCompany = {
				name: companyName,
				description: description,
				email: contactEmail,
				organisationNumber: organisationNumber,
				isOnFrontPage: showOnStartPage,
				whiteLogo: whiteLogo,
				image: image,
				categories: categories,
				address: address,
				phoneNumber: phoneNumber,
				website_url: websiteURL,
			};

			uploadCompany(company)
				.then(() => {
					setIsLoading(false);
					alert("Företaget har skapats");
					window.location.reload();
				})
				.catch((error) => {
					setIsLoading(false);
					alert(error);
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			alert(
				"Företagets namn, kontaktperson, organisationsnummer och bild måste finnas med!"
			);
		}
	};

	return (
		<SuperAdminTemplate title="Lägg till sponsor">
			<div className="flex flex-col align-top w-96 mb-12">
				<CustomInputField
					label="Företagets namn"
					parentCallback={setCompanyName}
				/>
				<CustomInputField
					label="Kontaktpersonens email"
					parentCallback={setContactEmail}
				/>
				<CustomInputField
					label="Beskrivning"
					multiline
					parentCallback={setDescription}
				/>
				<CustomInputField
					label="Organisationsnummer"
					parentCallback={setOrganisationNumber}
				/>
				<CheckBoxDropdown
					label="Kategori(er)"
					elements={CATEGORIES}
					parentCallback={setCategories}
					defaultValues={categories}
				/>
				<CustomInputField label="Adress" parentCallback={setAddress} />
				<CustomInputField
					label="Telefonnummer"
					parentCallback={setPhoneNumber}
				/>
				<CustomInputField
					label="Hemsida (URL)"
					parentCallback={setWebsiteURL}
				/>
				{userType === 2 && (
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(e) =>
										setShowOnStartPage(e.target.checked)
									}
								/>
							}
							label="Visa på startsidan"
						/>
					</FormGroup>
				)}
				<div className="mb-3">
					<FileInput parentCallback={setImage} title={"logga"} />
				</div>
				<div className="flex mb-4 items-center">
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={whiteLogo}
									onChange={(e) =>
										setWhiteLogo(e.target.checked)
									}
								/>
							}
							label="Vit logga"
						/>
					</FormGroup>
					<Tooltip
						title="Vit logga markeras då den valda loggan är vit utan konturer och behöver en mörk bakgrund för att synas mot vitt."
						placement="top"
					>
						<HelpOutlineIcon />
					</Tooltip>
				</div>
				{isLoading ? (
					<CircularProgress color="inherit" size={20} />
				) : (
					<StandardButton
						text="Lägg till sponsor"
						onClick={handleOnSubmit}
					/>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
