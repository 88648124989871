import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

interface Props {
	label: string;
	multiline?: boolean;
	maxLength?: number;
	parentCallback?: any;
	error?: boolean;
	defaultValue?: string;
	noHelper?: boolean;
	type?: string;
}

/**
 * Input field based on MUI components.
 *
 */
export function CustomInputField(props: Props) {
	const [value, setValue] = useState<string>(
		props.defaultValue ? props.defaultValue : ""
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
		if (props.parentCallback) {
			props.parentCallback(event.target.value);
		}
	};

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue);
			if (props.parentCallback) {
				props.parentCallback(props.defaultValue);
			}
		}
	}, [props.defaultValue]);

	return (
		<Box
			sx={{
				"& .MuiTextField-root": { width: "100%" },
			}}
		>
			<div>
				<TextField
					id="outlined-multiline-flexible"
					label={props.label}
					multiline={props.multiline}
					rows={4}
					value={value}
					type={props.type}
					onChange={handleChange}
					inputProps={{ maxLength: props.maxLength }}
				/>
				{!props.noHelper && (
					<FormHelperText
						sx={{
							color: "var(--color-primary-red)",
						}}
					>
						{props.error && value === ""
							? "*Vänligen fyll i detta fält"
							: " "}
					</FormHelperText>
				)}
			</div>
		</Box>
	);
}
