import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { StandardButton } from "./StandardButton";
import { CustomDropdownField } from "./CustomDropdownField";
import { Ad, Club } from "../DBTypes";
import { useFirestore } from "../hooks/useFirestore";
import { useParams } from "react-router-dom";
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterIcon,
	TwitterShareButton,
} from "next-share";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

interface Props {
	open: boolean;
	handleClose: any;
	object: Ad;
	loggedIn?: boolean;
}

/**
 * Dialog based on MUI. Takes an object with Ad data and displays it when set to open in parent.
 *
 */

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

export default function CustomizedDialogs(props: Props) {
	const {
		id,
		club_ids,
		description,
		image_url,
		title,
		isForCompanies,
		clubOfferInstructions,
		contactPerson,
		phoneNumber,
		email,
		expiry_date,
	} = props.object;
	const open = props.open;

	const [clubs, setClubs] = useState<Club[] | undefined>([]);
	const [clubNames, setClubNames] = useState<string[]>([]);
	const [clubIndex, setClubIndex] = useState<number>(-1);
	const [instructions, setInstructions] = useState<string>("");
	const [url, setUrl] = useState<string>("");

	const { club_id } = useParams<string>();
	const { ad_id } = useParams<string>();

	const { getClubs } = useFirestore();

	useEffect(() => {
		if (open) {
			setInstructions(
				"Välj din förening för att se hur du tar del av erbjudandet."
			);
			setUrl("");
			setClubIndex(-1);

			const param = club_id ? [club_id] : club_ids;

			getClubs(param)
				.then((resp) => {
					let _clubs: Club[] = [];
					let _clubNames: string[] = [];
					resp.forEach((club) => {
						_clubs.push(club);
						_clubNames.push(club.name);
					});
					setClubs(_clubs);
					setClubNames(_clubNames);
					if (club_id) {
						setClubIndex(0);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [open]);

	useEffect(() => {
		if (open && clubs && clubIndex > -1) {
			const id = clubs[clubIndex].id;

			const clubInstructions = clubOfferInstructions.filter(
				(a) => a.club_id === id
			)[0];

			if (clubInstructions) {
				setInstructions(clubInstructions.instructions);
				setUrl(clubInstructions.url);
			} else {
				setInstructions(
					"Kunde inte hitta instruktioner för den valda föreningen..."
				);
			}
		}
	}, [clubIndex]);

	return (
		<div>
			<BootstrapDialog
				onClose={props.handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
			>
				<div className="min-w-[30vw]">
					<BootstrapDialogTitle
						id="customized-dialog-title"
						onClose={props.handleClose}
					></BootstrapDialogTitle>

					<DialogContent>
						<div className="px-[5%] sm:px-[10%] flex flex-col">
							<img
								className="my-8 object-scale-down max-h-[150px]"
								src={image_url}
								alt="Parter"
							/>
							<Typography
								gutterBottom
								sx={{
									fontSize: "1.2rem",
									fontWeight: "bold",
									fontFamily: "RedHatText",
								}}
							>
								{title}
							</Typography>
							<Typography
								gutterBottom
								sx={{ 
									fontFamily: "RedHatText",
									whiteSpace: "pre-wrap", 
							}}
							>
								{description}
							</Typography>

							<div className="py-6">
								{club_id ? (
									<Typography
										gutterBottom
										sx={{
											fontWeight: "bold",
											fontFamily: "RedHatText",
										}}
									>
										{"Vald förening: " + clubNames}
									</Typography>
								) : (
									<CustomDropdownField
										label="Välj förening"
										elements={clubNames}
										getIndex
										parentCallback={setClubIndex}
									/>
								)}
							</div>
							<div className="py-5 space-y-8 flex flex-col items-start flex-wrap">
								<div className="flex flex-col">
									<Typography
										fontSize="16px"
										sx={{
											fontWeight: "bold",
											fontFamily: "RedHatText",
										}}
									>
										Hur du tar del av erbjudandet:
									</Typography>
									<Typography
										fontSize="16px"
										sx={{ fontFamily: "RedHatText" }}
									>
										{instructions}
									</Typography>
								</div>
								{isForCompanies && (
									<div>
										<Typography
											sx={{ fontFamily: "RedHatText" }}
										>
											<b>Kontaktperson:</b>{" "}
											{contactPerson}
										</Typography>
										<Typography
											sx={{ fontFamily: "RedHatText" }}
										>
											<b>Telefon:</b> {phoneNumber}
										</Typography>
										<Typography
											sx={{ fontFamily: "RedHatText" }}
										>
											<b>Mail:</b> {email}
										</Typography>
									</div>
								)}
								{url && (
									<a
										href={
											"https://" +
											url
												.replace("http://", "")
												.replace("https://", "")
										}
										className="self-center"
									>
										<StandardButton text="Till erbjudandet" />
									</a>
								)}
							</div>
							<div className="flex w-full justify-between ">
								<div className="self-center">
									<b className="text-sm">
										Gäller till och med:{" "}
									</b>
									{expiry_date ? (
										<b className="text-sm">
											{expiry_date.toLocaleDateString(
												"sv-SE"
											)}
										</b>
									) : (
										<b className="text-sm">tills vidare</b>
									)}
								</div>
								{!isForCompanies && !props.loggedIn && (
									<div className="flex flex-col self-end items-center justify-end">
										<b className="text-sm">
											Dela erbjudandet
										</b>
										<div className="py-2 flex items-center space-x-2">
											<FacebookShareButton
												//url={window.location.href + "/" + id}
												url={
													window.location.href +
													(ad_id ? "" : "/" + id)
												}
											>
												<FacebookIcon size={32} round />
											</FacebookShareButton>
											<TwitterShareButton
												url={
													window.location.href +
													(ad_id ? "" : "/" + id)
												}
												title={
													"Kolla in det här erbjudandet från " +
													props.object.company_name +
													" på Parter.se \n"
												}
											>
												<TwitterIcon size={32} round />
											</TwitterShareButton>
										</div>
									</div>
								)}
							</div>
						</div>
					</DialogContent>
				</div>
			</BootstrapDialog>
		</div>
	);
}
