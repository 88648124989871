import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

type CustomTextFieldProps = {
	label: string;
	name: string;
	multiline?: boolean;
	invalidInput?: boolean;
	defaultValue?: string
	changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CustomFormField = (props: CustomTextFieldProps) => {
	return (
		<Box
			sx={{
				"& .MuiTextField-root": { width: "100%" },
			}}
		>
			<div>
				<TextField
					label={props.label}
					name={props.name}
					onChange={props.changeHandler}
					variant={"outlined"} //enables special material-ui styling
					multiline={props.multiline}
					rows={4}
					defaultValue={props.defaultValue}
				/>
				<FormHelperText
					sx={{
						color: "var(--color-primary-red)",
					}}
				>
					{props.invalidInput ? "*Vänligen fyll i detta fält" : ""}
				</FormHelperText>
			</div>
		</Box>
	);
};
