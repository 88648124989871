import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { InputField } from "../../components/InputField";
import { Club } from "../../DBTypes";
import { useFirestore } from "../../hooks/useFirestore";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import { SuperAdminTemplate } from "./SuperadminTemplate";

export const Clubs = () => {
    const { navigateToSuperAddClub, navigateToSuperClub } = usePageNavigation();

	const { getAllClubs } = useFirestore();
	const [clubs, setClubs] = useState<Club[]>([]);
	const [filteredClubs, setFilteredClubs] = useState<Club[]>([]);
	const [searchInput, setSearchInput] = useState<string>("");

	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		getAllClubs()
			.then((resp) => {
				setClubs(resp);
				setFilteredClubs(resp);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const handleOnSearchBarChange = (e: any) => {
		setSearchInput(e.target.value);
	};

	useEffect(() => {
		if (searchInput === "") {
			setFilteredClubs(clubs);
		}
	}, [searchInput]);

	const filterClubs = () => {
		const searchInputLowerCase = searchInput.toLowerCase();
		const tmpFilteredClubs: Club[] = [];

		clubs.forEach((club) => {
			const clubName = club.name.toLowerCase();
			if (clubName.includes(searchInputLowerCase)) {
				tmpFilteredClubs.push(club);
			}
		});

		setFilteredClubs(tmpFilteredClubs);
	};

	const handleOnClubClick = (club_id: string) => {
		navigateToSuperClub(club_id);
	}

	return (
		<SuperAdminTemplate title="Föreningar" shouldIncludePlusIcon={true} navigationToOtherPage={navigateToSuperAddClub}>
			<div>
				<div className="flex mb-3">
					<InputField
						placeholderText="Sök efter förening..."
						type="text"
						onChange={handleOnSearchBarChange}
					/>
					<button
						className="btn px-6 py-2 search-button shadow-md bg-color-primary-blue hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
						type="submit"
						id="button-addon2"
						onClick={filterClubs}
					>
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="search"
							className="w-4"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path
								fill="currentColor"
								d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
							></path>
						</svg>
					</button>
				</div>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<>
						{!error ? (
							<div className="w-80 striped">
								{filteredClubs.map((club) => {
									return (
										<div key={club.id} onClick={() => handleOnClubClick(club.id)} className="pl-3 text-lg cursor-hover standard-hover-opacity">
											{club.name}
										</div>
									);
								})}
							</div>
						) : (
							<p>{error}</p>
						)}
					</>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
