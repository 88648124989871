import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { SearchClubAds, SearchClubs } from "../../hooks/frontendSearch";
import { Nav } from "../../components/Nav";
import { Header } from "../../components/Header";
import { GridMenu } from "../../components/GridMenu";
import { ParterGrid } from "../../components/ParterGrid";
import { ParterData } from "../../components/ParterData";
import { CircularProgress } from "@mui/material";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Company } from "../../DBTypes";
import { BannerAd } from "../../components/BannerAd";

let latestDoc: QueryDocumentSnapshot<DocumentData>;
let cards: ParterData[] = [];
const titles = ["Erbjudanden", "Föreningar"];

/**
 * Page for selected company. Gets company using company ID from url and displays company info, deals/clubs with filtering option.
 */
export const CompanyPage = () => {
	const { company_id } = useParams<string>();
	const { ad_id } = useParams<string>();

	const [data, setData] = useState<ParterData[]>([]);
	const [oldData, setOldData] = useState<ParterData[]>([]);

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [categoryFilter, setCategoryFilter] = useState<string>("");
	const [cityFilter, setCityFilter] = useState<string>("");

	const { getAdsFromCompanyPaginated, getCompanies, getClubs } =
		useFirestore();

	const [selectedTitle, setSelectedTitle] = useState<number>(1);

	const [searchText, setSearchText] = useState<string>("");

	const [company, setCompany] = useState<Company>();
	const [fromLink, setFromLink] = useState<boolean>(true);

	function RenderResults() {
		if (data) {
			if (data.length > 0) {
				return (
					<ParterGrid
						cards={data}
						fromLink={fromLink}
						setFromLink={setFromLink}
					/>
				);
			} else {
				return (
					<div className="text-white p-20">
						{"Här var det tomt. Hittade inga " +
							(selectedTitle === 0
								? "erbjudanden."
								: "föreningar.")}
					</div>
				);
			}
		}
	}

	useEffect(() => {
		setIsLoading(true);

		if (company_id) {
			getCompanies([company_id])
				.then((resp) => {
					setCompany(resp[0]);
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		}
		if (ad_id) {
			setSelectedTitle(0);
		}
	}, []);

	useEffect(() => {
		if (selectedTitle === 0) {
			let filteredData = SearchClubAds(searchText, oldData);

			setData(filteredData);
		}
		if (selectedTitle === 1) {
			let filteredData = SearchClubs(searchText, oldData);

			setData(filteredData);
		}
		setIsLoading(false);
	}, [searchText, oldData]);

	useEffect(() => {
		setData([]);
		setIsLoading(true);
		if (company) {
			if (selectedTitle === 0) {
				getAdsFromCompanyPaginated(
					company.id,
					undefined,
					categoryFilter,
					cityFilter
				)
					.then((resp) => {
						cards = [];
						latestDoc = resp.latestDoc;
						resp.ads.forEach((ad) => {
							if (!ad.isForCompanies) {
								cards.push({
									type: "ad",
									object: ad,
								});
							}
						});
						setOldData(cards);
					})
					.catch((error) => {
						setOldData([]);
						setErrorMessage(error);
						setIsLoading(false);
					});
			} else if (selectedTitle === 1) {
				setErrorMessage("");
				if (company.relatedClubs.length > 0) {
					getClubs(company.relatedClubs)
						.then((resp) => {
							cards = [];
							resp.forEach((club) => {
								cards.push({
									type: "club",
									object: club,
								});
							});
							setOldData(cards);
						})
						.catch((error) => {
							setOldData([]);
							setErrorMessage(error);
							setIsLoading(false);
						});
				} else {
					setIsLoading(false);
				}
			}
		}
	}, [company, categoryFilter, cityFilter, selectedTitle]);

	return (
		<div>
			<Nav />

			{/*Header*/}
			<div className={""}>
				<Header
					object={company}
					title={
						"Leta bland erbjudanden och sök efter föreningar som sponsras av " +
						(company ? company.name : "företaget")
					}
					type={"company"}
					parentCallback={setSearchText}
					searchValue={searchText}
				/>
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />

			<div className={"grid-menu"}>
				<GridMenu
					title={titles[0]}
					title2={titles[1]}
					selectedTitle={selectedTitle}
					setSelectedTitle={setSelectedTitle}
					setCategoryFilter={
						selectedTitle === 0 ? setCategoryFilter : undefined
					}
					setCityFilter={
						selectedTitle === 0 ? setCityFilter : undefined
					}
				/>
			</div>

			<div className={"card-grid"}>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<div>{RenderResults()}</div>
				)}
			</div>
		</div>
	);
};
