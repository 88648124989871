import { useEffect, useState, useRef } from "react";
import { StandardButton } from "../../components/StandardButton";
import { CustomFormField } from "../../components/CustomFormField";
import { CheckBoxDropdown } from "../../components/CheckBoxDropdown";
import { CircularProgress } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormHelperText from "@mui/material/FormHelperText";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import {
	Club,
	Company,
	PostEvent,
	EventInvite,
	SponsorGroup,
	Event,
	DBUser,
	Notification,
} from "../../DBTypes";
import { FileInput } from "../../components/FileInput";
import { Nav } from "../../components/Nav";
import { GroupSelectDropdown } from "../../components/GroupSelectDropdown";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";

import { useErrorMessages } from "../../hooks/useErrorMessages";
import { functions } from "../../Firebase";
import { httpsCallable } from "firebase/functions";

type Values = {
	title: string;
	street: string;
	area: string;
	description: string;
};

export const ManageEventsPage = () => {
	const [values, setValues] = useState<Values>({
		title: "",
		street: "",
		area: "",
		description: "",
	});

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [uploading, setUploading] = useState<boolean>(false);
	const [sponsors, setSponsors] = useState<Company[]>([]);
	const [invalidInput, setInvalidInput] = useState<boolean>(false);

	const [club, setClub] = useState<Club>();
	const [sponsorGroups, setSponsorGroups] = useState<SponsorGroup[]>();
	const [oldImageURL, setOldImageURL] = useState<string>();
	const [invited, setInvited] = useState<EventInvite[]>();

	const [date, setDate] = useState<Date | null>(null);
	const [time, setTime] = useState<Date | null>(null);
	const [sponsorNames, setSponsorNames] = useState<string[]>([]);
	const [imageFile, setImageFile] = useState<File>();

	const notifyBoxRef = useRef<HTMLInputElement>(null);

	const [error, setError] = useState("");

	const {
		getClubs,
		getCompaniesFromClub,
		uploadEvent,
		updateEvent,
		getEventFromID,
		deleteEvent,
		getUsersByCompanyId,
		getEventsFromClub,
		getUser,
	} = useFirestore();

	const { event_id } = useParams<string>();
	const navigate = useNavigate();
	const { navigateToClubHomePage } = usePageNavigation();

	let auth = useAuth();
	const user = auth.user;

	let firestore = useFirestore();

	const [selectedSponsorNames, setSelectedSponsorNames] = useState<string[]>(
		[]
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInvalidInput(false);
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const fetchUsers = async (company_ids: string[]) => {
		try {
			let allUsers: DBUser[] = [];
			await Promise.all(
				company_ids.map(async (id) => {
					const resp = await getUsersByCompanyId(id);
					resp.forEach((user) => allUsers.push(user));
				})
			);
			return allUsers;
		} catch (err) {
			console.log(err);
		}
	};

	// To check if a new event has the same name as an existing from the same club
	const fetchClubEventNames = async (clubId: string) => {
		try {
			let allEvents: string[] = [];
			const resp = await getEventsFromClub(clubId);
			resp.forEach((event) => allEvents.push(event.title));
			return allEvents;
		} catch (err) {
			console.log(err);
		}
	};

	const handleDeleteClick = (event_id: string) => {
		confirmAlert({
			message:
				"Är du säker på att du vill ta bort eventet? Detta går inte att ångra.",

			buttons: [
				{
					label: "Avbryt",
				},
				{
					label: "Ta bort",
					onClick: () => {
						setIsLoading(true);
						deleteEvent(event_id)
							.then(() => {
								alert("Tog bort erbjudandet.");
							})
							.catch((error) => {
								alert(error);
							})
							.finally(() => {
								navigateToClubHomePage();
							});
					},
				},
			],
		});
	};

	const getDateAndTime = (date: Date, time: Date) => {
		const timeDate = new Date(time);
		let hours = timeDate.getHours();
		let minutes = timeDate.getMinutes();
		let newDate = new Date(date);
		newDate.setHours(hours, minutes, 0);
		return newDate;
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (club) {
			// Update
			if (event_id) {
				if (
					date &&
					time &&
					values.title &&
					values.area &&
					values.description &&
					values.street
				) {
					const eventNames = await fetchClubEventNames(club.id);
					const currentEvent = (await getEventFromID(event_id)).title;
					if (eventNames) {
						const otherEventNames = eventNames.filter(
							(name) => name !== currentEvent
						);
						if (
							otherEventNames &&
							otherEventNames.includes(values.title)
						) {
							setError(
								"Din förening har redan ett event med detta namn"
							);
							return;
						}
					}

					const dateAndTime: Date = getDateAndTime(date, time);

					let add: string[] = [];

					let image = undefined;
					let old_image_url = undefined;
					if (imageFile && oldImageURL) {
						image = imageFile;
						old_image_url = oldImageURL;
					}
					let updatedInvited: EventInvite[] | undefined = undefined;
					let allUsersInput: DBUser[] = [];

					if (invited) {
						const selectedSponsorIds = sponsors
							.filter((element) =>
								selectedSponsorNames.includes(element.name)
							)
							.map((a) => a.id);

						const invitedIds = invited.map((i) => i.sponsor_id);
						// Get new invited
						add = selectedSponsorIds.filter(
							(x) => !invitedIds.includes(x)
						);

						// Get removed, TODO: alert and ask to confirm
						const remove = new Set(
							invitedIds.filter(
								(x) => !selectedSponsorIds.includes(x)
							)
						);

						updatedInvited = invited.filter(
							(obj) => !remove.has(obj.sponsor_id)
						);

						// Add new invited
						if (add) {
							add.forEach((id) => {
								const newInvite: EventInvite = {
									sponsor_id: id,
									answer: 0, // 0 for unanswered, -1 for no, 1 for yes
									seen: false,
								};
								if (updatedInvited === undefined) {
									updatedInvited = [];
								}
								updatedInvited.push(newInvite);
							});
						}
						const ids = updatedInvited.map((i) => i.sponsor_id);
						const emails: string[] = [];
						const allusers = await fetchUsers(ids);
						if (allusers) {
							allUsersInput = allusers;
						}
					}

					setUploading(true);
					updateEvent(
						event_id,
						values.title,
						dateAndTime,
						values.street,
						values.area,
						values.description,
						updatedInvited,
						image,
						old_image_url
					)
						.then(() => {
							const sendNotifications =
								notifyBoxRef.current?.checked;
							const eventNotis: Notification = {
								event_name: values.title,
								event_club: club.name,
								event_area: values.area,
								read: false,
								isUpdate: true,
								type: "event",
							};

							// TODO: send invitation with sendgrid, pass array of new sponsors to invite
							const createNotifications = httpsCallable(
								functions,
								"createNotifications"
							);
							const sendUpdateEmails = httpsCallable(
								functions,
								"sendUpdateEmails"
							);

							if (allUsersInput.length > 0 && sendNotifications) {
								const userIds = allUsersInput.map(u => u.id)
								createNotifications({
									users: userIds,
									event: eventNotis,
								});
							}

							const sendgridUsers: DBUser[] = [];
							if (allUsersInput) {
								allUsersInput.map((user) => {
									if (user?.email) {
										sendgridUsers.push(user);
									}
								});
							}
							if (allUsersInput.length > 0 && sendNotifications) {
								sendUpdateEmails({
									users: sendgridUsers,
									event: values.title,
									club: club.name,
								})
									.then((result) => console.log(result))
									.catch((err) => console.log(err));
							}
						})
						.then(() => {
							alert("Uppdaterade eventet");
							setUploading(false);
							navigateToClubHomePage();
						})
						.catch((e) => {
							alert(e);
							setUploading(false);
						});
				} else {
					setInvalidInput(true);
				}
			} else {
				if (
					date &&
					time &&
					imageFile &&
					values.title &&
					values.area &&
					values.description &&
					values.street
				) {
					const eventNames = await fetchClubEventNames(club.id);
					if (eventNames && eventNames.includes(values.title)) {
						setError(
							"Din förening har redan ett event med detta namn"
						);
						return;
					}
					// Add date and time together to one object
					const dateAndTime: Date = getDateAndTime(date, time);
					let allUsersInput: DBUser[] = [];

					// Get ids for sponsors to invite
					const sponsorIds = sponsors
						.filter((element) =>
							selectedSponsorNames.includes(element.name)
						)
						.map((a) => a.id);

					let invited: EventInvite[] = [];
					sponsorIds.forEach((id) => {
						const newInvite: EventInvite = {
							sponsor_id: id,
							answer: 0, // 0 for unanswered, -1 for no, 1 for yes
							seen: false,
						};
						invited.push(newInvite);
					});

					const newEvent: PostEvent = {
						club_id: club.id,
						club_name: club.name,
						title: values.title,
						date: dateAndTime,
						street: values.street,
						area: values.area,
						description: values.description,
						sponsor_ids: sponsorIds,
						invited: invited,
						image: imageFile,
					};

					const ids = invited.map((i) => i.sponsor_id);
					const allusers = await fetchUsers(ids);
					if (allusers) {
						allUsersInput = allusers;
					}

					setUploading(true);

					uploadEvent(newEvent)
						.then(() => {
							const eventNotis: Notification = {
								event_name: newEvent.title,
								event_club: newEvent.club_name,
								event_area: newEvent.area,
								read: false,
								isUpdate: false,
								type: "event",
							};

							// TODO: send invitation with sendgrid, pass array of new sponsors to invite
							const createNotifications = httpsCallable(
								functions,
								"createNotifications"
							);
							const sendInviteEmails = httpsCallable(
								functions,
								"sendInviteEmails"
							);
							const sendgridUsers: DBUser[] = [];
							allusers &&
								allusers.map((user) => {
									if (user.email) {
										sendgridUsers.push(user);
									}
								});
							if (allusers) {
								const userIds = allusers.map(u => u.id)
								createNotifications({
									users: userIds,
									event: eventNotis,
								});
							}
							if (sendgridUsers) {
								sendInviteEmails({
									users: sendgridUsers,
									event: values.title,
									club: club.name,
								});
							}
						})
						.then(() => {
							alert("Eventet har skapats");
							setUploading(false);
							navigateToClubHomePage();
						})
						.catch((error) => {
							alert(error);
						});
				} else {
					setInvalidInput(true);
				}
			}
		}
	};

	const handleDateChange = (newValue: Date | null) => {
		setDate(newValue);
	};

	useEffect(() => {
		const fetchClub = async (club_id: string) => {
			try {
				const resp: Club[] = await getClubs([club_id]);
				return resp[0];
			} catch (err) {
				console.log(err);
			}
		};

		const fetchSponsors = async (club_id: string) => {
			try {
				const sponsors: Company[] = await getCompaniesFromClub(club_id);
				return sponsors;
			} catch (err) {
				console.log(err);
			}
		};
		const fetchEvent = async (event_id: string) => {
			try {
				const event: Event = await getEventFromID(event_id);
				return event;
			} catch (err) {
				alert(err);
				navigateToClubHomePage();
			}
		};

		const fetchAll = async () => {
			if (user && user.firestoreUser.club_id) {
				const club: Club | undefined = await fetchClub(
					user.firestoreUser.club_id
				);
				if (!club) {
					return;
				}
				const sponsors = await fetchSponsors(club.id);

				if (sponsors) {
					setSponsors(sponsors);
					let names = sponsors.map((a) => a.name);
					let ids = sponsors.map((a) => a.id);
					setSponsorNames(names);
				}

				if (event_id) {
					const event = await fetchEvent(event_id);

					if (!event) {
						return;
					}
					setInvited(event.invited);

					if (sponsors) {
						const invited = event.invited.map((i) => i.sponsor_id);
						const sponsorNames = sponsors
							.filter((element) => invited.includes(element.id))
							.map((a) => a.name);
						setSelectedSponsorNames(sponsorNames);
					}

					const values = {
						title: event.title,
						street: event.street,
						area: event.area,
						description: event.description,
					};
					setValues({ ...values });

					setDate(event.date);
					setTime(event.date);
					setOldImageURL(event.image_url);
				}
				setClub(club);

				if (club.sponsor_groups) setSponsorGroups(club.sponsor_groups);
			}
			setIsLoading(false);
		};

		fetchAll();
	}, []);

	return (
		<div className="flex flex-col h-screen">
			<Nav />
			<div className="flex justify-center items-start flex-grow">
				<div className="relative mt-10 w-[95%] md:w-[70%] lg:w-[50%] flex justify-center login-form mb-16 min-h-[90%]">
					{isLoading ? (
						<div className="self-center">
							<CircularProgress />
						</div>
					) : (
						<form onSubmit={(e) => handleSubmit(e)} className="">
							{event_id && (
								<div className="absolute right-5 top-5 cursor-hover">
									<DeleteIcon
										onClick={() => {
											handleDeleteClick(event_id);
										}}
									></DeleteIcon>
								</div>
							)}
							<div className="flex flex-col m-5 p-10 w-[400px] sm:w-[500px] space-y-4">
								<p
									style={{ marginBottom: "20px" }}
									className="flex font-bold text-2xl"
								>
									{event_id
										? "Redigera event"
										: "Skapa event"}
								</p>
								<CustomFormField
									changeHandler={handleChange}
									label={"Titel"}
									name={"title"}
									invalidInput={
										invalidInput && values.title === ""
									}
									defaultValue={values.title}
								/>

								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DesktopDatePicker
										label="Datum"
										inputFormat="YYYY/MM/DD"
										value={date}
										onChange={handleDateChange}
										renderInput={(params) => (
											<div>
												<TextField
													onKeyDown={(e) =>
														e.preventDefault()
													}
													{...params}
												/>
											</div>
										)}
									/>
									{invalidInput && !date && (
										<FormHelperText
											sx={{
												color: "var(--color-primary-red)",
											}}
										>
											*Vänligen välj datum
										</FormHelperText>
									)}
								</LocalizationProvider>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<TimePicker
										inputFormat="HH:mm"
										label="Tid"
										value={time}
										onChange={(newValue) =>
											setTime(newValue)
										}
										renderInput={(params) => (
											<div>
												<TextField
													onKeyDown={(e) =>
														e.preventDefault()
													}
													{...params}
												/>
											</div>
										)}
									/>
									{invalidInput && !time && (
										<FormHelperText
											sx={{
												color: "var(--color-primary-red)",
											}}
										>
											*Vänligen välj tid
										</FormHelperText>
									)}
								</LocalizationProvider>
								{sponsorGroups &&
									(sponsorGroups[0].name !== "" ||
										sponsorGroups.length > 1) ? (
									<GroupSelectDropdown
										label="Bjud in sponsorer"
										elements={sponsors}
										groups={sponsorGroups}
										parentCallback={setSelectedSponsorNames}
										defaultValues={selectedSponsorNames}
									/>
								) : (
									<CheckBoxDropdown
										label="Bjud in sponsorer"
										elements={sponsorNames}
										parentCallback={setSelectedSponsorNames}
										defaultValues={selectedSponsorNames}
									/>
								)}

								<CustomFormField
									changeHandler={handleChange}
									label={"Gatuadress"}
									name={"street"}
									invalidInput={
										invalidInput && values.street === ""
									}
									defaultValue={values.street}
								/>
								<CustomFormField
									changeHandler={handleChange}
									label={"Postnummer och ort"}
									name={"area"}
									invalidInput={
										invalidInput && values.area === ""
									}
									defaultValue={values.area}
								/>
								<CustomFormField
									changeHandler={handleChange}
									label={"Beskrivning"}
									name={"description"}
									multiline
									invalidInput={
										invalidInput &&
										values.description === ""
									}
									defaultValue={values.description}
								/>

								<FileInput
									parentCallback={setImageFile}
									title={"bild"}
									error={invalidInput}
									edit={event_id ? true : false}
								/>
								{event_id && (
									<div style={{ marginBottom: "20px" }}>
										<input
											type="checkbox"
											style={{
												marginRight: "10px",
												padding: "20px",
											}}
											name="notifyInvited"
											ref={notifyBoxRef}
										/>
										<span>
											Jag vill meddela inbjudna om
											ändringen
										</span>
									</div>
								)}

								{uploading ? (
									<div className="self-center">
										<CircularProgress />
									</div>
								) : (
									<div className={"flex justify-evenly"}>
										<StandardButton
											text="Avbryt"
											button
											onClick={() => {
												navigate(-1);
											}}
											secondary
										/>
										<StandardButton
											text={
												event_id
													? "Spara ändringar"
													: "Skapa event"
											}
										/>
									</div>
								)}
								{error && (
									<p className="color-primary-red">{error}</p>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};
