import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface Props {
	label: string;
	elements: string[];
	parentCallback?: any;
	multiple?: boolean;
	error?: boolean;
	getIndex?: boolean;
	defaultValue?: string;
}

/**
 * Dropdown select based on MUI component. Gets Elements and label from parents.
 * Can also display error helper text if empty
 *
 */

export function CustomDropdownField(props: Props) {
	const [selected, setSelected] = useState<string>(
		props.defaultValue ? props.defaultValue : ""
	);
	useEffect(() => {
		if (props.defaultValue) {
			setSelected(props.defaultValue);
			props.parentCallback(props.defaultValue);
		}
	}, [props.defaultValue]);
	/**
	 * Handle displaying changes for checkbox
	 */

	const handleSingleChange = (event: SelectChangeEvent) => {
		const {
			target: { value },
		} = event;
		setSelected(value);
		props.parentCallback(value);
	};
	return (
		<div>
			<FormControl sx={{ width: "100%" }}>
				<InputLabel id="demo-simple-select-label">
					{props.label}
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={selected}
					label={props.label}
					onChange={handleSingleChange}
					MenuProps={MenuProps}
				>
					{props.elements.map((element, index) => (
						<MenuItem key={index} value={props.getIndex ? index : element}>
							{element}
						</MenuItem>
					))}
				</Select>

				<FormHelperText
					sx={{
						color: "var(--color-primary-red)",
					}}
				>
					{props.error && !selected
						? "*Vänligen välj ett alternativ från listan"
						: " "}
				</FormHelperText>
			</FormControl>
		</div>
	);
}
