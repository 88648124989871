import React, { useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	IconButton,
	TextField,
} from "@mui/material";
import { StandardButton } from "./StandardButton";
import { InputField } from "./InputField";
import { SponsorDealInfo } from "../DBTypes";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

interface Props {
	open: boolean;
	handleClose: any;
	handleSubmit: any;
	deal: SponsorDealInfo | undefined;
}

export const SponsorDealDialog = (props: Props) => {
	const uploadDeal = (e: any) => {
		e.preventDefault();
		const price = e.target.price.value;
		const expires = e.target.expires.value;
		const obligation = e.target.obligation.value;
		const other = e.target.other.value;
		const pdf = e.target.pdf.files[0];

		setEdit(false);
		props.handleSubmit(price, expires, obligation, other, pdf);
	};
	const onClose = () => {
		setEdit(false);
		props.handleClose();
	};

	const [edit, setEdit] = useState<boolean>(false);

	return (
		<Dialog
			open={props.open}
			onClose={onClose}
			PaperProps={{
				style: {
					maxHeight: "360px",
					height: "100%",
					maxWidth: "900px",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					padding: "5px",
				},
			}}
		>
			<DialogTitle
				style={{
					fontSize: "24px",
					width: "100%",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				Avtalsdetaljer
				<Box borderBottom={1} borderColor="grey.300" width="100%" />
				<IconButton
					aria-label="close"
					onClick={onClose}
					style={{ position: "absolute", top: 0, right: 0 }}
				>
					<CloseSharpIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					justifyContent: "center",
				}}
			>
				{edit ? (
					<form
						onSubmit={uploadDeal}
						id="dealform"
						style={{ width: "100%" }}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<label>Avtalssumma</label>
								<input
									type="number"
									placeholder="kr"
									name="price"
									defaultValue={
										props.deal ? props.deal.price : ""
									}
									style={{
										padding: "8px",
										borderRadius: "10px",
										border: "1px solid grey",
										width: "240px",
										marginTop: "10px",
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<label>Giltigt till</label>
								<input
									type="date"
									placeholder="Giltigt till"
									name="expires"
									defaultValue={
										props.deal
											? String(props.deal.expiry_date)
											: ""
									}
									style={{
										padding: "8px",
										borderRadius: "10px",
										border: "1px solid grey",
										width: "240px",
										marginTop: "10px",
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<label>Motprestation</label>
								<input
									type="text"
									placeholder="Motprestation"
									name="obligation"
									defaultValue={
										props.deal
											? props.deal.clubObligations
											: ""
									}
									style={{
										padding: "8px",
										borderRadius: "10px",
										border: "1px solid grey",
										width: "240px",
										marginTop: "10px",
									}}
								/>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "left",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<label>Övrigt</label>
								<input
									type="text"
									placeholder="Övrigt"
									name="other"
									defaultValue={
										props.deal ? props.deal.other : ""
									}
									style={{
										padding: "8px",
										borderRadius: "10px",
										border: "1px solid grey",
										width: "240px",
										marginTop: "10px",
									}}
								/>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginTop: "10px",
							}}
						>
							<label>Ladda upp fil</label>
							<input
								type="file"
								name="pdf"
								accept="application/pdf"
								style={{ marginTop: "10px" }}
							/>
						</div>
					</form>
				) : (
					<>
						{props.deal ? (
							<div
								style={{
									width: "100%",
									height: "100%",
									display: "flex",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
										width: "100%",
										height: "100%",
										justifyContent: "space-evenly",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											flexBasis: "20%",
											alignItems: "center",
										}}
									>
										<label style={{ fontSize: "22px" }}>
											Avtalssumma:
										</label>
										<Box
											borderBottom={1}
											borderColor="grey.300"
											width="100%"
										/>
										<p
											style={{
												maxWidth: "100%",
												wordBreak: "break-word",
											}}
										>
											{props.deal.price}
											{" kr"}
										</p>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											flexBasis: "20%",
											alignItems: "center",
										}}
									>
										<label style={{ fontSize: "22px" }}>
											Giltigt till:{" "}
										</label>
										<Box
											borderBottom={1}
											borderColor="grey.300"
											width="100%"
										/>
										<p
											style={{
												maxWidth: "100%",
												wordBreak: "break-word",
											}}
										>
											{String(props.deal.expiry_date)}
										</p>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											flexBasis: "20%",
											alignItems: "center",
										}}
									>
										<label style={{ fontSize: "22px" }}>
											Motprestation:
										</label>
										<Box
											borderBottom={1}
											borderColor="grey.300"
											width="100%"
										/>
										<p
											style={{
												maxWidth: "100%",
												wordBreak: "break-word",
											}}
										>
											{props.deal.clubObligations}
										</p>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											flexBasis: "20%",
											alignItems: "center",
										}}
									>
										<label style={{ fontSize: "22px" }}>
											Övrigt:
										</label>
										<Box
											borderBottom={1}
											borderColor="grey.300"
											width="100%"
										/>
										<p
											style={{
												maxWidth: "100%",
												wordBreak: "break-word",
											}}
										>
											{props.deal.other}
										</p>
									</div>
									{props.deal.downloadURL ? (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												flexBasis: "20%",
											}}
										>
											<label
												style={{
													fontSize: "22px",
												}}
											>
												Uppladdad fil:{" "}
											</label>
											<Box
												borderBottom={1}
												borderColor="grey.300"
												width="100%"
											/>

											<button
												className={
													"standard-button standard-button-border-radius standard-hover-opacity primary-button"
												}
												style={{
													marginTop: "10px",
												}}
											>
												<a
													href={
														props.deal.downloadURL
													}
													download
													target="_blank"
												>
													Ladda ned
												</a>
											</button>
										</div>
									) : null}
								</div>
							</div>
						) : (
							<>
								<p style={{ marginTop: "70px" }}>
									Just nu finns det ingen information att visa
								</p>
							</>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions
				style={{
					display: "flex",
					justifyContent: "flex-end",
					width: "100%",
				}}
			>
				{!edit && (
					<StandardButton
						text="redigera"
						onClick={() => setEdit(true)}
					/>
				)}
				{edit && <StandardButton text="Spara" form="dealform" />}
			</DialogActions>
		</Dialog>
	);
};
