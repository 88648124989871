import {
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { Company } from "../../DBTypes";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import { SuperAdminTemplate } from "./SuperadminTemplate";
import { CheckBoxDropdown } from "../../components/CheckBoxDropdown";
import { CreateUserForm } from "../../components/CreateUserForm";
import { CATEGORIES } from "../../Categories";
import UsersList from "../../components/UsersList";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { NewSponsorUser } from "../../components/NewSponsorUser";

export const SuperSponsorPage = () => {
	const auth = useAuth();
	const user = auth.user;

	const { navigateToCompanyHomePage, navigateToSuperSponsors } =
		usePageNavigation();
	const { updateCompany, setUserCompanyID, getCompanies, removeCompany } =
		useFirestore();
	const [company, setCompany] = useState<Company>();
	const { company_id } = useParams<string>();
	const [errorLoadingCompany, setErrorLoadingCompany] = useState<string>("");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingEdit, setIsLoadingEdit] = useState(false);
	const [isLoadingRemove, setIsLoadingRemove] = useState(false);
	const [showUsers, setShowUsers] = useState(false);


	const [companyName, setCompanyName] = useState<string>();
	const [description, setDescription] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [adminLevel, setAdminLevel] = useState<number>(0);
	const [categories, setCategories] = useState<string[]>([]);
	const [whiteLogo, setWhiteLogo] = useState<boolean>(false);
	const [showOnStartPage, setShowOnStartPage] = useState<boolean>();
	const [image, setImage] = useState<File>();
	const [address, setAddress] = useState<string>();
	const [phoneNumber, setPhoneNumber] = useState<string>();
	const [websiteURL, setWebsiteURL] = useState<string>();

	// Internal contact
	const [contactName, setContactName] = useState<string>();
	const [contactEmail, setContactEmail] = useState<string>();
	const [contactPhone, setContactPhone] = useState<string>();

	useEffect(() => {
		if (company_id) {
			getCompanies([company_id])
				.then((resp) => {
					setCompany(resp[0]);
					if (resp[0].categories) {
						setCategories(resp[0].categories);
					}
				})
				.catch((error) => {
					setErrorLoadingCompany(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
			setErrorLoadingCompany("Kunde inte hitta sponsor");
		}
	}, []);

	useEffect(() => {
		if (company) {
			setCompanyName(company.name);
			setDescription(company.description);
			setEmail("");
			setShowOnStartPage(company.isOnFrontPage);
			if (company.whiteLogo) {
				setWhiteLogo(company.whiteLogo);
			}

			setAddress(company.address);
			setPhoneNumber(company.phoneNumber);
			setWebsiteURL(company.website_url);

			// Internal contact
			setContactName(company.contactName);
			setContactEmail(company.contactEmail);
			setContactPhone(company.contactPhone);

			setIsLoading(false);
		}
	}, [company]);

	const handleOnSubmit = () => {
		if (company && companyName) {
			setIsLoadingEdit(true);
			updateCompany(
				company.id,
				companyName,
				description,
				image,
				company.image_url,
				showOnStartPage,
				whiteLogo,
				email,
				categories,
				address,
				phoneNumber,
				websiteURL,
				contactName,
				contactEmail,
				contactPhone, adminLevel === 1 && email ? true : undefined
			)
				.then(() => {
					setIsLoadingEdit(false);
					alert("Företaget har uppdaterats");
					if (company_id)
						getCompanies([company_id]).then((resp) =>
							setCompany(resp[0])
						);
				})
				.catch((error) => {
					setIsLoadingEdit(false);
					alert(error);
				});
		} else {
			alert("Företagets namn måste finnas med!");
		}
	};

	const handleOnRemoveCompany = () => {
		if (company_id) {
			setIsLoadingRemove(true);

			removeCompany(company_id)
				.then(() => {
					setIsLoadingRemove(false);
					alert("Företaget har tagits bort");
					navigateToSuperSponsors();
				})
				.catch((error) => {
					setIsLoadingRemove(false);
					alert(error);
				});
		}
	};

	const onGoToCompanyPageClick = () => {
		if (user && company_id) {
			setUserCompanyID(user.uid, company_id)
				.then(() => {
					auth.updateUserCompanyID(company_id);
					navigateToCompanyHomePage();
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};


	return (
		<SuperAdminTemplate title={company ? company.name : "Hämtar sponsor"}>
			<div className="flex flex-col align-top w-96">
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<>
						{errorLoadingCompany ? (
							<p>{errorLoadingCompany}</p>
						) : (
							<div className="flex w-full space-x-8">
								<div className="flex flex-col align-top w-96">
									<div className="max-w-[200px] mb-5">
										<StandardButton
											text="Gå till företagssida"
											onClick={onGoToCompanyPageClick}
										/>
									</div>
									<p className="mb-3">
										Organisationsnummer:{" "}
										{company?.organisationNumber}
									</p>
									<CustomInputField
										label="Företagets namn"
										parentCallback={setCompanyName}
										defaultValue={companyName}
									/>

									<NewSponsorUser adminLevel={adminLevel} setAdminLevel={setAdminLevel} email={email} setEmail={setEmail} />
									{company_id && (
										<div>
											<div className="mb-6">
												<CreateUserForm
													company_id={company_id}
												/>
											</div>
											<div className="pb-8">
												<UsersList
													open={showUsers}
													setOpen={setShowUsers}
													companyId={company!.id}
													superAdmin
												/>
											</div>
										</div>
									)}

									<CustomInputField
										label="Beskrivning"
										multiline
										parentCallback={setDescription}
										defaultValue={description}
									/>
									<CheckBoxDropdown
										label="Kategori(er)"
										elements={CATEGORIES}
										parentCallback={setCategories}
										defaultValues={categories}
									/>

									<CustomInputField
										label="Adress"
										parentCallback={setAddress}
										defaultValue={address}
									/>

									<CustomInputField
										label="Telefonnummer"
										parentCallback={setPhoneNumber}
										defaultValue={phoneNumber}
									/>

									<CustomInputField
										label="Hemsida (URL)"
										parentCallback={setWebsiteURL}
										defaultValue={websiteURL}
									/>

									<div className="mb-3">
										<div className="flex">
											<FileInput
												edit={true}
												parentCallback={setImage}
												title={"logga"}
											/>

											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={whiteLogo}
															onChange={(e) =>
																setWhiteLogo(
																	e.target
																		.checked
																)
															}
														/>
													}
													label="Vit logga"
												/>
											</FormGroup>
										</div>
									</div>

									{showOnStartPage !== undefined && (
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														defaultChecked={
															showOnStartPage
														}
														onChange={(e) =>
															setShowOnStartPage(
																e.target.checked
															)
														}
													/>
												}
												label="Visa på startsidan"
											/>
										</FormGroup>
									)}
									<div className="my-6 flex flex-col space-y-2">
										<b className="text-lg mb-2">
											Intern kontakt
										</b>
										<CustomInputField
											label="Namn"
											parentCallback={setContactName}
											defaultValue={contactName}
										/>
										<CustomInputField
											label="Mailadress"
											parentCallback={setContactEmail}
											defaultValue={contactEmail}
										/>
										<CustomInputField
											label="Telefonnummer"
											parentCallback={setContactPhone}
											defaultValue={contactPhone}
										/>
									</div>
									{isLoadingEdit ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : (
										<StandardButton
											text="Ändra företagets uppgifter"
											onClick={handleOnSubmit}
										/>
									)}

									<div className="mt-20">
										{isLoadingRemove ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : (
											<StandardButton
												text="Ta bort sponsor"
												onClick={handleOnRemoveCompany}
												color={"red"}
											/>
										)}
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
