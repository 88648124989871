import {
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Tooltip,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { Company } from "../../DBTypes";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import { SuperAdminTemplate } from "../Superadmin/SuperadminTemplate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import UsersList from "../../components/UsersList";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { NewSponsorUser } from "../../components/NewSponsorUser";

export const CompanyInfoPage = () => {
	const { getUser } = useFirestore();
	const auth = useAuth();
	const user = auth.user;

	const { navigateToCompanyHomePage, navigateToUpdatePasswordPage } =
		usePageNavigation();
	const { updateCompany, getCompanies } = useFirestore();
	const [company, setCompany] = useState<Company>();
	const { company_id } = useParams<string>();
	const [errorLoadingCompany, setErrorLoadingCompany] = useState<string>("");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingEdit, setIsLoadingEdit] = useState(false);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);

	const [companyName, setCompanyName] = useState<string>();
	const [description, setDescription] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [adminLevel, setAdminLevel] = useState<number>(0);
	const [showUsers, setShowUsers] = useState<boolean>(false);
	const [categories, setCategories] = useState<string[]>([]);
	const [address, setAddress] = useState<string>();
	const [phoneNumber, setPhoneNumber] = useState<string>();
	const [websiteURL, setWebsiteURL] = useState<string>();

	const [showOnStartPage, setShowOnStartPage] = useState<boolean>();

	const [image, setImage] = useState<File>();
	const [whiteLogo, setWhiteLogo] = useState<boolean>(false);

	const [showEmailField, setShowEmailField] = useState<boolean>(false);

	const [userType, setUserType] = useState<number>();

	// Internal contact
	const [contactName, setContactName] = useState<string>();
	const [contactEmail, setContactEmail] = useState<string>();
	const [contactPhone, setContactPhone] = useState<string>();

	useEffect(() => {
		if (user) {
			getUser(user.uid).then((user) => {
				setUserType(user.user_type);
			});
			if (!user.firestoreUser.staff) {
				setIsAdmin(true);
			}
			getCompanies([user.firestoreUser.company_id])
				.then((resp) => {
					setCompany(resp[0]);
				})
				.catch((error) => {
					console.error(error);
					setErrorLoadingCompany(error);
				});
		}
	}, []);

	useEffect(() => {
		if (company) {
			setCompanyName(company.name);
			setDescription(company.description);
			setEmail("");
			setShowOnStartPage(company.isOnFrontPage);
			if (company.whiteLogo) {
				setWhiteLogo(company.whiteLogo);
			}

			setAddress(company.address);
			setPhoneNumber(company.phoneNumber);
			setWebsiteURL(company.website_url);

			// Internal contact
			setContactName(company.contactName);
			setContactEmail(company.contactEmail);
			setContactPhone(company.contactPhone);

			setIsLoading(false);
		}
	}, [company]);

	const handleOnSubmit = () => {
		if (company && companyName) {
			setIsLoadingEdit(true);
			updateCompany(
				company.id,
				companyName,
				description,
				image,
				company.image_url,
				showOnStartPage,
				whiteLogo,
				email,
				categories,
				address,
				phoneNumber,
				websiteURL,
				contactName,
				contactEmail,
				contactPhone,
				adminLevel === 1 && email ? true : undefined
			)
				.then(() => {
					setIsLoadingEdit(false);
					alert("Företaget har uppdaterats");
					if (company_id)
						getCompanies([company_id]).then((resp) =>
							setCompany(resp[0])
						);
				})
				.finally(() => {
					navigateToCompanyHomePage();
				})
				.catch((error) => {
					setIsLoadingEdit(false);
					alert(error);
				});
		} else {
			alert("Företagets namn måste finnas med!");
		}
	};

	const onNewUserEmailClick = () => {
		confirmAlert({
			message:
				"Är du säker på att du vill lägga till en ny användare som kommer kunna göra ändringar på " +
				companyName +
				"? Om ja, klicka på fortsätt och fyll i den mailadress som ska användas och välj nivå för den nya användaren. Spara sedan alla ändringar längst ner på denna sida. Detta kommer inte påverka nuvarande användare.",
			buttons: [
				{
					label: "Avbryt",
				},
				{
					label: "Fortsätt",
					onClick: () => setShowEmailField(true),
				},
			],
		});
	};

	return (
		<SuperAdminTemplate title={company ? company.name : "Hämtar sponsor"}>
			<div className="flex flex-col align-top mb-12">
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<>
						{errorLoadingCompany ? (
							<p>{errorLoadingCompany}</p>
						) : (
							<div className="flex flex-col align-top w-72 sm:w-96">
								<div className="max-w-[200px] mb-5"></div>

								<CustomInputField
									label="Företagets namn"
									parentCallback={setCompanyName}
									defaultValue={companyName}
								/>
								{isAdmin && (
									<div>
										{showEmailField ? (
											<NewSponsorUser adminLevel={adminLevel} setAdminLevel={setAdminLevel} email={email} setEmail={setEmail} />
										) : (
											<div className="pb-4">
												<StandardButton
													text="Lägg till användare"
													onClick={
														onNewUserEmailClick
													}
												/>
											</div>
										)}

										<div className="pb-8">
											<UsersList
												open={showUsers}
												setOpen={setShowUsers}
												companyId={company!.id}
											/>
										</div>
									</div>
								)}

								<CustomInputField
									label="Beskrivning"
									multiline
									parentCallback={setDescription}
									defaultValue={description}
								/>
								{/*<CheckBoxDropdown
									label="Kategori(er)"
									elements={CATEGORIES}
									parentCallback={setCategories}
									defaultValues={categories}
                    />*/}

								<CustomInputField
									label="Adress"
									parentCallback={setAddress}
									defaultValue={address}
								/>

								<CustomInputField
									label="Telefonnummer"
									parentCallback={setPhoneNumber}
									defaultValue={phoneNumber}
								/>

								<CustomInputField
									label="Hemsida (URL)"
									parentCallback={setWebsiteURL}
									defaultValue={websiteURL}
								/>

								<div className="mb-3">
									<div className="flex">
										<FileInput
											edit={true}
											parentCallback={setImage}
											title={"logga"}
										/>

										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={whiteLogo}
														onChange={(e) =>
															setWhiteLogo(
																e.target.checked
															)
														}
													/>
												}
												label="Vit logga"
											/>
										</FormGroup>
									</div>
								</div>
								<div className="mt-6 mb-12 flex flex-col space-y-2">
									<b className="text-lg mb-2">
										Intern kontakt
									</b>
									<CustomInputField
										label="Namn"
										parentCallback={setContactName}
										defaultValue={contactName}
									/>
									<CustomInputField
										label="Mailadress"
										parentCallback={setContactEmail}
										defaultValue={contactEmail}
									/>
									<CustomInputField
										label="Telefonnummer"
										parentCallback={setContactPhone}
										defaultValue={contactPhone}
									/>
								</div>
								<div className="mb-16">
									{isLoadingEdit ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : (
										<div className="flex w-full justify-between">
											<StandardButton
												text="Avbryt"
												onClick={
													navigateToCompanyHomePage
												}
												color={"red"}
											/>
											<StandardButton
												text="Spara"
												onClick={handleOnSubmit}
											/>
										</div>
									)}
								</div>
								{userType !== 3 && (
									<div>
										<StandardButton
											text="Byt lösenord"
											onClick={
												navigateToUpdatePasswordPage
											}
										/>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
