import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { usePageNavigation } from "../hooks/usePageNavigation";
import parterLogo from "../icons/main-logo.svg";
import { StandardButton } from "./StandardButton";

export const Nav = () => {
	let auth = useAuth();
	const user = auth.user;

	const handleOnLogoutClick = () => {
		auth.logout()
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				navigateToMainPage();
				window.location.reload();
			});
	};

	const [userType] = useState(auth.user?.firestoreUser.user_type);

	const {
		navigateToMainPage,
		navigateToLoginPage,
		navigateToCompanyHomePage,
		navigateToClubHomePage,
		navigateToSuperSponsors,
		navigateToSuperClubs,
		navigateToAboutPage,
	} = usePageNavigation();

	return (
		<nav>
			<div className="nav flex justify-between items-center px-4">
				<img
					className="cursor-hover standard-hover-opacity w-30 h-16"
					src={parterLogo}
					alt="Parter"
					onClick={navigateToMainPage}
				/>

				{/* Show button if not logged in or company logged in
					Super admin has navigation items */}
				{!userType || userType === 1 || userType === 3 ? (
					<div className="flex flex-row space-x-6 items-center">
						<p
							onClick={navigateToAboutPage}
							className="cursor-hover standard-hover-opacity"
						>
							Om oss
						</p>
						{user ? (
							<p
								onClick={() => {
									if (userType === 1) {
										navigateToCompanyHomePage();
									} else if (userType === 3) {
										navigateToClubHomePage();
									}
								}}
								className="cursor-hover standard-hover-opacity"
							>
								Profil
							</p>
						) : null}
						<StandardButton
							text={user ? "Logga ut" : "Logga in"}
							onClick={() => {
								user
									? handleOnLogoutClick()
									: navigateToLoginPage();
							}}
						/>
					</div>
				) : (
					<div className="flex flex-row space-x-5 items-center">
						<p
							onClick={navigateToSuperSponsors}
							className="cursor-hover standard-hover-opacity"
						>
							Sponsorer
						</p>
						<p
							onClick={navigateToSuperClubs}
							className="cursor-hover standard-hover-opacity"
						>
							Föreningar
						</p>
						<StandardButton
							text="Logga ut"
							onClick={handleOnLogoutClick}
						/>
					</div>
				)}
			</div>
		</nav>
	);
};
