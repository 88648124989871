interface Props {
	text: string;
	onClick?: () => void;
	secondary?: boolean;
	color?: string;
	button?: boolean;
	form?: string;
}

/**
 * Reusable button
 * TODO: make responsive
 */
export const StandardButton = (props: Props) => {
	const handleOnClick = () => {
		if (props.onClick) props.onClick();
	};

	return (
		<button
			type={props.button ? "button" : undefined}
			onClick={handleOnClick}
			className={
				"standard-button standard-button-border-radius standard-hover-opacity " +
				(props.secondary ? "secondary-button" : "primary-button")
			}
			style={{ backgroundColor: props.color }}
			form={props.form}
		>
			{props.text}
		</button>
	);
};
