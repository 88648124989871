import { useState, useEffect } from "react";
import { Nav } from "../../components/Nav";
import { Header } from "../../components/Header";
import { GridMenu } from "../../components/GridMenu";
import { ParterGrid } from "../../components/ParterGrid";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import { ParterData } from "../../components/ParterData";
import { CircularProgress } from "@mui/material";
import { Company, Notification } from "../../DBTypes";
import usePersistedState from "../../hooks/usePersistedState";
import background from "../../icons/Backgrounds/bg1.jpg";
import { BannerAd } from "../../components/BannerAd";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import { db } from "../../Firebase";
import "firebase/firestore";

const titles = [
	"Mina föreningar",
	"Mina erbjudanden",
	"Mina eventinbjudningar",
];

/**
 * Homepage for logged in company to view their info, clubs they sponsor and view and edit their deals or upload new deals.
 */
export const CompanyHomePage = () => {
	let auth = useAuth();
	const user = auth.user;

	const {
		getAdsFromCompanyPaginated,
		getCompanies,
		getClubs,
		getEventsForCompany,
		getNotifications,
	} = useFirestore();

	const initialCompany: Company = {
		id: "string;",
		isOnFrontPage: false,
		name: "",
		image_url: "",
		description: "",
		relatedClubs: [""],
		organisationNumber: "",
	};
	const [company, setCompany] = useState<Company>(initialCompany);
	const [eventNotifications, setEventNotifications] = useState<
		Notification[]
	>([]);
	const [adNotifications, setAdNotifications] = useState<Notification[]>([]);

	const [data, setData] = useState<ParterData[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [selectedTitle, setSelectedTitle] = usePersistedState(
		"selectedTitle",
		0
	);

	useEffect(() => {
		if (user) {
			getCompanies([user.firestoreUser.company_id])
				.then((resp) => {
					setCompany(resp[0]);
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		}
	}, []);

	useEffect(() => {
		setIsLoading(true);
		let cards: ParterData[] = [];
		setData([]);

		if (user && company) {
			if (selectedTitle === 0) {
				getClubs(company.relatedClubs)
					.then((resp) => {
						resp.forEach((club) => {
							cards.push({
								type: "company_club",
								object: club,
							});
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else if (selectedTitle === 1) {
				getAdsFromCompanyPaginated(
					user.firestoreUser.company_id,
					undefined,
					undefined,
					undefined,
					true
				)
					.then((resp) => {
						resp.ads.forEach((ad) => {
							// Don't show ad for club admins to manage if ad is connected to other clubs
							if (
								(user.firestoreUser.club_id &&
									ad.club_ids.length > 1) ||
								(user.firestoreUser.club_id &&
									!ad.club_ids.includes(
										user.firestoreUser.club_id
									))
							) {
								return;
							}
							cards.push({
								type: "editable_ad",
								object: ad,
							});
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else if (selectedTitle === 2) {
				getEventsForCompany(user.firestoreUser.company_id)
					.then((resp) => {
						resp.forEach((event) => {
							// Don't show ad for club admins to manage if ad is connected to other clubs
							cards.push({
								type: "event",
								object: event,
							});
						});
						setData(cards);
					})
					.catch((error) => {
						console.log(error);
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		}
	}, [company, selectedTitle]);

	const fetchNotifications = async (uid: string) => {
		try {
			let events: Notification[] = [];
			let ads: Notification[] = [];
			const resp = await getNotifications(uid);
			for (const notis of resp) {
				if (notis.type == "event") {
					events.push(notis);
				}
				if (notis.type == "ad") {
					ads.push(notis);
				}
			}

			setEventNotifications(events);
			setAdNotifications(ads);
			return events;
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		if (user) {
			fetchNotifications(user.uid);
		}
	}, []);

	const onNotificationRead = async (
		event_name?: string,
		event_club?: string,
		ad_title?: string,
		ad_company?: string
	) => {
		const markNotificationAsRead = httpsCallable(
			functions,
			"markNotificationAsRead"
		);
		try {
			if (user) {
				if (event_club && event_name) {
					await markNotificationAsRead({
						uid: user.uid,
						event_name: event_name,
						event_club: event_club,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
				if (ad_title && ad_company) {
					await markNotificationAsRead({
						uid: user.uid,
						ad_title: ad_title,
						ad_company: ad_company,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};
	const onNotificationRemove = async (
		event_name?: string,
		event_club?: string,
		ad_title?: string,
		ad_company?: string
	) => {
		const deleteNotification = httpsCallable(
			functions,
			"deleteNotification"
		);
		try {
			if (user) {
				if (event_club && event_name) {
					await deleteNotification({
						uid: user.uid,
						event_name: event_name,
						event_club: event_club,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
				if (ad_title && ad_company) {
					await deleteNotification({
						uid: user.uid,
						ad_title: ad_title,
						ad_company: ad_company,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<Nav />

			{/*Header*/}
			<div>
				<Header
					object={company}
					type={"company-home"}
					title="Utforska dina föreningar samt redigera och lägg upp erbjudanden"
					backgroundImage={background}
				/>
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />
			<GridMenu
				title={titles[0]}
				title2={titles[1]}
				title3={titles[2]}
				selectedTitle={selectedTitle}
				setSelectedTitle={setSelectedTitle}
				eventNotifications={eventNotifications}
				adNotifications={adNotifications}
				onReadClick={onNotificationRead}
				onRemoveClick={onNotificationRemove}
				addButton
			/>

			{/*CARD GRID*/}

			<div className={"card-grid"}>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<div>
						{data ? (
							<ParterGrid cards={data} loggedIn />
						) : (
							<div>{errorMessage}</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
