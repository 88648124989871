type HookProps = {
	getErrorMessageLogin: (code: string) => string;
};

export const useErrorMessages = (): HookProps => {
	const getErrorMessageLogin = (code: string) => {
		switch (code) {
			case "auth/wrong-password":
				return "Fel lösenord";
            case "auth/user-not-found":
                return "Kunde ej hitta användare";
			default:
				return "Kunde inte logga in";
		}
	};

	return {
		getErrorMessageLogin,
	};
};
