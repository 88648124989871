import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Company, SponsorGroup } from "../DBTypes";
import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface Props {
	label: string;
	elements: Company[];
	groups: SponsorGroup[];
	parentCallback: any;
	defaultValues?: string[];
}

export function GroupSelectDropdown(props: Props) {
	const [values, setValues] = useState<string[]>([]);
	const { elements, groups } = props;

	const handleChange = (event: any) => {
		const {
			target: { value },
		} = event;

		const singleValue = value[value.length - 1];

		try {
			const myString = singleValue.split(" ");
			const first = myString.shift();
			const rest = myString.join(" ");

			// Whole sponsor group is clicked
			if (first === "SponsorGroupName") {
				const sponsorGroup = groups.find(
					(group) => group.name === rest
				);
				if (sponsorGroup) {
					const sponsors = getSponsorGroup(
						elements,
						sponsorGroup
					).map((s) => {
						return s.name;
					});

					// Whole group is already selected -> clear it
					if (
						getSponsorGroup(elements, sponsorGroup).every((e) =>
							values.includes(e.name)
						)
					) {
						const cleared = values.filter(
							(e) => !sponsors.includes(e)
						);

						setValues(cleared);

						// Whole group not selected -> select remaining
					} else {
						const merged = [...values, ...sponsors];
						let unique = merged.filter((element, index) => {
							return merged.indexOf(element) === index;
						});
						setValues(unique);
					}
				}

				return;
			}
		} catch (error) {
			// String could not be splitted, continue
		}

		setValues(typeof value === "string" ? value.split(",") : value);
	};

	useEffect(() => {
		props.parentCallback(values);
	}, [values]);

	useEffect(() => {
		if (props.defaultValues) {

			setValues(props.defaultValues);
		}
	}, []);

	// Get all sponsors in specific sponsor group
	const getSponsorGroup = (sponsors: Company[], group: SponsorGroup) => {
		return elements.filter((element) =>
			group.organisationNumbers.includes(element.organisationNumber)
		);
	};

	// Render Sponsor group followed by indented list of belonging sponsors
	const renderSelectGroup = (group: SponsorGroup) => {
		const items = getSponsorGroup(elements, group).map((e) => {
			return (
				<MenuItem key={e.id} value={e.name}>
					<Checkbox
						checked={values.indexOf(e.name) > -1}
						sx={{
							ml: 3,
						}}
					/>
					<ListItemText primary={e.name} />
				</MenuItem>
			);
		});
		return [
			<MenuItem
				key={group.prioritizationNumber}
				value={"SponsorGroupName " + group.name}
			>
				<Checkbox
					checked={getSponsorGroup(elements, group).every((e) =>
						values.includes(e.name)
					)}
				/>
				<ListItemText primary={group.name} />
			</MenuItem>,
			items,
		];
	};

	return (
		<div>
			<FormControl sx={{ width: "100%" }}>
				<InputLabel htmlFor="grouped-select">{props.label}</InputLabel>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={values}
					onChange={handleChange}
					input={<OutlinedInput label={props.label} />}
					renderValue={(selected) => selected.join(", ")}
					MenuProps={MenuProps}
				>
					{groups.map((group) => renderSelectGroup(group))}
				</Select>
			</FormControl>
		</div>
	);
}
