import { ParterData } from "../components/ParterData";
import { Ad, Club, Company, Event } from "../DBTypes";

const isClub = (card: Ad | Club | Company | Event): card is Club => {
	return true;
};
const isAd = (card: Ad | Club | Company | Event): card is Ad => {
	return true;
};
const isCompany = (card: Ad | Club | Company | Event): card is Company => {
	return true;
};

export function SearchAds(searchText: string, data: ParterData[]) {
	const searchTerm = searchText.toLowerCase();

	return data.filter((value) => {
		if (isAd(value.object)) {
			return value.object.company_name
				.toLowerCase()
				.match(new RegExp(searchTerm, "g"));
		} else {
			return 0;
		}
	});
}

export function SearchClubAds(searchText: string, data: ParterData[]) {
	const searchTerm = searchText.toLowerCase();

	const filteredData: ParterData[] = [];
	data.forEach((value) => {
		if (isAd(value.object)) {
			value.object.club_names.every((club_name) => {
				if (
					club_name.toLowerCase().includes(searchTerm.toLowerCase())
				) {
					filteredData.push(value);
					return false;
				} else {
					return true;
				}
			});
		}
	});
	return filteredData;
}

export function SearchSponsors(searchText: string, data: ParterData[]) {
	const searchTerm = searchText.toLowerCase();

	return data.filter((value) => {
		if (isCompany(value.object)) {
			return value.object.name
				.toLowerCase()
				.match(new RegExp(searchTerm, "g"));
		} else {
			return 0;
		}
	});
}
export function FindClubSponsors(orgNumbers: string[], data: Company[]) {
	return data.filter((value) => {
		return orgNumbers.includes(value.organisationNumber);
	});
}
export function SearchClubs(searchText: string, data: ParterData[]) {
	const searchTerm = searchText.toLowerCase();

	return data.filter((value) => {
		if (isClub(value.object)) {
			return value.object.name
				.toLowerCase()
				.match(new RegExp(searchTerm, "g"));
		} else {
			return 0;
		}
	});
}
