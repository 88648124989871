import { useNavigate } from "react-router-dom";

type HookProps = {
	navigateToMainPage: () => void;
	navigateToPolicyPage: () => void;
	navigateToLoginPage: () => void;
	navigateToUpdatePasswordPage: () => void;
	navigateToUpdateUserPage: () => void;
	navigateToCompanyPage: (param: string) => void;
	navigateToClubPage: (param: string) => void;
	navigateToSearchResultsPage: (params: string) => void;
	navigateToCompanyHomePage: () => void;
	navigateToClubHomePage: () => void;
	navigateToCompanyClubPage: (param: string) => void;
	navigateToManageAdsPage: (param?: string, param2?: boolean) => void;
	navigateToManageEventsPage: (event_id?: string) => void;
	navigateToForgotPasswordPage: () => void;
	navigateToSuperSponsors: () => void;
	navigateToSuperSponsor: (param: string) => void;
	navigateToSuperClub: (param: string) => void;
	navigateToSuperClubs: () => void;
	navigateToSuperAddClub: () => void;
	navigateToSuperAddSponsor: () => void;
	navigateToAboutPage: () => void;
	navigateToCompanyInfoPage: () => void;
	navigateToClubAdminPage: () => void;
};

/**
 * Enum for all pages on the website.
 */
export const Pages = {
	MAIN_PAGE: "/",
	POLICY_PAGE: "/privacy_policy",
	LOGIN_PAGE: "/login",
	UPDATE_PASSWORD_PAGE: "/update_password",
	UPDATE_USER_PAGE: "/update_user",
	COMPANY_PAGE: "/company",
	CLUB_PAGE: "/club",
	COMPANY_HOME: "/home",
	CLUB_HOME: "/club-home",
	COMPANY_CLUB: "/sponsored",
	MANAGE_ADS: "/ads",
	MANAGE_EVENTS: "/events",
	SEARCH_RESULTS: "/search",
	SIGNUP_PAGE: "/signup",
	FORGOT_PASSWORD_PAGE: "/forgot-password",
	SUPER_SPONSORS: "/admin",
	SUPER_CLUBS: "/admin/clubs",
	SUPER_SPONSOR: "/admin/sponsors",
	SUPER_CLUB: "/admin/club",
	SUPER_ADD_SPONSOR: "/admin/add-sponsor",
	SUPER_ADD_CLUB: "/admin/add-club",
	ABOUT_PAGE: "/about",
	COMPANY_INFO: "/myinfo",
	CLUB_ADMIN: "/myclub",
};

/**
 * This hook is used to navigate between pages in the WHOLE website.
 * Used to make it easier with spelling mistakes or if pages changes names in the future.
 * @returns functions to navigate throughout the web page
 */
export const usePageNavigation = (): HookProps => {
	let navigate = useNavigate();

	const navigateToMainPage = () => {
		navigate(Pages.MAIN_PAGE);
	};
	const navigateToPolicyPage = () => {
		navigate(Pages.POLICY_PAGE);
	};

	const navigateToLoginPage = () => {
		navigate(Pages.LOGIN_PAGE);
	};
	const navigateToUpdatePasswordPage = () => {
		navigate(Pages.UPDATE_PASSWORD_PAGE);
	};
	const navigateToUpdateUserPage = () => {
		navigate(Pages.UPDATE_USER_PAGE);
	};

	const navigateToCompanyPage = (param: string) => {
		navigate(Pages.COMPANY_PAGE + "/" + param);
	};
	const navigateToClubPage = (param: string) => {
		navigate(Pages.CLUB_PAGE + "/" + param);
	};
	const navigateToCompanyHomePage = () => {
		navigate(Pages.COMPANY_HOME);
	};
	const navigateToClubHomePage = () => {
		navigate(Pages.CLUB_HOME);
	};
	const navigateToAboutPage = () => {
		navigate(Pages.ABOUT_PAGE);
	};
	const navigateToCompanyClubPage = (param: string) => {
		navigate(Pages.COMPANY_CLUB + "/" + param);
	};
	const navigateToManageAdsPage = (param?: string, param2?: boolean) => {
		if (param) {
			if (param2) {
				navigate(Pages.MANAGE_ADS + "/" + param, {
					state: { club_admin: param2 },
				});
			} else {
				navigate(Pages.MANAGE_ADS + "/" + param);
			}
		} else if (param2) {
			navigate(Pages.MANAGE_ADS, { state: { club_admin: param2 } });
		} else {
			navigate(Pages.MANAGE_ADS);
		}
	};

	const navigateToManageEventsPage = (event_id?: string) => {
		if (event_id) {
			navigate(Pages.MANAGE_EVENTS + "/" + event_id);
		} else {
			navigate(Pages.MANAGE_EVENTS);
		}
	};

	const navigateToSearchResultsPage = (params: string) => {
		navigate({
			pathname: Pages.SEARCH_RESULTS,
			search: params,
		});
	};

	const navigateToForgotPasswordPage = () => {
		navigate(Pages.FORGOT_PASSWORD_PAGE);
	};

	const navigateToSuperSponsors = () => {
		navigate(Pages.SUPER_SPONSORS);
	};

	const navigateToSuperSponsor = (param: string) => {
		navigate(Pages.SUPER_SPONSOR + "/" + param);
	};

	const navigateToSuperClub = (param: string) => {
		navigate(Pages.SUPER_CLUB + "/" + param);
	};

	const navigateToSuperClubs = () => {
		navigate(Pages.SUPER_CLUBS);
	};

	const navigateToSuperAddClub = () => {
		navigate(Pages.SUPER_ADD_CLUB);
	};

	const navigateToSuperAddSponsor = () => {
		navigate(Pages.SUPER_ADD_SPONSOR);
	};
	const navigateToCompanyInfoPage = () => {
		navigate(Pages.COMPANY_INFO);
	};
	const navigateToClubAdminPage = () => {
		navigate(Pages.CLUB_ADMIN);
	};

	return {
		navigateToMainPage,
		navigateToPolicyPage,
		navigateToLoginPage,
		navigateToUpdatePasswordPage,
		navigateToUpdateUserPage,
		navigateToCompanyPage,
		navigateToClubPage,
		navigateToCompanyHomePage,
		navigateToClubHomePage,
		navigateToCompanyClubPage,
		navigateToManageAdsPage,
		navigateToManageEventsPage,
		navigateToSearchResultsPage,
		navigateToForgotPasswordPage,
		navigateToSuperSponsors,
		navigateToSuperClubs,
		navigateToSuperAddClub,
		navigateToSuperAddSponsor,
		navigateToSuperClub,
		navigateToSuperSponsor,
		navigateToAboutPage,
		navigateToCompanyInfoPage,
		navigateToClubAdminPage,
	};
};
