import { Nav } from "../../components/Nav";

interface Props {
	children: any;
	title: string;
	shouldIncludePlusIcon?: boolean;
	navigationToOtherPage?: () => void;
}

export const SuperAdminTemplate = (props: Props) => {
	return (
		<div>
			<Nav />
			<div className="w-full flex justify-center">
				<div className="flex flex-col align-top">
					<div className="flex justify-between">
						<p className="text-3xl font-black mb-5">
							{props.title}
						</p>
						{props.shouldIncludePlusIcon && (
							<p
								className="text-3xl font-black text-sky-600 mb-5 cursor-hover standard-hover-opacity"
								onClick={props.navigationToOtherPage}
							>
								+
							</p>
						)}
					</div>
					{props.children}
				</div>
			</div>
		</div>
	);
};
