import { useState, useEffect } from "react";
import { Nav } from "../../components/Nav";
import { Header } from "../../components/Header";
import { GridMenu } from "../../components/GridMenu";
import { ParterGrid } from "../../components/ParterGrid";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import { ParterData } from "../../components/ParterData";
import { CircularProgress } from "@mui/material";
import { Club, Company, Ad, Event, Notification } from "../../DBTypes";
import usePersistedState from "../../hooks/usePersistedState";
import { BannerAd } from "../../components/BannerAd";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";

let cards: ParterData[] = [];
const titles = ["Mina sponsorer", "Mina erbjudanden", "Mina event"];

/**
 * Homepage for logged in company to view their info, clubs they sponsor and view and edit their deals or upload new deals.
 */
export const ClubHomePage = () => {
	let auth = useAuth();
	const user = auth.user;

	const {
		getCompaniesFromClub,
		getClubs,
		getAdsFromCompanyPaginated,
		getEventsFromClub,
		getNotifications,
	} = useFirestore();

	const [club, setClub] = useState<Club>();

	const [data, setData] = useState<ParterData[]>([]);
	const [oldData, setOldData] = useState<ParterData[]>([]);
	const [groupIndices, setGroupIndices] = useState<number[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [notifications, setNotifications] = useState<Notification[]>([]);

	const [selectedTitle, setSelectedTitle] = usePersistedState(
		"selectedTitle",
		0
	);

	function RenderResults() {
		if (data) {
			if (data.length > 0) {
				if (groupIndices.length > 0) {
					let lower = 0;
					let upper = 0;

					return (
						<div className="mt-12">
							{groupIndices.map((value, index) => {
								upper += value;
								const groups = [...data];
								const group = groups.slice(lower, upper);
								lower = upper;
								if (group.length > 0) {
									return (
										<div key={index}>
											<div className="text-white text-3xl">
												{club && club.sponsor_groups
													? club.sponsor_groups[index]
															.name
													: ""}
											</div>
											<ParterGrid
												cards={group}
												loggedIn
											/>
										</div>
									);
								}
							})}
						</div>
					);
				} else {
					return <ParterGrid cards={data} loggedIn />;
				}
			} else {
				return (
					<div className="text-white p-20">
						{"Här var det tomt. Hittade inga " +
							(selectedTitle === 0
								? "sponsorer."
								: "erbjudanden.")}
					</div>
				);
			}
		}
	}
	const isCompany = (
		object: Club | Company | Ad | Event
	): object is Company => {
		return true;
	};
	const sortBySponsorGroups = (_data: ParterData[]) => {
		cards = [];
		let numbers: number[] = [];
		if (club && club.sponsor_groups) {
			club.sponsor_groups.forEach((group) => {
				let lastIndex = 0;
				_data.forEach((d) => {
					if (isCompany(d.object)) {
						if (
							group.organisationNumbers.includes(
								d.object.organisationNumber
							)
						) {
							cards.push(d);
							lastIndex += 1;
						}
					}
				});
				numbers.push(lastIndex);
			});
			setGroupIndices(numbers);
			return cards;
		}
	};

	useEffect(() => {
		setIsLoading(true);

		if (user && user.firestoreUser.club_id) {
			getClubs([user.firestoreUser.club_id])
				.then((resp) => {
					setClub(resp[0]);
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		}
	}, []);

	// Filter here
	useEffect(() => {
		setData(oldData);
		setIsLoading(false);
	}, [oldData]);

	useEffect(() => {
		setData([]);
		setGroupIndices([]);
		setIsLoading(true);
		let cards: ParterData[] = [];

		if (user && club) {
			if (selectedTitle === 0) {
				getCompaniesFromClub(club.id)
					.then((resp) => {
						resp.forEach((company) => {
							cards.push({
								type: "company",
								companyDialog: true,
								object: company,
							});
						});
						if (
							club.sponsor_groups &&
							club.sponsor_groups.length > 1
						) {
							let c = sortBySponsorGroups(cards);

							if (c) {
								setOldData(c);
							}
						} else {
							setOldData(cards);
						}
					})
					.catch((error) => {
						setOldData([]);
						setErrorMessage(error);
						setIsLoading(false);
					});
			} else if (selectedTitle === 1 && user.firestoreUser.club_id) {
				getAdsFromCompanyPaginated(
					user.firestoreUser.club_id,
					undefined,
					undefined,
					undefined,
					true
				)
					.then((resp) => {
						resp.ads.forEach((ad) => {
							cards.push({
								type: "editable_ad",
								object: ad,
							});
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else if (selectedTitle === 2 && user.firestoreUser.club_id) {
				getEventsFromClub(user.firestoreUser.club_id)
					.then((resp) => {
						resp.forEach((event) => {
							cards.push({
								type: "editable_event",
								object: event,
							});
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		}
	}, [club, selectedTitle]);

	const fetchNotifications = async (uid: string) => {
		try {
			let responses: Notification[] = [];
			const resp = await getNotifications(uid);
			for (const notis of resp) {
				if (notis.type == "response") {
					responses.push(notis);
				}
			}

			setNotifications(responses);
			return responses;
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (user) {
			fetchNotifications(user.uid);
		}
	}, []);

	const onNotificationRead = async (
		event_name?: string,
		responding_company?: string
	) => {
		const markNotificationAsRead = httpsCallable(
			functions,
			"markNotificationAsRead"
		);
		try {
			if (user) {
				if (event_name && responding_company) {
					await markNotificationAsRead({
						uid: user.uid,
						event_name: event_name,
						responding_company: responding_company,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};
	const onNotificationRemove = async (
		event_name?: string,
		responding_company?: string
	) => {
		const deleteNotification = httpsCallable(
			functions,
			"deleteNotification"
		);
		try {
			if (user) {
				if (event_name && responding_company) {
					await deleteNotification({
						uid: user.uid,
						event_name: event_name,
						responding_company: responding_company,
					}).then(() => {
						fetchNotifications(user.uid);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<Nav />

			{/*Header*/}
			<div className={""}>
				<Header
					object={club}
					type={"club-home"}
					title="Utforska dina sponsorer samt redigera och lägg till erbjudanden"
					backgroundImage={club ? club.background_url : ""}
				/>
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />

			<GridMenu
				title={titles[0]}
				title2={titles[1]}
				title3={titles[2]}
				selectedTitle={selectedTitle}
				setSelectedTitle={setSelectedTitle}
				addButton
				clubAdmin
				responseNotifications={notifications}
				onReadClick={onNotificationRead}
				onRemoveClick={onNotificationRemove}
			/>

			{/*CARD GRID*/}

			<div
				className={"card-grid"}
				style={{
					backgroundColor: club ? club.background_color : "",
				}}
			>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<div>{RenderResults()}</div>
				)}
			</div>
		</div>
	);
};
