import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { SearchAds, SearchSponsors } from "../../hooks/frontendSearch";
import { Nav } from "../../components/Nav";
import { Header } from "../../components/Header";
import { GridMenu } from "../../components/GridMenu";
import { ParterGrid } from "../../components/ParterGrid";
import { ParterData } from "../../components/ParterData";
import { CircularProgress } from "@mui/material";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Club, Company, Ad, Event } from "../../DBTypes";
import { BannerAd } from "../../components/BannerAd";

let latestDoc: QueryDocumentSnapshot<DocumentData>;
let cards: ParterData[] = [];
const titles = ["Erbjudanden", "Sponsorer"];

/**
 * Page for selected club. Gets club using club ID from url and displays club info, deals/sponsors with filtering option.
 */

export const ClubPage = () => {
	const { club_id } = useParams<string>();
	const [data, setData] = useState<ParterData[]>([]);
	const [oldData, setOldData] = useState<ParterData[]>([]);
	const [groupIndices, setGroupIndices] = useState<number[]>([]);
	const [fromLink, setFromLink] = useState<boolean>(true);

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [categoryFilter, setCategoryFilter] = useState<string>("");

	const { getAdsFromClubPaginated, getClubs, getCompaniesFromClub } =
		useFirestore();

	const [selectedTitle, setSelectedTitle] = useState<number>(0);

	const [searchText, setSearchText] = useState<string>("");

	const [club, setClub] = useState<Club>();

	function RenderResults() {
		if (data) {
			if (data.length > 0) {
				if (groupIndices.length > 0 && !searchText) {
					let lower = 0;
					let upper = 0;

					return (
						<div className="mt-12">
							{groupIndices.map((value, index) => {
								upper += value;
								const groups = [...data];
								const group = groups.slice(lower, upper);
								lower = upper;
								if (group.length > 0) {
									return (
										<div key={index}>
											<div className="text-white text-3xl">
												{club && club.sponsor_groups
													? club.sponsor_groups[index]
														.name
													: ""}
											</div>
											<ParterGrid cards={group} />
										</div>
									);
								}
							})}
						</div>
					);
				} else {
					return (
						<ParterGrid
							cards={data}
							fromLink={fromLink}
							setFromLink={setFromLink}
						/>
					);
				}
			} else {
				return (
					<div className="text-white p-20">
						{"Här var det tomt. Hittade inga " +
							(selectedTitle === 0
								? "erbjudanden."
								: "sponsorer.")}
					</div>
				);
			}
		}
	}

	useEffect(() => {
		setIsLoading(true);

		if (club_id) {
			getClubs([club_id])
				.then((resp) => {
					setClub(resp[0]);
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		}
	}, []);

	useEffect(() => {
		if (selectedTitle === 0) {
			let filteredData = SearchAds(searchText, oldData);

			setData(filteredData);
		}
		if (selectedTitle === 1) {
			let filteredData = SearchSponsors(searchText, oldData);

			setData(filteredData);
		}
		setIsLoading(false);
	}, [searchText, oldData]);

	useEffect(() => {
		setData([]);
		setGroupIndices([]);
		setIsLoading(true);
		if (club) {
			if (selectedTitle === 0) {
				getAdsFromClubPaginated(club.id, undefined, categoryFilter)
					.then((resp) => {
						cards = [];
						latestDoc = resp.latestDoc;
						resp.ads.forEach((ad) => {
							if (!ad.isForCompanies) {
								cards.push({
									type: "ad",
									object: ad,
								});
							}
						});

						setOldData(cards);
					})
					.catch((error) => {
						setOldData([]);
						setErrorMessage(error);
						setIsLoading(false);
					});
			} else if (selectedTitle === 1) {
				setErrorMessage("");

				getCompaniesFromClub(club.id, categoryFilter)
					.then((resp) => {
						cards = [];

						resp.forEach((company) => {
							cards.push({
								type: "company",
								companyDialog: true,
								object: company,
							});
						});
						if (
							club.sponsor_groups &&
							club.sponsor_groups.length > 1
						) {
							let c = sortBySponsorGroups(cards);

							if (c) {
								setOldData(c);
							}
						} else {
							setOldData(cards);
						}
					})
					.catch((error) => {
						setOldData([]);
						setErrorMessage(error);
						setIsLoading(false);
					});
			}
		}
	}, [club, categoryFilter, selectedTitle]);
	const isCompany = (object: Club | Company | Ad | Event): object is Company => {
		return true;
	};

	const sortBySponsorGroups = (_data: ParterData[]) => {
		cards = [];
		let numbers: number[] = [];
		if (club && club.sponsor_groups) {
			club.sponsor_groups.forEach((group) => {
				let lastIndex = 0;
				_data.forEach((d) => {
					if (isCompany(d.object)) {
						if (
							group.organisationNumbers.includes(
								d.object.organisationNumber
							)
						) {
							cards.push(d);
							lastIndex += 1;
						}
					}
				});
				numbers.push(lastIndex);
			});
			setGroupIndices(numbers);
			return cards;
		}
	};
	return (
		<div>
			<div>
				<Nav />

				{/*Header*/}
				<div className={""}>
					<Header
						object={club}
						noTitle
						type={"club"}
						parentCallback={setSearchText}
						searchValue={searchText}
						backgroundImage={club ? club.background_url : ""}
					/>
				</div>

				{/*Ad banner component, pass "show = true" to show on page*/}
				<BannerAd />

				<GridMenu
					title={titles[0]}
					title2={titles[1]}
					selectedTitle={selectedTitle}
					setSelectedTitle={setSelectedTitle}
					setCategoryFilter={setCategoryFilter}
					memberUrl={club ? club.member_url : ""}
					sponsorUrl={club ? club.sponsor_url : ""}
				/>

				<div
					className={"card-grid"}
					style={{
						backgroundColor: club ? club.background_color : "",
					}}
				>
					{isLoading ? (
						<CircularProgress color="inherit" />
					) : (
						<div>{RenderResults()}</div>
					)}
				</div>
			</div>
		</div>
	);
};
