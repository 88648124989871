import { useState, useEffect } from "react";
import { Nav } from "../components/Nav";
import { Header } from "../components/Header";
import { GridMenu } from "../components/GridMenu";
import { ParterGrid } from "../components/ParterGrid";
import { useFirestore } from "../hooks/useFirestore";
import { ParterData } from "../components/ParterData";
import { BannerAd } from "../components/BannerAd";
import { CircularProgress } from "@mui/material";

/**
 * Homepage of website. Allows searching for clubs/sponsors and displays most popular sponsors set in admin panel.
 */
export const HomePage = () => {
	const { getFrontPageCompanies } = useFirestore();

	const [data, setData] = useState<ParterData[]>();

	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		let cards: ParterData[] = [];

		getFrontPageCompanies()
			.then((resp) => {
				resp.forEach((company) => {
					const card: ParterData = {
						type: "company",
						object: company,
					};
					cards.push(card);
				});
				setData(cards);
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<div>
			<Nav />

			{/*Header*/}
			<div className={"header"}>
				<Header />
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />

			<div className={"grid-menu"}>
				<GridMenu title="Populära sponsorer" />
			</div>

			{/*CARD GRID*/}
			<div className={"card-grid"}>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<div>
						{data ? (
							<ParterGrid cards={data} />
						) : (
							<div>{errorMessage}</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
