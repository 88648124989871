import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { PageLocked } from "./login/PageLocked";

interface Props {
	valid_user_type: number;
	valid_user_type2?: number;
}

/**
 * ProtectedRoute can be wrapped around a Route component to make sure the user trying to reach a path is authenticated correctly
 */
export const ProtectedRoute = (props: Props) => {
	const auth = useAuth();

	if (
		(!auth.user ||
			(auth.user.firestoreUser.user_type !== props.valid_user_type &&
				auth.user.firestoreUser.user_type !==
					props.valid_user_type2)) &&
		auth.user?.firestoreUser.user_type !== 2
	) {
		return <PageLocked />;
	}

	return <Outlet />;
};
