import { ChangeEventHandler } from "react";

interface Props {
	placeholderText: string;
	type: string;
	onChange?: ChangeEventHandler;
	maxLength?: number;
	label?: string;
	name?: string;
	searchBar?: boolean;
	value?: string;
	noAutoComplete?: boolean;
}

/**
 * Reusable input field
 */
export const InputField = (props: Props) => {
	return (
		<div>
			{props.label && <p className="font-black mb-2">{props.label}</p>}
			<input
				type={props.type}
				placeholder={props.placeholderText}
				className={
					`p-2` +
					(props.searchBar
						? " search-input-field lg:w-96 mymd:w-72 w-60"
						: " standard-input-field w-80")
				}
				name={props.name}
				onChange={props.onChange}
				maxLength={props.maxLength}
				value={props.value}
				autoComplete={props.noAutoComplete ? "off" : "on"}
			/>
		</div>
	);
};
