import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
	limit,
	orderBy,
	startAfter,
	QueryDocumentSnapshot,
	DocumentData,
	addDoc,
	QueryConstraint,
	updateDoc,
	arrayUnion,
	arrayRemove,
	deleteDoc,
	Timestamp,
} from "firebase/firestore";
import {
	deleteObject,
	getDownloadURL,
	getStorage,
	ref,
	uploadBytes,
	UploadResult,
} from "firebase/storage";
import {
	Ad,
	Club,
	ClubOfferInstruction,
	Company,
	DBUser,
	PostAd,
	Event,
	PostEvent,
	PostClub,
	PostCompany,
	SponsorGroup,
	EventInvite,
	Notification,
	SponsorDealInfo,
} from "../DBTypes";
import { db } from "../Firebase";
import { v4 as uuidv4 } from "uuid";
import { Pages } from "./usePageNavigation";
import {
	getAuth,
	sendSignInLinkToEmail,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import { secondaryApp } from "../Firebase";
import { config } from "../Constants";
import { error } from "console";

var url = config.url.API_URL;

type HookProps = {
	createUserManually: (
		email: string,
		password: string,
		companyId: string,
		first_name: string,
		last_name: string
	) => Promise<void>;
	getUser: (uid: string) => Promise<DBUser>;
	getUsersByCompanyId: (company_id: string) => Promise<DBUser[]>;
	getUsersByClubId: (club_id: string) => Promise<string[]>;
	createNewUser: (
		uid: string,
		company_id: string,
		email: string,
		first_name: string,
		last_name: string,
		admin: boolean
	) => Promise<DBUser>;
	createNewClubUser: (
		uid: string,
		club_id: string,
		email: string,
		first_name: string,
		last_name: string
	) => Promise<DBUser>;
	setUserCompanyID: (uid: string, newCompanyID: string) => Promise<void>;

	updateUser: (
		uid: string,
		email?: string,
		first_name?: string,
		last_name?: string
	) => Promise<void>;
	getFrontPageCompanies: () => Promise<Company[]>;
	removeCompany: (companyID: string) => Promise<void>;
	removeClub: (clubID: string) => Promise<void>;
	getAdFromID: (ad_id: string) => Promise<Ad>;
	getEventFromID: (event_id: string) => Promise<Event>;
	getAdsFromCompanyPaginated: (
		company_id: string,
		latestDoc?: QueryDocumentSnapshot<DocumentData>,
		category?: string,
		region?: string,
		getExpired?: boolean
	) => Promise<AdPagination>;
	getEventsFromClub: (club_id: string) => Promise<Event[]>;
	getAdsFromCompany: (company_id: string) => Promise<Ad[]>;
	getEventsForCompany: (company_id: string) => Promise<Event[]>;
	getAdsFromClubPaginated: (
		club_id: string,
		latestDoc?: QueryDocumentSnapshot<DocumentData>,
		category?: string
	) => Promise<AdPagination>;
	getCompaniesFromClub: (
		club_id: string,
		category?: string
	) => Promise<Company[]>;
	uploadAd: (adInfo: PostAd) => Promise<void>;
	uploadEvent: (eventInfo: PostEvent) => Promise<void>;
	updateAd: (
		ad_id: string,
		company_name: string,
		category?: string,
		club_ids?: string[],
		club_names?: string[],
		description?: string,
		image?: File,
		old_image_url?: string,
		whiteLogo?: boolean,
		clubOfferInstructions?: ClubOfferInstruction[],
		title?: string,
		contactPerson?: string,
		phoneNumber?: string,
		email?: string,
		expiry_date?: Date | null
	) => Promise<string>;
	updateEvent: (
		event_id: string,
		title: string,
		date: Date,
		street: string,
		area: string,
		description: string,

		invited?: EventInvite[],
		image?: File,
		old_image_url?: string
	) => Promise<string>;
	updateClub: (
		club_id: string,
		clubName?: string,
		description?: string,
		region?: string,
		image?: File,
		old_image_url?: string,
		organisationNumbers?: string[],
		oldOrganisationNumbers?: string[],
		member_url?: string,
		sponsor_url?: string,
		backgroundImage?: File | string,
		partnerBackgroundImage?: File | string,
		old_background_url?: string,
		old_partner_background_url?: string,
		background_color?: string,
		sponsor_groups?: SponsorGroup[],
		email?: string,
		hideLogo?: boolean
	) => Promise<void>;
	updateCompany: (
		company_id: string,
		companyName?: string,
		description?: string,
		image?: File,
		old_image_url?: string,
		isOnFrontPage?: boolean,
		whiteLogo?: boolean,
		email?: string,
		categories?: string[],
		address?: string,
		phoneNumber?: string,
		websiteURL?: string,
		contactName?: string,
		contactEmail?: string,
		contactPhone?: string,
		newAdmin?: boolean
	) => Promise<void>;
	uploadClub: (club: PostClub) => Promise<void>;
	uploadClubDeal: (
		clubId: string,
		deal: SponsorDealInfo,
		pdf: File
	) => Promise<void>;
	updateClubDeal: (
		clubId: string,
		deal: SponsorDealInfo,
		pdf: File
	) => Promise<void>;
	getDealByCompanyId: (
		club_id: string,
		company_id: string
	) => Promise<SponsorDealInfo | undefined>;
	uploadCompany: (company: PostCompany) => Promise<void>;
	searchCompany: (
		searchQuery: string,
		club_id?: string
	) => Promise<Company[]>;
	searchClub: (
		searchQuery: string,
		organisationNumber?: string
	) => Promise<Club[]>;
	getClubs: (club_ids: string[]) => Promise<Club[]>;
	getCompanies: (company_ids: string[]) => Promise<Company[]>;
	getCompaniesByOrganisationNumber: (
		organisationNumbers: string[]
	) => Promise<Company[]>;
	getAllCompanies: () => Promise<Company[]>;
	getAllClubs: () => Promise<Club[]>;
	getUsersFromCompany: (company_id: string) => Promise<DBUser[]>;
	getNotifications: (uid: string) => Promise<Notification[]>;
	deleteAd: (ad_id: string) => Promise<void>;
	deleteEvent: (event_id: string) => Promise<void>;
	deleteUser: (user_id: string) => Promise<void>;
};

const Collections = {
	COMPANIES: "companies",
	USERS: "users",
	CLUBS: "clubs",
	ADS: "ads",
	EVENTS: "events",
	NOTIFICATIONS: "notifications",
};

const StorageLocations = {
	COMPANY_LOGOS: "company_logos/",
	AD_LOGOS: "ad_logos/",
	EVENT_LOGOS: "event_logos/",
	CLUB_LOGOS: "club_logos/",
	CLUB_BACKGROUNDS: "club_backgrounds/",
	CLUB_FILES: "club_files/",
};

type AdPagination = {
	ads: Ad[];
	latestDoc: QueryDocumentSnapshot<DocumentData>;
};

type UploadAd = {
	category: string;
	club_ids: string[];
	club_names: string[];
	company_id: string;
	company_name: string;
	isForCompanies: boolean;
	description: string;
	image_url: string;
	whiteLogo?: boolean;
	title: string;
	clubOfferInstructions: ClubOfferInstruction[];
	regions: string[];
	contactPerson?: string;
	phoneNumber?: string;
	email?: string;
	expiry_date?: Timestamp;
};

type UploadEvent = {
	club_id: string;
	club_name: string;
	title: string;
	date: Timestamp;
	street: string;
	area: string;
	description: string;
	invited: EventInvite[];
	image_url: string;
};

type UploadClub = {
	image_url: string;
	name: string;
	region: string;
	description: string;
	member_url?: string;
	sponsor_url?: string;
	organisationNumbers: string[];
	background_url?: string;
	partner_background_url?: string;
	background_color?: string;
	sponsor_groups?: SponsorGroup[];
};

type UploadCompany = {
	name: string;
	isOnFrontPage: boolean;
	whiteLogo?: boolean;
	image_url: string;
	description: string;
	relatedClubs: string[];
	organisationNumber: string;
	categories?: string[];
	address?: string;
	phoneNumber?: string;
	website_url?: string;
};

// Convert PostClub to Firebase type (only difference is that image File is converted to a URL)
const getUploadClubToFirebaseType = (
	club: PostClub,
	image_url: string,
	background_url: string,
	partner_background_url: string
): UploadClub => {
	return {
		name: club.name,
		region: club.region,
		description: club.description,
		member_url: club.member_url,
		sponsor_url: club.sponsor_url,
		organisationNumbers: club.organisationNumbers,
		image_url: image_url,
		background_url: background_url,
		partner_background_url: partner_background_url,
		background_color: club.background_color,
		sponsor_groups: club.sponsor_groups,
	};
};

const getUploadCompanyToFirebaseType = (
	company: PostCompany,
	image_url: string,
	relatedClubs: string[]
): UploadCompany => {
	return {
		isOnFrontPage: company.isOnFrontPage,
		whiteLogo: company.whiteLogo,
		name: company.name,
		description: company.description,
		organisationNumber: company.organisationNumber,
		image_url: image_url,
		relatedClubs: relatedClubs,
		categories: company.categories,
		address: company.address,
		phoneNumber: company.phoneNumber,
		website_url: company.website_url,
	};
};

// Convert PostAd to Firebase type (only difference is that image File is converted to a URL and regions are added)
const getUploadAdToFirebaseType = (
	adInfo: PostAd,
	image_url: string,
	regions: string[]
): UploadAd => {
	var myTimestamp = adInfo.expiry_date
		? Timestamp.fromDate(new Date(adInfo.expiry_date))
		: undefined;
	var returnData = {
		category: adInfo.category,
		club_ids: adInfo.club_ids,
		club_names: adInfo.club_names,
		company_id: adInfo.company_id,
		company_name: adInfo.company_name,
		description: adInfo.description,
		image_url: image_url,
		whiteLogo: adInfo.whiteLogo,
		isForCompanies: adInfo.isForCompanies,
		title: adInfo.title,
		regions: regions,
		clubOfferInstructions: adInfo.clubOfferInstructions,
		contactPerson: adInfo.contactPerson,
		phoneNumber: adInfo.phoneNumber,
		email: adInfo.email,
		expiry_date: myTimestamp,
	};

	return returnData;
};

const getUploadEventToFirebaseType = (
	eventInfo: PostEvent,
	image_url: string
): UploadEvent => {
	var myTimestamp = Timestamp.fromDate(new Date(eventInfo.date));

	var returnData = {
		club_id: eventInfo.club_id,
		club_name: eventInfo.club_name,
		title: eventInfo.title,
		date: myTimestamp,
		street: eventInfo.street,
		area: eventInfo.area,
		description: eventInfo.description,
		sponsor_ids: eventInfo.sponsor_ids,
		invited: eventInfo.invited,
		image_url: image_url,
	};

	return returnData;
};

const storage = getStorage();
const uploadImageToStorageAndGetImageURL = async (
	image: File,
	location: string
): Promise<string> => {
	// Upload image to Firebase storage
	const randomID = uuidv4();
	const imageRef = ref(storage, location + image.name + randomID);

	var imageUploadResponse: UploadResult | undefined = undefined;
	await uploadBytes(imageRef, image).then((resp) => {
		imageUploadResponse = resp;
	});

	if (!imageUploadResponse) {
		return Promise.reject("Kunde inte ladda upp bild, prova igen");
	}

	// Get image download url
	var image_url = "";
	const tmpImageUploadResp = imageUploadResponse as UploadResult;
	await getDownloadURL(tmpImageUploadResp.ref).then((downloadURL) => {
		image_url = downloadURL;
	});

	if (image_url === "") {
		return Promise.reject("Kunde inte ladda upp bild, prova igen");
	}

	return Promise.resolve(image_url);
};

const deleteImageFromStorage = async (image_url: string): Promise<void> => {
	const imageRef = ref(storage, image_url);
	var isDeleted = false;
	await deleteObject(imageRef).then(() => {
		isDeleted = true;
	});
	if (isDeleted) return Promise.resolve();
	else return Promise.reject("Kunde inte ta bort bild");
};

const PAGINATION_LIMIT = 50;

export const useFirestore = (): HookProps => {
	/**
	 * Get user information from specific uid (only firestore information, NOT authentication info)
	 */
	const getUser = async (uid: string): Promise<DBUser> => {
		const docRef = doc(db, Collections.USERS, uid);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const user: DBUser = docSnap.data() as DBUser;
			return Promise.resolve(user);
		}

		return Promise.reject("Kunde inte hitta användare");
	};

	const getUsersByCompanyId = async (
		company_id: string
	): Promise<DBUser[]> => {
		const docSnap = await getDocs(collection(db, Collections.USERS));

		const users: DBUser[] = [];
		docSnap.forEach((doc) => {
			if (doc.exists()) {
				const user: DBUser = doc.data() as DBUser;
				user.id = doc.id;
				if (user.user_type === 1 && user.company_id === company_id) {
					users.push(user);
				}
			}
		});

		return Promise.resolve(users);
	};
	const getUsersByClubId = async (club_id: string): Promise<string[]> => {
		const docSnap = await getDocs(collection(db, Collections.USERS));

		const user_ids: string[] = [];
		docSnap.forEach((doc) => {
			if (doc.exists()) {
				const user: DBUser = doc.data() as DBUser;
				if (user.club_id === club_id) {
					user_ids.push(doc.id);
				}
			}
		});

		return Promise.resolve(user_ids);
	};

	const createUserManually = async (
		email: string,
		password: string,
		companyId: string,
		first_name: string,
		last_name: string
	): Promise<void> => {
		const secondAuth = getAuth(secondaryApp);

		const returnData = await createUserWithEmailAndPassword(
			secondAuth,
			email,
			password
		)
			.then((newUser) => {
				createNewUser(
					newUser.user.uid,
					companyId,
					email,
					first_name,
					last_name,
					true
				).catch((error) => {
					return Promise.reject(
						"Kunde inte skapa ny användare i databas"
					);
				});

				secondAuth.signOut();
			})
			.catch((error) => {
				return Promise.reject("Kunde inte skapa ny användare");
			});

		return returnData;
	};

	/**
	 * Create a firestore user (NOT authentication user)
	 */
	const createNewUser = async (
		uid: string,
		company_id: string,
		email: string,
		first_name: string,
		last_name: string,
		admin: boolean
	): Promise<DBUser> => {
		const docRef = doc(db, Collections.USERS, uid);
		const userInfo: DBUser = {
			user_type: 1,
			company_id: company_id,
			email: email,
			first_name: first_name,
			last_name: last_name,
			staff: admin ? undefined : true,
		};

		const returnData = await setDoc(docRef, userInfo)
			.then(() => {
				return Promise.resolve(userInfo);
			})
			.catch((error) => {
				return Promise.reject("Kunde inte skapa ny användare");
			});

		return returnData;
	};

	const createNewClubUser = async (
		uid: string,
		club_id: string,
		email: string,
		first_name: string,
		last_name: string
	): Promise<DBUser> => {
		const docRef = doc(db, Collections.USERS, uid);
		const userInfo: DBUser = {
			user_type: 3,
			company_id: "",
			club_id: club_id,
			email: email,
			first_name: first_name,
			last_name: last_name,
		};

		const returnData = await setDoc(docRef, userInfo)
			.then(() => {
				return Promise.resolve(userInfo);
			})
			.catch((error) => {
				return Promise.reject("Kunde inte skapa ny användare");
			});

		return returnData;
	};

	const setUserCompanyID = async (
		uid: string,
		newCompanyID: string
	): Promise<void> => {
		const docRef = doc(db, Collections.USERS, uid);
		const updateInfo = {
			company_id: newCompanyID,
		};

		const returnData = await updateDoc(docRef, updateInfo)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject("Kunde inte gå till företagssida");
			});

		return returnData;
	};
	// Update method currently only used for requesting data from existing users

	const removeCompany = async (companyID: string): Promise<void> => {
		// Get company doc and keep data in variable
		const companyDocRef = doc(db, Collections.COMPANIES, companyID);
		const companyDocSnap = await getDoc(companyDocRef);

		var companyData: Company;
		if (companyDocSnap.exists()) {
			companyData = companyDocSnap.data() as Company;
		} else {
			return Promise.reject("Kunde inte hitta sponsor som ska tas bort");
		}

		// Find users connected to company (not including any superadmins who may have company's companyID)
		const queryUsers = query(
			collection(db, Collections.USERS),
			where("company_id", "==", companyID)
		);
		const queryUsersSnapshot = await getDocs(queryUsers);
		const usersToRemove: string[] = [];
		queryUsersSnapshot.forEach((doc) => {
			const user = doc.data() as DBUser;
			if (user.user_type === 1) {
				usersToRemove.push(doc.id);
			}
		});

		// Find ads published by the company
		const queryAds = query(
			collection(db, Collections.ADS),
			where("company_id", "==", companyID)
		);
		const queryAdsSnapshot = await getDocs(queryAds);
		const adsToRemove: string[] = [];
		queryAdsSnapshot.forEach((doc) => {
			adsToRemove.push(doc.id);
		});

		// Find clubs related to the company and remove their connection
		const queryClubs = query(
			collection(db, Collections.CLUBS),
			where(
				"organisationNumbers",
				"array-contains",
				companyData.organisationNumber
			)
		);
		const queryClubsSnapshot = await getDocs(queryClubs);
		for (var i = 0; i < queryClubsSnapshot.size; i++) {
			await updateDoc(queryClubsSnapshot.docs[i].ref, {
				organisationNumbers: arrayRemove(
					companyData.organisationNumber
				),
			});

			// Remove connection in the field sponsor_groups
			const clubData = queryClubsSnapshot.docs[i].data() as Club;

			// If the variable sponsor_groups doesnt exist, do not perform rest of the code
			if (!clubData.sponsor_groups) {
				continue;
			}

			const sponsorGroups = clubData.sponsor_groups;
			if (sponsorGroups) {
				for (var ii = 0; ii < sponsorGroups.length; ii++) {
					// Skip if the organisation number cant be found in the sponsor group
					if (
						!sponsorGroups[ii].organisationNumbers.includes(
							companyData.organisationNumber
						)
					) {
						continue;
					}

					// if there is only one organisation number in the sponsor group left, remove the whole sponsor group
					if (sponsorGroups[ii].organisationNumbers.length === 1) {
						await updateDoc(queryClubsSnapshot.docs[i].ref, {
							sponsor_groups: arrayRemove(sponsorGroups[ii]),
						});

						continue;
					}

					// remove the organisation number from the sponsor group
					const index = sponsorGroups[ii].organisationNumbers.indexOf(
						companyData.organisationNumber
					);
					if (index > -1) {
						sponsorGroups[ii].organisationNumbers.splice(index, 1); // Remove organisation number from array
					}

					// replace everything in sponsor groups (this was the easiest method to remove the organisation number )
					await updateDoc(queryClubsSnapshot.docs[i].ref, {
						sponsor_groups: sponsorGroups,
					});
				}
			}
		}

		// Delete all docs and their belonging images from storage
		for (var i = 0; i < adsToRemove.length; i++) {
			const docRef = doc(db, "ads", adsToRemove[i]);
			const adDocSnap = await getDoc(docRef);
			await deleteDoc(doc(db, Collections.ADS, adsToRemove[i]));
			var adData: Ad;
			if (adDocSnap.exists()) {
				adData = adDocSnap.data() as Ad;
				deleteImageFromStorage(adData.image_url);
			}
		}

		for (var i = 0; i < usersToRemove.length; i++) {
			await deleteDoc(doc(db, Collections.USERS, usersToRemove[i]));
		}

		await deleteDoc(companyDocRef);

		await deleteImageFromStorage(companyData.image_url);

		return Promise.resolve();
	};

	const removeClub = async (clubID: string): Promise<void> => {
		// Get club doc and keep data in variable
		const clubDocRef = doc(db, Collections.CLUBS, clubID);
		const clubDocSnap = await getDoc(clubDocRef);

		var clubData: Club;
		if (clubDocSnap.exists()) {
			clubData = clubDocSnap.data() as Club;
		} else {
			return Promise.reject("Kunde inte hitta förening som ska tas bort");
		}

		// Find users connected to club (not including any superadmins)
		const queryUsers = query(
			collection(db, Collections.USERS),
			where("club_id", "==", clubID)
		);
		const queryUsersSnapshot = await getDocs(queryUsers);
		const usersToRemove: string[] = [];
		queryUsersSnapshot.forEach((doc) => {
			const user = doc.data() as DBUser;
			if (user.user_type === 3) {
				usersToRemove.push(doc.id);
			}
		});

		// Find ads related to club
		const queryAds = query(
			collection(db, Collections.ADS),
			where("club_ids", "array-contains", clubID)
		);

		const queryAdsSnapshot = await getDocs(queryAds);
		const adsToRemove: string[] = [];
		for (var i = 0; i < queryAdsSnapshot.size; i++) {
			const ad = queryAdsSnapshot.docs[i].data() as Ad;
			if (ad.club_ids.length === 1)
				adsToRemove.push(queryAdsSnapshot.docs[i].id); // Remove all ads that only has a relation with this club

			var clubOfferInstruction;
			ad.clubOfferInstructions.forEach((instr) => {
				if (instr.club_id === clubID) clubOfferInstruction = instr;
			});

			await updateDoc(queryAdsSnapshot.docs[i].ref, {
				club_ids: arrayRemove(clubID),
				clubOfferInstructions: arrayRemove(clubOfferInstruction),
			});
		}

		// Find clubs related to the company and remove their connection
		const queryCompanies = query(
			collection(db, Collections.COMPANIES),
			where("relatedClubs", "array-contains", clubID)
		);
		const queryCompaniesSnapshot = await getDocs(queryCompanies);
		for (var i = 0; i < queryCompaniesSnapshot.size; i++) {
			await updateDoc(queryCompaniesSnapshot.docs[i].ref, {
				relatedClubs: arrayRemove(clubID),
			});
		}

		// Delete all docs and their belonging from storage
		for (var i = 0; i < adsToRemove.length; i++) {
			const docRef = doc(db, "ads", adsToRemove[i]);
			const adDocSnap = await getDoc(docRef);
			await deleteDoc(doc(db, Collections.ADS, adsToRemove[i]));
			var adData: Ad;
			if (adDocSnap.exists()) {
				adData = adDocSnap.data() as Ad;
				deleteImageFromStorage(adData.image_url);
			}
		}

		for (var i = 0; i < usersToRemove.length; i++) {
			await deleteDoc(doc(db, Collections.USERS, usersToRemove[i]));
		}

		await deleteDoc(clubDocRef);

		await deleteImageFromStorage(clubData.image_url);
		if (clubData.background_url) {
			await deleteImageFromStorage(clubData.background_url);
		}
		if (clubData.partner_background_url) {
			await deleteImageFromStorage(clubData.partner_background_url);
		}

		return Promise.resolve();
	};

	const getAdFromID = async (ad_id: string): Promise<Ad> => {
		const docRef = doc(db, Collections.ADS, ad_id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const ad: Ad = docSnap.data() as Ad;
			ad.id = ad_id;

			if (docSnap.data().expiry_date) {
				ad.expiry_date = docSnap.data().expiry_date.toDate();
			}

			return Promise.resolve(ad);
		}

		return Promise.reject("Kunde inte hitta Erbjudande");
	};
	const updateUser = async (
		uid: string,
		email?: string,
		first_name?: string,
		last_name?: string
	): Promise<void> => {
		var updateFields = {};
		if (email && email.length > 0)
			updateFields = {
				...updateFields,
				email: email,
			};
		if (first_name && first_name.length > 0)
			updateFields = {
				...updateFields,
				first_name: first_name,
			};
		if (last_name && last_name.length > 0)
			updateFields = {
				...updateFields,
				last_name: last_name,
			};
		const docRef = doc(db, Collections.USERS, uid);

		const returnData = await updateDoc(docRef, updateFields)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject("Kunde inte uppdatera användaren");
			});

		return returnData;
	};

	const updateAd = async (
		ad_id: string,
		company_name: string,
		category?: string,
		club_ids?: string[],
		club_names?: string[],
		description?: string,
		image?: File,
		old_image_url?: string,
		whiteLogo?: boolean,
		clubOfferInstructions?: ClubOfferInstruction[],
		title?: string,
		contactPerson?: string,
		phoneNumber?: string,
		email?: string,
		expiry_date?: Date | null
	): Promise<string> => {
		var updateFields = {}; // Fields to be updated

		// Upload image
		if (image && old_image_url) {
			var image_url = "";
			var imageUploadError = "";

			var imageDeleteError = "ERR";
			await deleteImageFromStorage(old_image_url).catch((error) => {
				imageDeleteError = error;
			});

			if (imageDeleteError)
				return Promise.reject("Kunde inte uppdatera bild, prova igen");

			await uploadImageToStorageAndGetImageURL(
				image,
				StorageLocations.AD_LOGOS
			)
				.then((resp) => {
					image_url = resp;
					updateFields = {
						...updateFields,
						image_url: resp,
					};
				})
				.catch((error) => {
					imageUploadError = error;
				});
			if (imageUploadError !== "") {
				return Promise.reject(imageUploadError);
			}
		}

		if (category)
			updateFields = {
				...updateFields,
				category: category,
			};

		if (club_ids && club_ids.length > 0) {
			// Find clubs' regions
			const clubRegions: string[] = [];
			const foundClubs = await getClubs(club_ids)
				.then((clubs) => {
					clubs.forEach((club) => {
						clubRegions.push(club.region);
					});
					return true;
				})
				.catch(() => {
					return false;
				});

			if (!foundClubs) {
				return Promise.reject(
					"Kunde inte hitta några föreningar kopplade till erbjudandet"
				);
			}
			updateFields = {
				...updateFields,
				regions: clubRegions,
				club_ids: club_ids,
			};
		}
		if (whiteLogo !== undefined)
			updateFields = {
				...updateFields,
				whiteLogo: whiteLogo,
			};

		if (description)
			updateFields = {
				...updateFields,
				description: description,
			};

		if (title)
			updateFields = {
				...updateFields,
				title: title,
			};

		if (clubOfferInstructions)
			updateFields = {
				...updateFields,
				clubOfferInstructions: clubOfferInstructions,
			};
		if (club_names)
			updateFields = {
				...updateFields,
				club_names: club_names,
			};

		if (contactPerson)
			updateFields = {
				...updateFields,
				contactPerson: contactPerson,
			};

		if (phoneNumber)
			updateFields = {
				...updateFields,
				phoneNumber: phoneNumber,
			};

		if (email)
			updateFields = {
				...updateFields,
				email: email,
			};
		if (company_name)
			updateFields = {
				...updateFields,
				company_name: company_name,
			};
		if (expiry_date) {
			updateFields = {
				...updateFields,
				expiry_date: Timestamp.fromDate(new Date(expiry_date)),
			};
		} else if (expiry_date === null) {
			updateFields = {
				...updateFields,
				expiry_date: null,
			};
		}
		const docRef = doc(db, Collections.ADS, ad_id);

		const returnData = await updateDoc(docRef, updateFields)
			.then(() => {
				return Promise.resolve(image_url);
			})
			.catch(() => {
				return Promise.reject("Kunde inte uppdatera erbjudande");
			});

		return returnData;
	};
	const uploadClubDeal = async (
		clubId: string,
		deal: SponsorDealInfo,
		pdf: File
	): Promise<void> => {
		var newDeal = {};
		var dealUpload = {};
		const docRef = doc(db, Collections.CLUBS, clubId);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const deals = docSnap.data().sponsorDeals;
			newDeal = { ...newDeal, sponsorDeals: [deal] };
			dealUpload = { ...deal };
			var fileUploadResp: UploadResult | undefined = undefined;
			var file_url = "";
			if (pdf) {
				const fileRef = ref(
					storage,
					StorageLocations.CLUB_FILES + pdf.name
				);
				await uploadBytes(fileRef, pdf).then((resp) => {
					fileUploadResp = resp;
				});
				if (!fileUploadResp) {
					return Promise.reject(
						"Kunde inte ladda upp fil, prova igen"
					);
				}
				const tmpFileUpload = fileUploadResp as UploadResult;
				await getDownloadURL(tmpFileUpload.ref).then((downloadURL) => {
					file_url = downloadURL;
				});

				dealUpload = { ...deal, downloadURL: file_url };
				newDeal = { ...newDeal, sponsorDeals: [dealUpload] };
			}
			if (deals && deals.length > 0) {
				await updateDoc(docRef, {
					sponsorDeals: arrayUnion(dealUpload),
				}).catch(() => {
					return Promise.reject("Kunde inte ladda upp deal");
				});
			} else {
				const returnData = await updateDoc(docRef, newDeal)
					.then(() => {
						return Promise.resolve();
					})
					.catch(() => {
						return Promise.reject("Kunde inte ladda upp deal");
					});
				return returnData;
			}
		}
	};
	const getDealByCompanyId = async (
		club_id: string,
		company_id: string
	): Promise<SponsorDealInfo | undefined> => {
		const docRef = doc(db, Collections.CLUBS, club_id);

		const docSnap = await getDoc(docRef);

		if (!docSnap.exists()) {
			return undefined;
		}

		if (docSnap.data().sponsorDeals) {
			const matchingDeal: SponsorDealInfo[] = docSnap
				.data()
				.sponsorDeals.filter(
					(deal: SponsorDealInfo) => deal.companyId === company_id
				);

			if (matchingDeal && matchingDeal.length > 0) {
				const returnDeal = matchingDeal[0] as SponsorDealInfo;
				return returnDeal;
			} else {
				return undefined;
			}
		} else {
			return;
		}
	};

	const updateClubDeal = async (
		clubId: string,
		deal: SponsorDealInfo,
		pdf: File
	): Promise<void> => {
		const { companyId, price, expiry_date, clubObligations, other } = deal;
		const docRef = doc(db, Collections.CLUBS, clubId);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return;
		}
		if (docSnap.data().sponsorDeals) {
			const existingDeals = docSnap.data().sponsorDeals;
			const sponsorDeals = await Promise.all(
				existingDeals.map(async (deal: SponsorDealInfo) => {
					if (deal.companyId === companyId) {
						if (pdf) {
							if (deal.downloadURL) {
								const existingFileRef = ref(
									storage,
									deal.downloadURL
								);
								await deleteObject(existingFileRef);
							}
							var fileUploadResp: UploadResult | undefined =
								undefined;
							var file_url = "";
							const fileRef = ref(
								storage,
								StorageLocations.CLUB_FILES + pdf.name
							);
							await uploadBytes(fileRef, pdf).then((resp) => {
								fileUploadResp = resp;
							});
							if (!fileUploadResp) {
								return Promise.reject(
									"Kunde inte ladda upp fil, prova igen"
								);
							}
							const tmpFileUpload =
								fileUploadResp as UploadResult;
							await getDownloadURL(tmpFileUpload.ref).then(
								(downloadURL) => {
									file_url = downloadURL;
								}
							);
							return {
								...deal,
								price: deal.price,
								expiry_date: expiry_date,
								clubObligations: clubObligations,
								other: other,
								downloadURL: file_url,
							};
						} else {
							return {
								...deal,
								price: price,
								expiry_date: expiry_date,
								clubObligations: clubObligations,
								other: other,
							};
						}
					} else {
						return deal;
					}
				})
			);
			const updateData = { sponsorDeals };
			const returnData = await updateDoc(docRef, updateData);
			return Promise.resolve(returnData);
		} else {
			return;
		}
	};

	const updateEvent = async (
		event_id: string,
		title: string,
		date: Date,
		street: string,
		area: string,
		description: string,
		invited?: EventInvite[],
		image?: File,
		old_image_url?: string
	): Promise<string> => {
		var updateFields = {}; // Fields to be updated

		// Upload image
		if (image && old_image_url) {
			var image_url = "";
			var imageUploadError = "";

			var imageDeleteError = "";

			await deleteImageFromStorage(old_image_url).catch((error) => {
				imageDeleteError = error;
			});

			if (imageDeleteError)
				return Promise.reject("Kunde inte uppdatera bild, prova igen");

			await uploadImageToStorageAndGetImageURL(
				image,
				StorageLocations.EVENT_LOGOS
			)
				.then((resp) => {
					image_url = resp;
					updateFields = {
						...updateFields,
						image_url: resp,
					};
				})
				.catch((error) => {
					imageUploadError = error;
				});
			if (imageUploadError !== "") {
				return Promise.reject(imageUploadError);
			}
		}

		updateFields = {
			...updateFields,
			title: title,
			date: Timestamp.fromDate(new Date(date)),
			street: street,
			area: area,
			description: description,
		};

		if (invited) {
			const ids: string[] = invited.map((i) => i.sponsor_id);

			updateFields = {
				...updateFields,
				invited: invited,
				sponsor_ids: ids,
			};
		}

		const docRef = doc(db, Collections.EVENTS, event_id);

		const returnData = await updateDoc(docRef, updateFields)
			.then(() => {
				return Promise.resolve(image_url);
			})
			.catch(() => {
				return Promise.reject("Kunde inte uppdatera event");
			});

		return returnData;
	};

	const updateClub = async (
		club_id: string,
		clubName?: string,
		description?: string,
		region?: string,
		image?: File,
		old_image_url?: string,
		organisationNumbers?: string[],
		oldOrganisationNumbers?: string[],
		member_url?: string,
		sponsor_url?: string,
		backgroundImage?: File | string,
		partnerBackgroundImage?: File | string,
		old_background_url?: string,
		old_partner_background_url?: string,
		background_color?: string,
		sponsor_groups?: SponsorGroup[],
		email?: string,
		hideLogo?: boolean
	): Promise<void> => {
		var updateFields = {}; // Fields to be updated
		// Upload image
		if (image) {
			var imageUploadError = "";
			await uploadImageToStorageAndGetImageURL(
				image,
				StorageLocations.CLUB_LOGOS
			)
				.then((resp) => {
					updateFields = {
						...updateFields,
						image_url: resp,
					};
				})
				.catch((error) => {
					imageUploadError = error;
				});
			if (imageUploadError !== "") {
				return Promise.reject(imageUploadError);
			}

			if (old_image_url && !imageUploadError) {
				var imageDeleteError = "";
				await deleteImageFromStorage(old_image_url).catch((error) => {
					imageDeleteError = error;
				});

				if (imageDeleteError)
					return Promise.reject(
						"Kunde inte uppdatera bild, prova igen"
					);
			}
		}
		//upload background image
		if (backgroundImage) {
			var backgroundUploadError = "";
			if (backgroundImage instanceof File) {
				await uploadImageToStorageAndGetImageURL(
					backgroundImage,
					StorageLocations.CLUB_BACKGROUNDS
				)
					.then((resp) => {
						updateFields = {
							...updateFields,
							background_url: resp,
						};
					})
					.catch((error) => {
						backgroundUploadError = error;
					});
				if (backgroundUploadError !== "") {
					return Promise.reject(backgroundUploadError);
				}
			} else if (backgroundImage === "NONE") {
				updateFields = {
					...updateFields,
					background_url: "",
				};
			}
			if (old_background_url && !backgroundUploadError) {
				var backgroundDeleteError = "";
				await deleteImageFromStorage(old_background_url).catch(
					(error) => {
						backgroundDeleteError = error;
					}
				);

				if (backgroundDeleteError)
					return Promise.reject(
						"Kunde inte uppdatera bild, prova igen"
					);
			}
		}

		//upload partner background image
		if (partnerBackgroundImage) {
			var partnerBackgroundUploadError = "";
			if (partnerBackgroundImage instanceof File) {
				await uploadImageToStorageAndGetImageURL(
					partnerBackgroundImage,
					StorageLocations.CLUB_BACKGROUNDS
				)
					.then((resp) => {
						updateFields = {
							...updateFields,
							partner_background_url: resp,
						};
					})
					.catch((error) => {
						partnerBackgroundUploadError = error;
					});
				if (partnerBackgroundUploadError !== "") {
					return Promise.reject(partnerBackgroundUploadError);
				}
			} else if (partnerBackgroundImage === "NONE") {
				updateFields = {
					...updateFields,
					partner_background_url: "",
				};
			}
			if (old_partner_background_url && !partnerBackgroundUploadError) {
				var partnerBackgroundDeleteError = "";
				await deleteImageFromStorage(old_partner_background_url).catch(
					(error) => {
						partnerBackgroundDeleteError = error;
					}
				);

				if (partnerBackgroundDeleteError)
					return Promise.reject(
						"Kunde inte uppdatera bild, prova igen"
					);
			}
		}

		if (clubName)
			updateFields = {
				...updateFields,
				name: clubName,
			};

		if (region)
			updateFields = {
				...updateFields,
				region: region,
			};

		if (description || description === "")
			updateFields = {
				...updateFields,
				description: description,
			};
		if (hideLogo !== undefined)
			updateFields = {
				...updateFields,
				hideLogo: hideLogo,
			};
		if (member_url || member_url === "")
			updateFields = {
				...updateFields,
				member_url: member_url,
			};
		if (sponsor_url || sponsor_url === "")
			updateFields = {
				...updateFields,
				sponsor_url: sponsor_url,
			};
		if (background_color || background_color === "")
			updateFields = {
				...updateFields,
				background_color: background_color,
			};
		if (email) {
			const actionCodeSettings = {
				url: url + Pages.SIGNUP_PAGE + "?club_id=" + club_id,
				handleCodeInApp: true,
			};

			var sentEmail = false;
			await sendSignInLinkToEmail(
				getAuth(),
				email,
				actionCodeSettings
			).then(() => {
				sentEmail = true;
			});

			if (!sentEmail) {
				return Promise.reject(
					"Kunde inte skicka ut nytt inloggningsmail"
				);
			}
		}

		if (
			organisationNumbers !== undefined &&
			oldOrganisationNumbers !== undefined
		) {
			// Find new org numbers
			var newOrgNumbers: string[] = [];
			organisationNumbers.forEach((orgNumber) => {
				if (!oldOrganisationNumbers.includes(orgNumber)) {
					newOrgNumbers.push(orgNumber);
				}
			});

			// Add new clubs to related clubs in company
			if (newOrgNumbers.length > 0) {
				const q = query(
					collection(db, Collections.COMPANIES),
					where("organisationNumber", "in", newOrgNumbers)
				);
				const querySnapshot = await getDocs(q);
				if (!querySnapshot.empty) {
					for (var i = 0; i < querySnapshot.size; i++) {
						await updateDoc(querySnapshot.docs[i].ref, {
							relatedClubs: arrayUnion(club_id),
						});
					}
				}
			}

			// Find deleted org numbers
			var deletedOrgNumbers: string[] = [];
			oldOrganisationNumbers.forEach((oldOrgNumber) => {
				if (!organisationNumbers.includes(oldOrgNumber)) {
					deletedOrgNumbers.push(oldOrgNumber);
				}
			});

			// Delete old clubs in related clubs in company
			if (deletedOrgNumbers.length > 0) {
				const q2 = query(
					collection(db, Collections.COMPANIES),
					where("organisationNumber", "in", deletedOrgNumbers)
				);
				const querySnapshot2 = await getDocs(q2);
				if (!querySnapshot2.empty) {
					for (var i = 0; i < querySnapshot2.size; i++) {
						await updateDoc(querySnapshot2.docs[i].ref, {
							relatedClubs: arrayRemove(club_id),
						});
					}
				}
			}

			updateFields = {
				...updateFields,
				organisationNumbers: organisationNumbers,
			};

			// only change sponsor_groups if organisationNumbers change
			if (sponsor_groups) {
				updateFields = {
					...updateFields,
					sponsor_groups: sponsor_groups,
				};
			}
		}

		const docRef = doc(db, Collections.CLUBS, club_id);

		const returnData = await updateDoc(docRef, updateFields)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject(
					"Kunde inte uppdatera föreningens uppgifter, prova igen"
				);
			});

		return returnData;
	};

	const updateCompany = async (
		company_id: string,
		companyName?: string,
		description?: string,
		image?: File,
		old_image_url?: string,
		isOnFrontPage?: boolean,
		whiteLogo?: boolean,
		email?: string,
		categories?: string[],
		address?: string,
		phoneNumber?: string,
		websiteURL?: string,
		contactName?: string,
		contactEmail?: string,
		contactPhone?: string,
		newAdmin?: boolean
	): Promise<void> => {
		var updateFields = {}; // Fields to be updated

		// Upload image
		if (image) {
			var imageUploadError = "";
			await uploadImageToStorageAndGetImageURL(
				image,
				StorageLocations.CLUB_LOGOS
			)
				.then((resp) => {
					updateFields = {
						...updateFields,
						image_url: resp,
					};
				})
				.catch((error) => {
					imageUploadError = error;
				});
			if (imageUploadError !== "") {
				return Promise.reject(imageUploadError);
			}

			if (old_image_url && !imageUploadError) {
				var imageDeleteError = "";
				await deleteImageFromStorage(old_image_url).catch((error) => {
					imageDeleteError = error;
				});

				if (imageDeleteError)
					return Promise.reject(
						"Kunde inte uppdatera bild, prova igen"
					);
			}
		}

		if (companyName)
			updateFields = {
				...updateFields,
				name: companyName,
			};

		if (description !== undefined)
			updateFields = {
				...updateFields,
				description: description,
			};
		if (categories)
			updateFields = {
				...updateFields,
				categories: categories,
			};

		if (isOnFrontPage !== undefined)
			updateFields = {
				...updateFields,
				isOnFrontPage: isOnFrontPage,
			};
		if (whiteLogo !== undefined)
			updateFields = {
				...updateFields,
				whiteLogo: whiteLogo,
			};

		if (address !== undefined)
			updateFields = {
				...updateFields,
				address: address,
			};

		if (phoneNumber !== undefined)
			updateFields = {
				...updateFields,
				phoneNumber: phoneNumber,
			};

		if (websiteURL !== undefined)
			updateFields = {
				...updateFields,
				website_url: websiteURL,
			};
		if (contactName !== undefined)
			updateFields = {
				...updateFields,
				contactName: contactName,
			};
		if (contactEmail !== undefined)
			updateFields = {
				...updateFields,
				contactEmail: contactEmail,
			};
		if (contactPhone !== undefined)
			updateFields = {
				...updateFields,
				contactPhone: contactPhone,
			};

		if (email) {
			const actionCodeSettings = {
				url:
					url +
					Pages.SIGNUP_PAGE +
					"?company_id=" +
					company_id +
					(newAdmin ? "&is_admin=true" : ""),
				handleCodeInApp: true,
			};

			var sentEmail = false;
			await sendSignInLinkToEmail(
				getAuth(),
				email,
				actionCodeSettings
			).then(() => {
				sentEmail = true;
			});

			if (!sentEmail) {
				return Promise.reject(
					"Kunde inte skicka ut nytt inloggningsmail"
				);
			}
		}

		const docRef = doc(db, Collections.COMPANIES, company_id);

		const returnData = await updateDoc(docRef, updateFields)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject(
					"Kunde inte uppdatera företagets uppgifter, prova igen"
				);
			});

		return returnData;
	};

	/**
	 * Get companies that are shown only on the front page
	 */
	const getFrontPageCompanies = async (): Promise<Company[]> => {
		const q = query(
			collection(db, Collections.COMPANIES),
			where("isOnFrontPage", "==", true)
		);
		const querySnapshot = await getDocs(q);
		if (querySnapshot.empty) {
			Promise.reject("Kunde inte hitta sponsor");
		}
		const companies: Company[] = [];
		querySnapshot.forEach((doc) => {
			const company: Company = doc.data() as Company;
			company.id = doc.id;
			companies.push(company);
		});

		return Promise.resolve(companies);
	};

	/**
	 * Get ads created by a specific company
	 * Call this again to get next pagination
	 * First time calling does not need the latestDoc parameter
	 * Include optional parameters category and region to sort for more specific ads
	 */
	const getAdsFromCompanyPaginated = async (
		company_id: string,
		latestDoc?: QueryDocumentSnapshot<DocumentData>,
		category?: string,
		region?: string,
		getExpired?: boolean
	): Promise<AdPagination> => {
		// Setup query constraints
		const queryConstraints: QueryConstraint[] = [];
		queryConstraints.push(where("company_id", "==", company_id));
		if (category) queryConstraints.push(where("category", "==", category));
		if (region)
			queryConstraints.push(where("regions", "array-contains", region));
		const q = query(
			collection(db, Collections.ADS),
			...queryConstraints,
			limit(PAGINATION_LIMIT),
			orderBy("title"),
			startAfter(latestDoc || 0)
		);

		const querySnapshot = await getDocs(q);
		if (querySnapshot.empty) {
			return Promise.reject("Kunde inte hitta fler Erbjudanden");
		}
		const ads: Ad[] = [];
		querySnapshot.forEach((doc) => {
			var expiryDate = undefined;
			if (doc.data().expiry_date) {
				expiryDate = doc.data().expiry_date.toDate();
			}
			var todaysDate = new Date();
			todaysDate.setHours(0, 0, 0, 0);

			if (
				!getExpired &&
				expiryDate &&
				expiryDate.getTime() < todaysDate.getTime()
			) {
				return;
			} else {
				const ad: Ad = doc.data() as Ad;
				ad.id = doc.id;
				if (expiryDate) {
					ad.expiry_date = expiryDate;
				}
				ads.push(ad);
			}
		});

		const tmpLatestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
		const returnData: AdPagination = {
			ads: ads,
			latestDoc: tmpLatestDoc,
		};

		return Promise.resolve(returnData);
	};

	/**
	 * Get ads created by a specific company
	 * Call this again to get next pagination
	 * First time calling does not need the latestDoc parameter
	 * * Include optional parameter category to sort for more specific ads
	 */
	const getAdsFromClubPaginated = async (
		club_id: string,
		latestDoc?: QueryDocumentSnapshot<DocumentData>,
		category?: string
	): Promise<AdPagination> => {
		// Setup query constraints
		const queryConstraints: QueryConstraint[] = [];
		queryConstraints.push(where("club_ids", "array-contains", club_id));

		if (category) queryConstraints.push(where("category", "==", category));
		const q = query(
			collection(db, Collections.ADS),
			...queryConstraints,
			limit(PAGINATION_LIMIT),
			orderBy("title"),
			startAfter(latestDoc || 0)
		);

		const querySnapshot = await getDocs(q);
		if (querySnapshot.empty) {
			return Promise.reject("Kunde inte hitta fler Erbjudanden");
		}
		const ads: Ad[] = [];
		querySnapshot.forEach((doc) => {
			var expiryDate = undefined;

			if (doc.data().expiry_date) {
				expiryDate = doc.data().expiry_date.toDate();
			}

			var todaysDate = new Date();

			todaysDate.setHours(0, 0, 0, 0);

			if (expiryDate && expiryDate.getTime() < todaysDate.getTime()) {
				return;
			} else {
				const ad: Ad = doc.data() as Ad;
				ad.id = doc.id;
				if (expiryDate) {
					ad.expiry_date = expiryDate;
				}

				ads.push(ad);
			}
		});

		const tmpLatestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
		const returnData: AdPagination = {
			ads: ads,
			latestDoc: tmpLatestDoc,
		};

		return Promise.resolve(returnData);
	};

	// Get an event with the specific id
	const getEventFromID = async (event_id: string): Promise<Event> => {
		const docRef = doc(db, Collections.EVENTS, event_id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const event: Event = docSnap.data() as Event;
			event.id = event_id;

			if (docSnap.data().date) {
				event.date = docSnap.data().date.toDate();
			}
			return Promise.resolve(event);
		}

		return Promise.reject("Kunde inte hitta Event");
	};

	/**
	 * Get events created by a specific club
	 */
	const getEventsFromClub = async (club_id: string): Promise<Event[]> => {
		// Setup query constraints
		const eventsRef = collection(db, Collections.EVENTS);

		const q = query(eventsRef, where("club_id", "==", club_id));

		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hitta några event");
		}

		const events: Event[] = [];
		docsSnap.forEach((doc) => {
			const event = doc.data() as Event;
			event.date = doc.data().date.toDate();
			event.id = doc.id;
			events.push(event);
		});

		return Promise.resolve(events);
	};
	const getAdsFromCompany = async (company_id: string): Promise<Ad[]> => {
		// Setup query constraints
		const eventsRef = collection(db, Collections.ADS);

		const q = query(eventsRef, where("company_id", "==", company_id));

		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hitta några event");
		}

		const ads: Ad[] = [];
		docsSnap.forEach((doc) => {
			const ad = doc.data() as Ad;
			ad.id = doc.id;
			ads.push(ad);
		});

		return Promise.resolve(ads);
	};

	/**
	 * Get events to which a company is invited
	 */
	const getEventsForCompany = async (
		company_id: string
	): Promise<Event[]> => {
		// Setup query constraints
		const eventsRef = collection(db, Collections.EVENTS);

		const q = query(
			eventsRef,
			where("sponsor_ids", "array-contains", company_id)
		);

		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hitta några event");
		}

		const events: Event[] = [];
		docsSnap.forEach((doc) => {
			const event = doc.data() as Event;
			event.date = doc.data().date.toDate();
			event.id = doc.id;
			events.push(event);
		});

		return Promise.resolve(events);
	};

	/**
	 * Get clubs from array of club ID:s
	 */
	const getClubs = async (club_ids: string[]): Promise<Club[]> => {
		const clubs: Club[] = [];
		for (var i = 0; i < club_ids.length; i++) {
			const docRef = doc(db, Collections.CLUBS, club_ids[i]);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const club: Club = docSnap.data() as Club;
				club.id = docSnap.id;
				clubs.push(club);
			}
		}

		if (clubs.length === 0) {
			if (club_ids.length === 1) {
				return Promise.reject("Kunde inte hitta förening");
			} else {
				return Promise.reject("Kunde inte hitta föreningar");
			}
		}

		return Promise.resolve(clubs);
	};

	/**
	 * Get companies from array of company ID:s
	 */
	const getCompanies = async (company_ids: string[]): Promise<Company[]> => {
		const companies: Company[] = [];
		for (var i = 0; i < company_ids.length; i++) {
			const docRef = doc(db, Collections.COMPANIES, company_ids[i]);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const company: Company = docSnap.data() as Company;
				company.id = docSnap.id;
				companies.push(company);
			}
		}

		if (companies.length === 0) {
			if (company_ids.length === 1) {
				return Promise.reject("Kunde inte hitta sponsor");
			} else {
				return Promise.reject("Kunde inte hitta sponsorer");
			}
		}

		return Promise.resolve(companies);
	};

	const getCompaniesByOrganisationNumber = async (
		organisationNumbers: string[]
	): Promise<Company[]> => {
		const companies: Company[] = [];
		// We need to take 10 organisation numbers at a time, since the operator IN is limited to 10 elements in firebase
		for (var i = 0; i < organisationNumbers.length; i += 10) {
			var limit = i + 9;
			if (i + 10 > organisationNumbers.length - 1) {
				limit = organisationNumbers.length - 1;
			}
			var orgNumbersToCheck = organisationNumbers.splice(i, limit);
			const q = query(
				collection(db, Collections.COMPANIES),
				where("organisationNumber", "in", orgNumbersToCheck)
			);

			const querySnapshot = await getDocs(q);

			if (querySnapshot.empty) {
				Promise.reject("Kunde inte hitta sponsorer");
			}
			const companies: Company[] = [];
			querySnapshot.forEach((doc) => {
				const company: Company = doc.data() as Company;
				company.id = doc.id;
				companies.push(company);
			});
		}

		if (companies.length === 0) {
			if (organisationNumbers.length === 1) {
				return Promise.reject("Kunde inte hitta sponsor");
			} else {
				return Promise.reject("Kunde inte hitta sponsorer");
			}
		}

		return Promise.resolve(companies);
	};

	const getCompaniesFromClub = async (
		club_id: string,
		category?: string
	): Promise<Company[]> => {
		const q = query(
			collection(db, Collections.COMPANIES),
			where("relatedClubs", "array-contains", club_id),
			orderBy("name", "asc")
		);
		const querySnapshot = await getDocs(q);

		if (querySnapshot.empty) {
			Promise.reject("Kunde inte hitta sponsor");
		}
		const companies: Company[] = [];
		querySnapshot.forEach((doc) => {
			const company: Company = doc.data() as Company;
			if (category) {
				if (company.categories?.includes(category)) {
					company.id = doc.id;
					companies.push(company);
				}
			} else {
				company.id = doc.id;
				companies.push(company);
			}
		});

		if (companies.length === 0)
			return Promise.reject(
				"Kunde inte hitta några sponsorer med kategorin " + category
			);

		return Promise.resolve(companies);
	};

	/**
	 * Get all companies, used in SUPERADMIN
	 */
	const getAllCompanies = async (): Promise<Company[]> => {
		const q = query(
			collection(db, Collections.COMPANIES),
			orderBy("name", "asc")
		);
		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hämta sponsorer");
		}

		const companies: Company[] = [];
		docsSnap.forEach((doc) => {
			const company = doc.data() as Company;
			company.id = doc.id;
			companies.push(company);
		});

		return Promise.resolve(companies);
	};

	/**
	 * Get all clubs, used in SUPERADMIN
	 */
	const getAllClubs = async (): Promise<Club[]> => {
		const q = query(
			collection(db, Collections.CLUBS),
			orderBy("name", "asc")
		);
		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hämta föreningar");
		}

		const clubs: Club[] = [];
		docsSnap.forEach((doc) => {
			const club = doc.data() as Club;
			club.id = doc.id;
			clubs.push(club);
		});

		return Promise.resolve(clubs);
	};

	const getUsersFromCompany = async (
		company_id: string
	): Promise<DBUser[]> => {
		const usersRef = collection(db, Collections.USERS);

		const q = query(
			usersRef,
			where("company_id", "==", company_id),
			where("user_type", "==", 1)
		);

		const docsSnap = await getDocs(q);

		if (docsSnap.empty) {
			return Promise.reject("Kunde inte hämta användare");
		}

		const users: DBUser[] = [];
		docsSnap.forEach((doc) => {
			const user = doc.data() as DBUser;
			user.id = doc.id;
			users.push(user);
		});

		return Promise.resolve(users);
	};

	const getNotifications = async (uid: string): Promise<Notification[]> => {
		const q = query(
			collection(db, Collections.NOTIFICATIONS),
			where("user", "==", uid)
		);

		const notifications: Notification[] = [];
		await getDocs(q).then((resp) => {
			if (!resp.empty) {
				resp.forEach((document) => {
					notifications.push(
						...[...document.data().user_notifications]
					);
				});
			}
		});
		return Promise.resolve(notifications);
	};

	/**
	 * Uploads an ad to Firestore and an image to Firebase
	 * Image is stored as a URL in Firestore
	 */
	const uploadAd = async (adInfo: PostAd): Promise<void> => {
		if (adInfo.club_ids.length === 0) {
			return Promise.reject("Välj föreningar");
		}

		// Find clubs' regions
		const clubRegions: string[] = [];
		const foundClubs = await getClubs(adInfo.club_ids)
			.then((clubs) => {
				clubs.forEach((club) => {
					clubRegions.push(club.region);
				});
				return true;
			})
			.catch(() => {
				return false;
			});

		if (!foundClubs) {
			return Promise.reject("Kunde inte hitta några föreningar");
		}

		var image_url = "";
		var imageUploadError = "";
		await uploadImageToStorageAndGetImageURL(
			adInfo.image,
			StorageLocations.AD_LOGOS
		)
			.then((resp) => {
				image_url = resp;
			})
			.catch((error) => {
				imageUploadError = error;
			});
		if (imageUploadError !== "") {
			return Promise.reject(imageUploadError);
		}

		// Upload data to Firestore
		const uploadInfo = getUploadAdToFirebaseType(
			adInfo,
			image_url,
			clubRegions
		);
		const returnData = await addDoc(
			collection(db, Collections.ADS),
			uploadInfo
		)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject("Kunde inte ladda upp erbjudande");
			});

		return returnData;
	};

	const uploadEvent = async (eventInfo: PostEvent): Promise<void> => {
		var image_url = "";
		var imageUploadError = "";
		await uploadImageToStorageAndGetImageURL(
			eventInfo.image,
			StorageLocations.EVENT_LOGOS
		)
			.then((resp) => {
				image_url = resp;
			})
			.catch((error) => {
				imageUploadError = error;
			});
		if (imageUploadError !== "") {
			return Promise.reject(imageUploadError);
		}

		// Upload data to Firestore
		const uploadInfo = getUploadEventToFirebaseType(eventInfo, image_url);
		const returnData = await addDoc(
			collection(db, Collections.EVENTS),
			uploadInfo
		)
			.then(() => {
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject("Kunde inte ladda upp event");
			});

		return returnData;
	};

	/**
	 *
	 */
	const uploadClub = async (club: PostClub): Promise<void> => {
		var image_url = "";
		var imageUploadError = "";
		await uploadImageToStorageAndGetImageURL(
			club.image,
			StorageLocations.CLUB_LOGOS
		)
			.then((resp) => {
				image_url = resp;
			})
			.catch((error) => {
				imageUploadError = error;
			});

		var background_url = "";
		if (club.backgroundImage) {
			await uploadImageToStorageAndGetImageURL(
				club.backgroundImage,
				StorageLocations.CLUB_BACKGROUNDS
			)
				.then((resp) => {
					background_url = resp;
				})
				.catch((error) => {
					imageUploadError = error;
				});
		}

		var partner_background_url = "";
		if (club.partnerBackgroundImage) {
			await uploadImageToStorageAndGetImageURL(
				club.partnerBackgroundImage,
				StorageLocations.CLUB_BACKGROUNDS
			)
				.then((resp) => {
					partner_background_url = resp;
				})
				.catch((error) => {
					imageUploadError = error;
				});
		}

		if (imageUploadError !== "") {
			return Promise.reject(imageUploadError);
		}

		// Upload data to Firestore
		var clubID = "";
		const uploadInfo = getUploadClubToFirebaseType(
			club,
			image_url,
			background_url,
			partner_background_url
		);
		await addDoc(collection(db, Collections.CLUBS), uploadInfo)
			.then(async (uploadedDoc) => {
				await addClubIDToCompanies(uploadedDoc.id, club);
				clubID = uploadedDoc.id;
				return Promise.resolve();
			})
			.catch(() => {
				return Promise.reject("Kunde inte ladda upp erbjudande");
			});
		if (clubID !== "") {
			const actionCodeSettings = {
				url: url + Pages.SIGNUP_PAGE + "?club_id=" + clubID,
				handleCodeInApp: true,
			};

			var sentEmail = false;
			await sendSignInLinkToEmail(
				getAuth(),
				club.email,
				actionCodeSettings
			).then(() => {
				sentEmail = true;
			});

			if (sentEmail) return Promise.resolve();
			else
				return Promise.reject(
					"Skapade företag, men kunde inte skicka inbjudningsmail till användaren. Gå in på företaget och skicka en ny inbjudan."
				);
		} else {
			return Promise.reject("Kunde inte skapa företag");
		}
	};

	// Find companies that matches any of the organisation numbers and add club ID in their array
	const addClubIDToCompanies = async (club_id: string, club: PostClub) => {
		if (club.organisationNumbers.length === 0) return;

		const q = query(
			collection(db, Collections.COMPANIES),
			where("organisationNumber", "in", club.organisationNumbers)
		);
		const companies: Company[] = [];
		await getDocs(q).then((resp) => {
			if (!resp.empty) {
				resp.forEach((document) => {
					const company: Company = document.data() as Company;
					company.id = document.id;
					companies.push(company);
				});
			}
		});

		for (var i = 0; i < companies.length; i++) {
			const docRef = doc(db, Collections.COMPANIES, companies[i].id);
			await updateDoc(docRef, {
				relatedClubs: arrayUnion(club_id),
			});
		}
	};

	const uploadCompany = async (company: PostCompany): Promise<void> => {
		// Upload image and get url
		var image_url = "";
		var imageUploadError = "";
		await uploadImageToStorageAndGetImageURL(
			company.image,
			StorageLocations.COMPANY_LOGOS
		)
			.then((resp) => {
				image_url = resp;
			})
			.catch((error) => {
				imageUploadError = error;
			});
		if (imageUploadError !== "") {
			return Promise.reject(imageUploadError);
		}

		// Find clubs with this company's organisation number
		const relatedClubs: string[] = [];
		const q = query(
			collection(db, Collections.CLUBS),
			where(
				"organisationNumbers",
				"array-contains",
				company.organisationNumber
			)
		);
		await getDocs(q).then((resp) => {
			if (!resp.empty) {
				resp.forEach((document) => {
					relatedClubs.push(document.id);
				});
			}
		});

		// Upload company to firestore
		var companyID = "";
		const uploadInfo = getUploadCompanyToFirebaseType(
			company,
			image_url,
			relatedClubs
		);

		await addDoc(collection(db, Collections.COMPANIES), uploadInfo).then(
			(resp) => {
				companyID = resp.id;
			}
		);

		if (companyID !== "") {
			const actionCodeSettings = {
				url: url + Pages.SIGNUP_PAGE + "?company_id=" + companyID,
				handleCodeInApp: true,
			};

			var sentEmail = false;
			await sendSignInLinkToEmail(
				getAuth(),
				company.email,
				actionCodeSettings
			).then(() => {
				sentEmail = true;
			});

			if (sentEmail) return Promise.resolve();
			else
				return Promise.reject(
					"Skapade företag, men kunde inte skicka inbjudningsmail till användaren. Gå in på företaget och skicka en ny inbjudan."
				);
		} else {
			return Promise.reject("Kunde inte skapa företag");
		}
	};

	/**
	 * Get companies based on a search query
	 * Search is based on name of the company
	 */
	const searchCompany = async (
		searchQuery: string,
		club_id?: string
	): Promise<Company[]> => {
		const lowerCaseSearchQuery = searchQuery.toLowerCase();

		const queryConstraints: QueryConstraint[] = [];
		if (club_id)
			queryConstraints.push(
				where("relatedClubs", "array-contains", club_id)
			);
		const q = query(
			collection(db, Collections.COMPANIES),
			...queryConstraints,
			orderBy("name", "asc")
		);
		const docsSnap = await getDocs(q);
		if (docsSnap.empty) {
			Promise.reject("Kunde inte hitta sponsor");
		}

		const companies: Company[] = [];
		var couldNotReadSnapData = false;
		docsSnap.forEach((doc) => {
			try {
				const company = doc.data() as Company;
				const companyName = company.name.toLowerCase();
				if (companyName.includes(lowerCaseSearchQuery)) {
					company.id = doc.id;
					companies.push(company);
				}
			} catch (e) {
				couldNotReadSnapData = true;
			}
		});

		if (couldNotReadSnapData) {
			return Promise.reject(
				"Något gick fel från vår sida, prova gärna att gå in på sidan senare"
			);
		}

		if (companies.length <= 0)
			return Promise.reject(
				"Kunde inte hitta några sponsorer som matchade söktermen"
			);

		return Promise.resolve(companies);
	};

	/**
	 * Get clubs based on a search query
	 * Search is based on club name, region and sport
	 */
	const searchClub = async (
		searchQuery: string,
		organisationNumber?: string
	): Promise<Club[]> => {
		const lowerCaseSearchQuery = searchQuery.toLowerCase();

		const queryConstraints: QueryConstraint[] = [];
		if (organisationNumber)
			queryConstraints.push(
				where(
					"organisationNumbers",
					"array-contains",
					organisationNumber
				)
			);
		const q = query(
			collection(db, Collections.CLUBS),
			...queryConstraints,
			orderBy("name", "asc")
		);
		const docsSnap = await getDocs(q);
		if (docsSnap.empty) {
			Promise.reject("Kunde inte hitta föreningar");
		}

		const clubs: Club[] = [];

		var couldNotReadSnapData = false;
		docsSnap.forEach((doc) => {
			try {
				const club = doc.data() as Club;
				const clubName = club.name.toLowerCase();
				const clubRegion = club.region.toLowerCase();

				if (
					clubName.includes(lowerCaseSearchQuery) ||
					clubRegion.includes(lowerCaseSearchQuery)
				) {
					club.id = doc.id;
					clubs.push(club);
				}
			} catch (error) {
				couldNotReadSnapData = true;
			}
		});

		if (couldNotReadSnapData) {
			return Promise.reject(
				"Något gick fel från vår sida, prova gärna att gå in på sidan senare"
			);
		}

		if (clubs.length <= 0) {
			return Promise.reject(
				"Kunde inte hitta några föreningar som matchade söktermen"
			);
		}

		return Promise.resolve(clubs);
	};

	const deleteAd = async (ad_id: string): Promise<void> => {
		const docRef = doc(db, "ads", ad_id);
		await getDoc(docRef)
			.then((docSnap) => {
				let image_url: string;
				if (docSnap.exists()) {
					image_url = docSnap.data().image_url;
				}
				const returnData = deleteDoc(docRef).then(() => {
					deleteImageFromStorage(image_url);
					return Promise.resolve();
				});
				return returnData;
			})
			.catch(() => {
				return Promise.reject("Kunde inte ta bort Erbjudande");
			});
	};

	const deleteEvent = async (event_id: string): Promise<void> => {
		const docRef = doc(db, Collections.EVENTS, event_id);
		await getDoc(docRef)
			.then((docSnap) => {
				let image_url: string;
				if (docSnap.exists()) {
					image_url = docSnap.data().image_url;
				}
				const returnData = deleteDoc(docRef).then(() => {
					deleteImageFromStorage(image_url);
					return Promise.resolve();
				});
				return returnData;
			})
			.catch(() => {
				return Promise.reject("Kunde inte ta bort Event");
			});
	};
	const deleteUser = async (user_id: string): Promise<void> => {
		const docRef = doc(db, Collections.USERS, user_id);

		await getDoc(docRef)
			.then((docSnap) => {
				const returnData = deleteDoc(docRef).then(() => {
					return Promise.resolve();
				});
				return returnData;
			})
			.catch((error) => {
				return Promise.reject("Kunde inte ta bort användare");
			});
	};

	return {
		getUser,
		getUsersByCompanyId,
		getUsersByClubId,
		createUserManually,
		createNewUser,
		createNewClubUser,
		setUserCompanyID,
		removeCompany,
		removeClub,
		getFrontPageCompanies,
		getAdFromID,
		getAdsFromCompanyPaginated,
		getEventFromID,
		getEventsFromClub,
		getAdsFromCompany,
		getEventsForCompany,
		getAdsFromClubPaginated,
		getCompaniesFromClub,
		uploadAd,
		uploadEvent,
		uploadClubDeal,
		updateClubDeal,
		getDealByCompanyId,
		updateAd,
		updateEvent,
		updateClub,
		updateCompany,
		updateUser,
		uploadClub,
		uploadCompany,
		searchCompany,
		searchClub,
		getClubs,
		getCompanies,
		getCompaniesByOrganisationNumber,
		getAllClubs,
		getUsersFromCompany,
		getAllCompanies,
		getNotifications,
		deleteAd,
		deleteEvent,
		deleteUser,
	};
};
