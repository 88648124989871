interface Props {
	text: string;
	centerText?: boolean;
}

/**
 * Title for header
 *
 */

export const HeaderTitle = (props: Props) => {
	return (
		<div>
			<h1
				className={
					"text-xl sm:text-3xl sm:leading-normal md:text-4xl md:leading-normal font-bold leading-normal text-white cursor-default" +
					(props.centerText ? " text-center" : "")
				}
			>
				<span className="box-decoration-clone px-4 bg-color-primary-blue">
					{props.text}
				</span>
			</h1>
		</div>
	);
};
