import { useEffect, useState } from "react";
import { StandardButton } from "../components/StandardButton";
import { CustomInputField } from "../components/CustomInputField";
import { CheckBoxDropdown } from "../components/CheckBoxDropdown";
import { CustomDropdownField } from "../components/CustomDropdownField";
import { FileInput } from "../components/FileInput";
import { DeleteDialog } from "../components/DeleteDialog";
import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Ad, ClubOfferInstruction } from "../DBTypes";
import { CATEGORIES } from "../Categories";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import { Timestamp } from "firebase/firestore";
import FormHelperText from "@mui/material/FormHelperText";

interface Club {
	name: string;
	id: string;
}

interface Props {
	editAd?: Ad | undefined;
	selectedTitle: number;
	setSelectedTitle: (v: number) => void;
	pageNumber: number;
	error: boolean;
	setError: (v: boolean) => void;
	titles: string[];
	setImageFile: any;
	whiteLogo: boolean;
	setWhiteLogo: (v: boolean) => void;
	clubs: Club[];
	clubIds: string[] | undefined;
	setClubIds: any;
	setClubNames: any;
	setCategory: any;
	setAdTitle: any;
	setDescription: any;
	setContactName: any;
	setContactPhone: any;
	setContactEmail: any;
	setClubOfferInstructions: any;
	isLoading: boolean;
	handlePublishClick: () => void;
	clubAd: boolean;
	date: Date | null;
	setDate: (date: Date | null) => void;
}

export const AdForm = (props: Props) => {
	/**
	 * Input fields variables
	 */
	const {
		editAd,
		selectedTitle,
		setSelectedTitle,
		pageNumber,
		error,
		setError,
		titles,
		setImageFile,
		clubs,
		clubIds,
		setClubIds,
		setCategory,
		setAdTitle,
		setDescription,
		setContactName,
		setContactPhone,
		setContactEmail,
		setClubOfferInstructions,
		isLoading,
		handlePublishClick,
		date,
		setDate,
	} = props;

	const navigate = useNavigate();

	const [clubNames, setClubNames] = useState<string[]>([]);
	const [selectedClubNames, setSelectedClubNames] = useState<string[]>([]);

	const [submit, setSubmit] = useState<boolean>(false);

	const [expiredDate, setExpiredDate] = useState<boolean>(false);

	let ids: string[] = [];
	let instructions: string[] = [];
	let urls: string[] = [];

	const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);

	/**
	 * Find club names for dropdown with id:s of already selected clubs, since ad db type only has id.
	 * */
	useEffect(() => {
		let names = clubs.map((a) => a.name);
		setClubNames(names);

		if (editAd) {
			const selectedNames = clubs
				.filter((element) => editAd.club_ids.includes(element.id))
				.map((a) => a.name);
			setSelectedClubNames(selectedNames);
		}
	}, [clubs]);

	useEffect(() => {
		const ids = clubs
			.filter((element) => selectedClubNames.includes(element.name))
			.map((a) => a.id);

		setClubIds(ids);
		props.setClubNames(selectedClubNames);
	}, [selectedClubNames]);

	useEffect(() => {
		if (pageNumber === 2) {
			if (clubIds) {
				setSelectedClubs(
					clubs.filter((element) => clubIds.includes(element.id))
				);
			}
		}
	}, [pageNumber]);

	useEffect(() => {
		if (submit) {
			handlePublishClick();
		}
	}, [submit]);

	useEffect(() => {
		if (date) {
			if (isExpired(Timestamp.fromDate(new Date(date)).toDate())) {
				setExpiredDate(true);
			} else {
				setExpiredDate(false);
			}
		} else {
			setExpiredDate(false);
		}
	}, [date]);

	const handleDateChange = (newValue: Date | null) => {
		setDate(newValue);
	};
	const isExpired = (d: Date) => {
		var expiryDate = d;
		var todaysDate = new Date();
		todaysDate.setHours(0, 0, 0, 0);

		if (expiryDate.getTime() < todaysDate.getTime()) {
			return true;
		} else {
			return false;
		}
	};

	const TitleButtons = () => {
		if (editAd) {
			return (
				<div
					className="self-center font-bold text-md sm:text-xl mymd:text-2xl  color-primary-blue"
					style={{ marginBottom: "30px" }}
				>
					{titles[selectedTitle]}
				</div>
			);
		}
		return (
			<div
				className="flex space-x-4 justify-center"
				style={{ marginBottom: "30px" }}
			>
				<button
					className={
						"font-bold text-md sm:text-xl mymd:text-2xl" +
						(selectedTitle === 0
							? " color-primary-blue underline"
							: " color-text-gray")
					}
					onClick={() => {
						setSelectedTitle(0);
						setError(false);
					}}
				>
					{titles[0]}
				</button>

				<button
					className={
						"font-bold text-md sm:text-xl mymd:text-2xl" +
						(selectedTitle === 1
							? " color-primary-blue underline"
							: " color-text-gray")
					}
					onClick={() => {
						setSelectedTitle(1);
						setError(false);
					}}
				>
					{titles[1]}
				</button>
			</div>
		);
	};
	const Page1 = () => {
		return (
			<div className="relative mt-10 w-[95%] md:w-[70%] lg:w-[50%] flex justify-center login-form">
				{isLoading && (
					<div className="absolute bg-grey w-full h-full flex items-center justify-center z-10">
						<CircularProgress />{" "}
					</div>
				)}

				<div className="absolute left-5 top-5 cursor-hover z-20">
					<ArrowBackIcon
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
				{editAd && (
					<div className="absolute right-5 top-5 cursor-hover">
						<DeleteDialog />
					</div>
				)}
				<div className={isLoading ? "invisible" : ""}>
					<div className="flex flex-col m-5 p-10 w-[500px] space-y-4">
						<p
							style={{ marginBottom: "20px" }}
							className="flex font-bold text-2xl"
						>
							{editAd
								? "Redigera erbjudande"
								: "Lägg till erbjudande"}
						</p>
						<p style={{ marginBottom: "20px" }}>
							{editAd
								? "Redigera ditt erbjudande nedan. Klicka därefter på fortsätt för att fylla i instruktioner som gäller för medlemmar i olika föreningar. Lämna sidan eller klicka på avbryt om du vill ta bort ändringar."
								: "Välj först om du vill lägga till ett erbjudande för privatpersoner eller för andra sponsorer som sponsrar samma klubb. Fyll därefter i fälten och publicera erbjudandet."}
						</p>

						{TitleButtons()}

						<div className="flex items-center">
							<div>
								<FileInput
									parentCallback={setImageFile}
									title={"logga"}
									edit={editAd ? true : false}
									error={error}
								/>
							</div>
						</div>
						<div className="flex items-center">
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={props.whiteLogo}
											onChange={(e) =>
												props.setWhiteLogo(
													e.target.checked
												)
											}
										/>
									}
									label="Vit logga"
								/>
							</FormGroup>
							<Tooltip
								title="Vit logga markeras då loggan är vit utan konturer och inte syns mot den vita bakgrunden i annonsen."
								placement="top"
							>
								<HelpOutlineIcon />
							</Tooltip>
						</div>
						{!props.clubAd && (
							<CheckBoxDropdown
								label="Föreningar"
								elements={clubNames}
								parentCallback={setSelectedClubNames}
								defaultValues={selectedClubNames}
								error={error}
							/>
						)}

						<CustomDropdownField
							label="Kategori"
							elements={CATEGORIES}
							defaultValue={editAd ? editAd.category : ""}
							parentCallback={setCategory}
							error={error}
						/>
						<CustomInputField
							label="Rubrik"
							parentCallback={setAdTitle}
							defaultValue={editAd ? editAd.title : ""}
							error={error}
						/>

						<CustomInputField
							label="Beskrivning"
							multiline
							parentCallback={setDescription}
							defaultValue={editAd ? editAd.description : ""}
							error={error}
						/>
						<div className="flex items-center space-x-2 pb-6">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DesktopDatePicker
									label="Aktiv till och med (frivilligt)"
									inputFormat="YYYY/MM/DD"
									value={date}
									onChange={handleDateChange}
									renderInput={(params) => (
										<div>
											<TextField
												onKeyDown={(e) =>
													e.preventDefault()
												}
												{...params}
											/>
											<FormHelperText
												sx={{
													color: "#e39014",
												}}
											>
												{expiredDate
													? "Observera att datumet ger utgången annons"
													: ""}
											</FormHelperText>
										</div>
									)}
								/>
							</LocalizationProvider>
							{date && (
								<button>
									<ClearIcon onClick={() => setDate(null)} />
								</button>
							)}
						</div>

						{selectedTitle === 1 && (
							<div className="space-y-4">
								<CustomInputField
									label="Kontaktperson"
									parentCallback={setContactName}
									defaultValue={
										editAd ? editAd.contactPerson : ""
									}
									error={error}
								/>
								<CustomInputField
									label="Mobilnummer"
									parentCallback={setContactPhone}
									defaultValue={
										editAd ? editAd.phoneNumber : ""
									}
									error={error}
									type="tel"
								/>
								<CustomInputField
									label="E-post"
									type="email"
									parentCallback={setContactEmail}
									defaultValue={editAd ? editAd.email : ""}
									error={error}
								/>
							</div>
						)}

						<div className={"flex justify-evenly"}>
							<StandardButton
								text="Avbryt"
								onClick={() => {
									navigate(-1);
								}}
								secondary
							/>
							<StandardButton
								text="Fortsätt"
								onClick={() => {
									handlePublishClick();
								}}
							/>
						</div>
						<p className="font-medium self-center">Sida 1/2</p>
					</div>
				</div>
			</div>
		);
	};
	const Page2 = () => {
		return (
			<div className="relative mt-10 w-[95%] md:w-[70%] lg:w-[50%] flex justify-center login-form">
				<div className="absolute left-5 top-5 cursor-hover">
					<ArrowBackIcon
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
				<div className="absolute right-5 top-5 cursor-hover">
					<DeleteDialog />
				</div>
				<div className="flex flex-col m-5 p-10 w-[500px] space-y-4">
					<p
						style={{ marginBottom: "20px" }}
						className="flex font-bold text-2xl"
					>
						{editAd
							? "Redigera instruktioner"
							: "Lägg till instruktioner"}
					</p>
					<p style={{ marginBottom: "20px" }}>
						{editAd
							? "Här redigerar du vilka instruktioner och länkar som ska finnas för erbjudandet för medlemmar i olika föreningar."
							: "Här lägger du till vilka instruktioner och länkar som ska finnas för erbjudandet för medlemmar i olika föreningar."}
					</p>

					{TitleButtons()}

					{selectedClubs.map((club, index) => {
						let defaultInstructions = "";
						let defaultUrl = "";

						ids[index] = club.id;

						if (editAd) {
							const clubInstructions =
								editAd.clubOfferInstructions.filter(
									(a) => a.club_id === club.id
								)[0];

							if (clubInstructions) {
								defaultInstructions =
									clubInstructions.instructions;
								defaultUrl = clubInstructions.url;
							}
						}
						instructions[index] = defaultInstructions;
						urls[index] = defaultUrl;

						return (
							<div key={index}>
								<p
									style={{ marginBottom: "20px" }}
									className="flex font-bold text-xl"
								>
									{club.name}
								</p>

								<CustomInputField
									label="Instruktioner för att ta del av erbjudandet"
									multiline
									parentCallback={(s: string) => {
										instructions[index] = s;
									}}
									defaultValue={defaultInstructions}
									error={error}
								/>
								<CustomInputField
									label="Länk till erbjudandet"
									parentCallback={(s: string) => {
										urls[index] = s;
									}}
									defaultValue={defaultUrl}
									error={error}
								/>
							</div>
						);
					})}

					{isLoading ? (
						<div className="self-center">
							<CircularProgress />
						</div>
					) : (
						!submit && (
							<div className={"flex justify-evenly"}>
								<StandardButton
									text="Avbryt"
									onClick={() => {
										navigate(-1);
									}}
									secondary
								/>
								<StandardButton
									text={
										editAd
											? "Spara ändringar"
											: "Publicera erbjudande"
									}
									onClick={() => {
										mapInstructions();
									}}
								/>
							</div>
						)
					)}
					<p className="font-medium self-center">Sida 2/2</p>
				</div>
			</div>
		);
	};

	const mapInstructions = () => {
		const offerInstructions: ClubOfferInstruction[] = ids.map((id, i) => ({
			club_id: id,
			url: urls[i],
			instructions: instructions[i],
		}));
		setClubOfferInstructions(offerInstructions);
		setSubmit(true);
	};

	const Pageing = () => {
		if (pageNumber === 1) return Page1();
		else if (pageNumber === 2) return Page2();
	};

	return (
		<div className="flex justify-center items-start flex-grow">
			{Pageing()}
		</div>
	);
};
