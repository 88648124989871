import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../components/InputField";
import { Nav } from "../components/Nav";
import { StandardButton } from "../components/StandardButton";
import { useAuth } from "../hooks/useAuth";
import { useErrorMessages } from "../hooks/useErrorMessages";
import { useFirestore } from "../hooks/useFirestore";
import { Pages, usePageNavigation } from "../hooks/usePageNavigation";

export const LoginPage = () => {
	const {
		navigateToSuperSponsors,
		navigateToCompanyHomePage,
		navigateToClubHomePage,
		navigateToUpdateUserPage,
	} = usePageNavigation();

	const { login } = useAuth();
	const { user } = useAuth();

	const { getErrorMessageLogin } = useErrorMessages();
	const [error, setError] = useState("");

	const firestore = useFirestore();

	useEffect(() => {}, []);

	const handleOnLoginClick = (e: any) => {
		e.preventDefault();

		const email = e.target.email.value;
		const password = e.target.password.value;

		login(email, password)
			.then((resp) => { 
				firestore
					.getUser(resp.user.uid)
					.then((user) => {
						let { email, first_name, last_name } = user;
						if (!email || !first_name || !last_name) {
							navigateToUpdateUserPage();
							return;
						} else {
						}
						if (user.user_type === 1) {
							navigateToCompanyHomePage();
						} else if (user.user_type === 2) {
							navigateToSuperSponsors();
						} else if (user.user_type === 3) {
							navigateToClubHomePage();
						}
					})
					.catch((error: string) => {
						setError(error);
					});
			})
			.catch((error: FirebaseError) => {
				setError(getErrorMessageLogin(error.code));
				console.log(error.code);
			})
			.finally(() => {
				// TODO: finish loading
			});
	};

	return (
		<div>
			<Nav />
			<div style={{ width: "100%" }} className="flex justify-center">
				<form
					onSubmit={handleOnLoginClick}
					className="flex flex-col align-middle m-5 p-10 login-form"
				>
					<p
						style={{ marginBottom: "20px" }}
						className="flex font-bold text-2xl"
					>
						Företagsinloggning
					</p>
					<div style={{ marginBottom: "15px" }}>
						<InputField
							placeholderText="Email"
							type="email"
							name="email"
						/>
					</div>
					<div>
						<InputField
							placeholderText="Lösenord"
							type="password"
							name="password"
						/>
					</div>
					{error && (
						<div
							className="error-box px-5 py-2"
							style={{ marginTop: "10px" }}
						>
							<p className="color-primary-red">{error}</p>
						</div>
					)}
					<Link
						to={Pages.FORGOT_PASSWORD_PAGE}
						className="color-primary-blue"
						style={{ marginBottom: "10px", marginTop: "30px" }}
					>
						Glömt lösenord?
					</Link>
					<StandardButton text="Logga in" />
				</form>
			</div>
		</div>
	);
};
