import { DropdownButton } from "./DropdownButton";
import { StandardButton } from "./StandardButton";
import { usePageNavigation } from "../hooks/usePageNavigation";
import { CATEGORIES } from "../Categories";
import { REGIONS } from "../Regions";
import { Notification } from "../DBTypes";
import { NotificationIcon } from "./NotificationIcon";

interface Props {
	title: string;
	title2?: string;
	title3?: string;
	setCategoryFilter?: any;
	setCityFilter?: any;
	selectedTitle?: number;
	setSelectedTitle?: any;
	addButton?: boolean;
	memberUrl?: string;
	sponsorUrl?: string;
	clubAdmin?: boolean;
	eventNotifications?: Notification[];
	adNotifications?: Notification[];
	responseNotifications?: Notification[];
	onReadClick?: any;
	onRemoveClick?: any;
}

export const GridMenu = (props: Props) => {
	const {
		navigateToManageAdsPage,
		navigateToManageEventsPage,
		navigateToCompanyInfoPage,
		navigateToClubAdminPage,
	} = usePageNavigation();

	const TitleButtons = () => {
		return (
			<div className="" style={{ marginRight: 0 }}>
				<button
					className={
						"font-bold text-sm sm:text-lg mymd:text-xl mr-4" +
						(props.selectedTitle === 0 ? "" : " color-text-gray")
					}
					onClick={() => props.setSelectedTitle(0)}
				>
					{props.title}
				</button>
				{props.title2 && (
					<button
						className={
							"font-bold text-sm sm:text-lg mymd:text-xl mx-4" +
							(props.selectedTitle === 1
								? ""
								: " color-text-gray")
						}
						onClick={() => props.setSelectedTitle(1)}
					>
						{props.title2}
					</button>
				)}
				{props.title3 && (
					<button
						className={
							"font-bold text-sm sm:text-lg mymd:text-xl ml-4" +
							(props.selectedTitle === 2
								? ""
								: " color-text-gray")
						}
						onClick={() => props.setSelectedTitle(2)}
					>
						{props.title3}
					</button>
				)}
			</div>
		);
	};

	return (
		<nav>
			<div className="flex justify-between items-center pl-[5%] sm:pr-[5%] pt-3 pb-2 overflow-hidden ">
				{props.title2 ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						{props.adNotifications &&
							props.adNotifications.length > 0 && (
								<div>
									<NotificationIcon
										notifications={props.adNotifications}
										onReadClick={props.onReadClick}
										onRemoveClick={props.onRemoveClick}
									/></div>
							)}
						{TitleButtons()}
						{props.eventNotifications &&
							props.eventNotifications.length > 0 && (
								<div>
									<NotificationIcon
										notifications={props.eventNotifications}
										onReadClick={props.onReadClick}
										onRemoveClick={props.onRemoveClick}
									/></div>
							)}
						{props.responseNotifications &&
							props.responseNotifications.length > 0 && (
								<div>
									<NotificationIcon
										notifications={props.responseNotifications}
										onReadClick={props.onReadClick}
										onRemoveClick={props.onRemoveClick}
									/></div>
							)}
					</div>
				) : (
					<p className={"font-bold text-sm sm:text-lg mymd:text-xl"}>
						{props.title}
					</p>
				)}

				<div
					className={
						"flex scale-75 items-center sm:scale-90 mymd:scale-100 justify-end flex-wrap space-x-4"
					}
				>
					<div className="h-full flex flex-wrap justify-end">
						{props.memberUrl && (
							<a
								href={
									"https://" +
									props.memberUrl
										.replace("http://", "")
										.replace("https://", "")
								}
								className="my-2 mx-2"
							>
								<StandardButton text="Bli medlem" />
							</a>
						)}
						{props.sponsorUrl && (
							<a
								href={
									"https://" +
									props.sponsorUrl
										.replace("http://", "")
										.replace("https://", "")
								}
								className="my-2 mx-2"
							>
								<StandardButton text="Bli sponsor" />
							</a>
						)}
					</div>
					{props.addButton && (
						<div className="flex flex-wrap gap-x-4 gap-y-2">
							<StandardButton
								text="Ändra uppgifter"
								onClick={() => {
									if (props.clubAdmin) {
										navigateToClubAdminPage();
									} else {
										navigateToCompanyInfoPage();
									}
								}}
							/>
							<StandardButton
								text="Lägg till erbjudande"
								onClick={() => {
									if (props.clubAdmin) {
										navigateToManageAdsPage(
											undefined,
											true
										);
									} else {
										navigateToManageAdsPage();
									}
								}}
							/>
							{props.clubAdmin && (
								<StandardButton
									text="Skapa event"
									onClick={() => {
										navigateToManageEventsPage();
									}}
								/>
							)}
						</div>
					)}

					<div className={"flex flex-wrap justify-end"}>
						{props.setCategoryFilter && (
							<DropdownButton
								title="Kategori"
								elements={[...["Alla"], ...CATEGORIES]}
								transparent
								height={35}
								parentCallback={props.setCategoryFilter}
							/>
						)}
						{props.setCityFilter && (
							<DropdownButton
								title="Region"
								elements={[...["Alla"], ...REGIONS]}
								transparent
								height={35}
								parentCallback={props.setCityFilter}
							/>
						)}
					</div>
				</div>
			</div>
		</nav>
	);
};
