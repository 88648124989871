import React, { useEffect, useState } from "react";
import { CompactPicker } from "react-color";
import { StandardButton } from "./StandardButton";

interface Props {
	defaultColor?: string;
	parentCallback: any;
}
export function ColorPicker(props: Props) {
	const [color, setColor] = useState<string>();

	const handleChangeComplete = (newColor: any) => {
		setColor(newColor.hex);
	};

	const noColor = () => {
		setColor(undefined);
		props.parentCallback("");
	};
	useEffect(() => {
		if (props.defaultColor) {
			setColor(props.defaultColor);
		}
	}, []);

	useEffect(() => {
		if (color) {
			props.parentCallback(color);
		}
	}, [color]);

	return (
		<div className="flex items-center space-x-2">
			<CompactPicker
				color={color}
				onChangeComplete={handleChangeComplete}
			/>

			{color && (
				<div
					className={
						"flex flex-col space-y-2 align-center items-center"
					}
				>
					<div
						className="w-[35px] aspect-square"
						style={{
							backgroundColor: color,
						}}
					></div>
					<StandardButton
						text="Ingen färg"
						onClick={() => {
							noColor();
						}}
					/>
				</div>
			)}
		</div>
	);
}
