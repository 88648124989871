import { FirebaseError } from "firebase/app";
import {
	getAuth,
	isSignInWithEmailLink,
	signInWithEmailLink,
	updatePassword,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { InputField } from "../components/InputField";
import { Nav } from "../components/Nav";
import { StandardButton } from "../components/StandardButton";
import { useAuth } from "../hooks/useAuth";
import { useErrorMessages } from "../hooks/useErrorMessages";
import { useFirestore } from "../hooks/useFirestore";
import { usePageNavigation } from "../hooks/usePageNavigation";

export const SignupPage = () => {
	const {
		navigateToMainPage,
		navigateToCompanyHomePage,
		navigateToClubHomePage,
		navigateToUpdateUserPage,
		navigateToPolicyPage,
	} = usePageNavigation();
	let auth = getAuth();
	let user = useAuth().user;

	const { login, logout } = useAuth();

	const { getErrorMessageLogin } = useErrorMessages();
	const [error, setError] = useState("");

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const companyID = urlParams.get("company_id");
	const isAdmin = urlParams.get("is_admin");
	const clubID = urlParams.get("club_id");
	let firestore = useFirestore();

	// Uses the urlParams to identify if we want to create a company user or
	// a club user. The input parameters, including accepting the terms, are checked
	// in the handleOnClick function.
	const chooseUserType = async (
		uid: string,
		email: string,
		firstName: string,
		lastName: string
	) => {
		try {
			if (companyID && companyID.length > 0) {
				let admin = false;
				if (isAdmin && isAdmin === "true") admin = true; // Admin rights for sponsor
				firestore.createNewUser(
					uid,
					companyID,
					email,
					firstName,
					lastName,
					admin
				);
			} else if (clubID && clubID.length > 0) {
				firestore.createNewClubUser(
					uid,
					clubID,
					email,
					firstName,
					lastName
				);
			}
		} catch (err) {
			alert("Kunde inte skapa användare");
			console.error(err);
		}
	};

	useEffect(() => {
		// Log out if already logged in
		if (user) logout();
		// If we do not have a sign in link, go back to home page
		if (!isSignInWithEmailLink(auth, window.location.href)) {
			navigateToMainPage();
		}
	}, []);

	const handleOnLoginClick = (e: any) => {
		e.preventDefault();
		const email = e.target.email.value;
		const password = e.target.password.value;
		const confirmPassword = e.target.confirmPassword.value;
		const firstName = e.target.firstName.value;
		const lastName = e.target.lastName.value;
		const acceptTerms = e.target.acceptTerms.checked;
		if (password.length < 6) {
			setError("Lösenordet måste vara minst 6 tecken");
			return;
		} else if (password !== confirmPassword) {
			setError("Lösenorden matchar inte");
			return;
		}
		if (!email || !firstName || !lastName) {
			setError("Var vänlig fyll i samtliga fält");
			return;
		} else if (!acceptTerms) {
			setError("Acceptera användarvillkoren för att använda tjänsten");
			return;
		}

		if (isSignInWithEmailLink(auth, window.location.href)) {
			// Sign in with email link
			// Create a password login with updatePassword
			// Login in user
			signInWithEmailLink(auth, email, window.location.href)
				.then((resp) => {
					updatePassword(resp.user, password).then(() => {
						login(email, password);
						chooseUserType(
							resp.user.uid,
							email,
							firstName,
							lastName
						)
							.then(() => {
								if (companyID) {
									navigateToCompanyHomePage();
								} else if (clubID) {
									navigateToClubHomePage();
								}
							})
							.catch((error: FirebaseError) => {
								setError(getErrorMessageLogin(error.code));
								console.log(error.code);
							})
							.finally(() => {
								// TODO: finish loading
							});
					});
				})
				.catch((error: FirebaseError) => {
					setError(getErrorMessageLogin(error.code));
					console.log(error.code);
				});
		}
	};

	return (
		<div>
			<Nav />
			<div style={{ width: "100%" }} className="flex justify-center">
				<form
					onSubmit={handleOnLoginClick}
					className="flex flex-col align-middle m-5 p-10 login-form"
				>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Bekräfta email"
							type="email"
							name="email"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Förnamn"
							type="text"
							name="firstName"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Efternamn"
							type="text"
							name="lastName"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Lösenord"
							type="password"
							name="password"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<InputField
							placeholderText="Bekräfta lösenord"
							type="password"
							name="confirmPassword"
						/>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<input
							type="checkbox"
							style={{ marginRight: "10px", padding: "20px" }}
							name="acceptTerms"
						/>
						<span>
							Jag accepterar{" "}
							<span className="text-[#1e9ede]">
								<a
									href="https://parter.se/privacy_policy"
									target="_blank"
								>
									användarvillkoren
								</a>
							</span>
						</span>
					</div>
					{error && <p className="color-primary-red">{error}</p>}
					<StandardButton text="Skapa konto" />
				</form>
			</div>
		</div>
	);
};
