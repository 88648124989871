import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
	getFirestore,
	initializeFirestore,
	connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth"; 
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

initializeFirestore(app, {
	ignoreUndefinedProperties: true,
});
export const secondaryApp = initializeApp(firebaseConfig, "Secondary");
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
if (process.env.NODE_ENV === "development") {
	connectAuthEmulator(auth, "http://localhost:9099");
}

export const storage = getStorage(app);
if (process.env.NODE_ENV === "development") {
	connectStorageEmulator(storage, "localhost", 9199);
}

export const db = getFirestore(app);
if (process.env.NODE_ENV === "development") {
	connectFirestoreEmulator(db, "localhost", 8080);
}

export const functions = getFunctions(app);
if (process.env.NODE_ENV === "development") {
	connectFunctionsEmulator(functions, "localhost", 5001);
}
