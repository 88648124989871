import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomDropdownField } from "../../components/CustomDropdownField";
import { MultipleInputFields } from "../../components/MultipleInputFields";
import { CustomInputField } from "../../components/CustomInputField";
import { FileInput } from "../../components/FileInput";
import { StandardButton } from "../../components/StandardButton";
import { ColorPicker } from "../../components/ColorPicker";
import { Club, SponsorGroup } from "../../DBTypes";
import { useFirestore } from "../../hooks/useFirestore";
import { REGIONS } from "../../Regions";
import { SuperAdminTemplate } from "./SuperadminTemplate";
import { usePageNavigation } from "../../hooks/usePageNavigation";

export const SuperClubPage = () => {
	const { navigateToSuperClubs } = usePageNavigation();

	const [club, setClub] = useState<Club>();
	const { club_id } = useParams<string>();
	const { getClubs, updateClub, removeClub } = useFirestore();
	const [errorLoadingClub, setErrorLoadingClub] = useState<string>("");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingEdit, setIsLoadingEdit] = useState(false);
	const [isLoadingRemove, setIsLoadingRemove] = useState(false);

	const [clubName, setClubName] = useState("");
	const [description, setDescription] = useState("");
	const [email, setEmail] = useState<string>();
	const [region, setRegion] = useState("");
	const [memberUrl, setMemberUrl] = useState("");
	const [sponsorUrl, setSponsorUrl] = useState("");
	const [organisationNumbers, setOrganisationNumbers] = useState<string[]>();
	const [sponsorGroups, setSponsorGroups] = useState<SponsorGroup[]>([]);

	const [image, setImage] = useState<File>();
	const [hideLogo, setHideLogo] = useState<boolean>(false);

	const [backgroundImage, setBackgroundImage] = useState<File | string>();
	const [partnerBackgroundImage, setPartnerBackgroundImage] = useState<
		File | string
	>();
	const [backgroundColor, setBackgroundColor] = useState<string>();

	useEffect(() => {
		if (club_id) {
			setEmail("");
			getClubs([club_id])
				.then((resp) => {
					setClub(resp[0]);
				})
				.catch((error) => {
					setIsLoading(false);
					setErrorLoadingClub(error);
				});
		} else {
			setErrorLoadingClub("Kunde inte hitta sponsor");
		}
	}, []);

	useEffect(() => {
		if (sponsorGroups) {
			let numbers = extractNumbers(sponsorGroups);
			setOrganisationNumbers(numbers);
		}
	}, [sponsorGroups]);

	useEffect(() => {
		if (club) {
			setClubName(club.name);
			setDescription(club.description);
			setRegion(club.region);
			setBackgroundColor(club.background_color);
			setOrganisationNumbers(club.organisationNumbers);
			if (club.member_url) setMemberUrl(club.member_url);
			if (club.sponsor_url) setSponsorUrl(club.sponsor_url);
			if (club.hideLogo) {
				setHideLogo(club.hideLogo);
			}

			if (club.sponsor_groups) {
				setSponsorGroups(club.sponsor_groups);
			} else if (club.organisationNumbers.length > 0) {
				setSponsorGroups([
					{
						name: "",
						prioritizationNumber: 1,
						organisationNumbers: club.organisationNumbers,
					},
				]);
			} else {
				setSponsorGroups([
					{
						name: "",
						prioritizationNumber: 1,
						organisationNumbers: [],
					},
				]);
			}
			setIsLoading(false);
		}
	}, [club]);

	const extractNumbers = (groups: SponsorGroup[]) => {
		const numberArray: string[] = [];
		groups.forEach((group: SponsorGroup) => {
			group.organisationNumbers.forEach((number) => {
				numberArray.push(number);
			});
		});
		return numberArray;
	};

	/*
	useEffect(() => {
		const str = organisationNumberInput.split(/\r?\n/);
		const newStr: string[] = [];
		str.forEach((s) => {
			if (s !== "") newStr.push(s.trim());
		});
		setOrganisationNumbers(newStr);
	}, [organisationNumberInput]);*/

	const handleOnSubmit = () => {
		if (club && region && clubName) {
			setIsLoadingEdit(true);
			updateClub(
				club.id,
				clubName,
				description,
				region,
				image,
				club.image_url,
				organisationNumbers,
				club.organisationNumbers,
				memberUrl,
				sponsorUrl,
				backgroundImage,
				partnerBackgroundImage,
				club.background_url,
				club.partner_background_url,
				backgroundColor,
				sponsorGroups,
				email,
				hideLogo
			)
				.then(() => {
					setIsLoadingEdit(false);
					alert("Föreningen har uppdaterats");
					if (club_id)
						getClubs([club_id]).then((resp) => setClub(resp[0]));
				})
				.catch((error) => {
					setIsLoadingEdit(false);
					alert(error);
				});
		} else {
			alert("Föreningens namn och region måste finnas med!");
		}
	};

	const handleOnRemoveClub = () => {
		if (club_id) {
			setIsLoadingRemove(true);

			removeClub(club_id)
				.then(() => {
					setIsLoadingRemove(false);
					alert("Föreningen har tagits bort");
					navigateToSuperClubs();
				})
				.catch((error) => {
					setIsLoadingRemove(false);
					alert(error);
				});
		}
	};

	return (
		<SuperAdminTemplate title={club ? club.name : "Hämtar förening"}>
			<div className="flex flex-col align-top w-96">
				{isLoading && !errorLoadingClub ? (
					<CircularProgress color="inherit" />
				) : (
					<>
						{errorLoadingClub ? (
							<p>{errorLoadingClub}</p>
						) : (
							<div className="flex flex-col align-top w-96">
								<CustomInputField
									label="Föreningens namn"
									parentCallback={setClubName}
									defaultValue={clubName}
								/>
								<CustomInputField
									label="Kontaktpersonens email"
									parentCallback={setEmail}
									defaultValue={email}
								/>
								<CustomInputField
									label="Beskrivning"
									multiline
									parentCallback={setDescription}
									defaultValue={description}
								/>
								<CustomDropdownField
									label="Välj region"
									elements={REGIONS}
									parentCallback={setRegion}
									defaultValue={region}
								/>
								<CustomInputField
									label="Länk för att bli medlem (frivilligt)"
									parentCallback={setMemberUrl}
									defaultValue={memberUrl}
								/>
								<CustomInputField
									label="Länk för att bli sponsor (frivilligt)"
									parentCallback={setSponsorUrl}
									defaultValue={sponsorUrl}
								/>

								<p className="mb-1">
									<span className="text-red-600">
										Notera:
									</span>{" "}
									Separera organisationsnummer med hjälp av
									radbyte. <br /> Upp till 10 NYA
									organisationsnummer kan läggas till
									samtidigt.
								</p>
								{/*<CustomInputField
									label="Sponsorers organisationsnummer"
									multiline
									parentCallback={setOrganisationNumberInput}
									defaultValue={organisationNumberInput}
								/>*/}
								<div className="mb-8">
									<MultipleInputFields
										groups={sponsorGroups}
										setGroups={setSponsorGroups}
									/>
								</div>

								<div className="mb-3">
									<div className="flex">
										<FileInput
											edit={true}
											parentCallback={setImage}
											title={"logga"}
										/>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={hideLogo}
														onChange={(e) =>
															setHideLogo(
																e.target.checked
															)
														}
													/>
												}
												label="Visa inte logga"
											/>
										</FormGroup>
									</div>
								</div>
								<b className="pt-4">Bakgrundsbilder</b>
								<p className="pb-4">
									Bakgrundsbilder som visas för privatpersoner
									respektive föreningens sponsorer.
									Bildförhållande 5:2 (t.ex. 1920x768). Notera
									att detaljer långt ut på sidorna inte syns
									på telefon och surfplatta.
								</p>
								<div className="mb-3 flex">
									<FileInput
										edit={
											club && club.background_url
												? true
												: false
										}
										parentCallback={setBackgroundImage}
										title={"bakgrundsbild"}
									/>
									{club &&
										club.background_url &&
										!backgroundImage && (
											<div className="flex space-x-2">
												<img
													className={
														"max-w-[60px] max-h-[60px]"
													}
													src={club.background_url}
													alt={"s"}
												/>
												<button
													onClick={() => {
														setBackgroundImage(
															"NONE"
														);
													}}
												>
													Ta bort
												</button>
											</div>
										)}
								</div>
								<div className="mb-3 flex pb-4">
									<FileInput
										edit={
											club && club.partner_background_url
												? true
												: false
										}
										parentCallback={
											setPartnerBackgroundImage
										}
										title={"bakgrundsbild (sponsor)"}
									/>
									{club &&
										club.partner_background_url &&
										!partnerBackgroundImage && (
											<div className="flex space-x-2">
												<img
													className={
														"max-w-[60px] max-h-[60px]"
													}
													src={
														club.partner_background_url
													}
													alt={"s"}
												/>
												<button
													onClick={() => {
														setPartnerBackgroundImage(
															"NONE"
														);
													}}
												>
													Ta bort
												</button>
											</div>
										)}
								</div>
								<div className="mb-6 flex flex-col space-y-2">
									<b>Bakgrundsfärg</b>
									<div className="flex">
										<ColorPicker
											defaultColor={backgroundColor}
											parentCallback={setBackgroundColor}
										/>
										<div></div>
									</div>
								</div>
								{isLoadingEdit ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : (
									<StandardButton
										text="Ändra föreningens uppgifter"
										onClick={handleOnSubmit}
									/>
								)}

								<div className="mt-20">
									{isLoadingRemove ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : (
										<StandardButton
											text="Ta bort förening"
											onClick={handleOnRemoveClub}
											color={"red"}
										/>
									)}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</SuperAdminTemplate>
	);
};
