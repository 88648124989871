import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Props {
	title?: string;
	elements: string[];
	parentCallback?: any;
	transparent?: boolean;
	height?: number;
	getIndex?: boolean;
	default?: number;
}

export const DropdownButton = (props: Props) => {
	const [category, setCategory] = React.useState<string>(
		props.title ? "" : "0"
	);

	const handleChange = (event: SelectChangeEvent) => {
		setCategory(event.target.value);
		var index: number = +event.target.value;

		if (props.getIndex) {
			props.parentCallback(index);
		} else if (props.elements[index] === "Alla") {
			props.parentCallback("");
		} else {
			props.parentCallback(props.elements[index]);
		}
	};

	useEffect(() => {
		if (props.default) setCategory(props.default.toString());
	}, [props.default]);

	return (
		<div>
			<Box sx={{ minWidth: 120 }}>
				<FormControl
					fullWidth
					variant="outlined"
					sx={{
						"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
							border: "none",
						},
					}}
				>
					<InputLabel id="demo-simple-select-label">
						{props.title}
					</InputLabel>
					<Select
						fullWidth
						label={props.title}
						value={category}
						sx={{
							fontFamily: "RedHatText",
							backgroundColor: props.transparent
								? ""
								: "var(--color-primary-blue)",
							height: props.transparent ? "" : props.height,
							color: props.transparent
								? "var(--color-text-black)"
								: "var(--color-text-white)",
							borderRadius: 0,
							"& .MuiSvgIcon-root": {
								color: props.transparent
									? "var(--color-text-black)"
									: "var(--color-text-white)",
							},
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
						}}
						onChange={handleChange}
						IconComponent={KeyboardArrowDownIcon}
					>
						{props.elements.map((name, index) => (
							<MenuItem key={index} value={index}>
								{name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</div>
	);
};
