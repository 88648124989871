import { FirebaseError } from "firebase/app";
import { useState } from "react";
import { InputField } from "../components/InputField";
import { Nav } from "../components/Nav";
import { StandardButton } from "../components/StandardButton";
import { useAuth } from "../hooks/useAuth";
import { useErrorMessages } from "../hooks/useErrorMessages";

export const ForgotPasswordPage = () => {
	const { getErrorMessageLogin } = useErrorMessages();
	const [error, setError] = useState("");

	const auth = useAuth();
	const handleOnSubmitClick = (e: any) => {
		e.preventDefault();
		auth.resetPassword(e.target.email.value)
			.then((resp) => {
				setError("Skickat meddelande");
			})
			.catch((error: FirebaseError) => {
				setError(getErrorMessageLogin(error.code));
			});
	};

	return (
		<div>
			<Nav />
			<div style={{ width: "100%" }} className="flex justify-center">
				<form onSubmit={handleOnSubmitClick}>
					<div style={{ marginBottom: "10px" }}>
						<InputField
							placeholderText="Email"
							type="email"
							name="email"
						/>
					</div>
					{error && <p className="color-primary-red">{error}</p>}
					<StandardButton text="Återställ lösenord" />
				</form>
			</div>
		</div>
	);
};
