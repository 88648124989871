import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CircularProgress } from "@mui/material";
import { Company, DBUser, Event, Notification } from "../DBTypes";
import { useFirestore } from "../hooks/useFirestore";
import InvitationList from "./InvitationList";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAuth } from "../hooks/useAuth";
import { functions } from "../Firebase";
import { httpsCallable } from "firebase/functions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	title: string;
	onClose: () => void;
}

interface Props {
	open: boolean;
	handleClose: any;
	object: Event;
}

/**
 * Dialog based on MUI. Takes an object with Ad data and displays it when set to open in parent.
 *
 */

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, title, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			Event av {title}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const DateTimeRender = (date: Date) => {
	return (
		<div className="flex space-x-2">
			<b className="bg-stone-200 px-2 rounded-sm">
				{date.toLocaleDateString()}
			</b>
			<b className="bg-stone-200 px-2 rounded-sm">
				{"kl. " +
					date.toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					})}
			</b>
		</div>
	);
};

const LocationRender = (street: string, area: string) => {
	return (
		<div className="flex py-5 space-x-2 ">
			<LocationOnIcon />
			<div className="flex flex-col">
				<Typography
					fontSize="16px"
					sx={{
						fontFamily: "RedHatText",
						fontWeight: "bold",
					}}
				>
					{street}
				</Typography>
				<Typography
					fontSize="16px"
					sx={{ fontFamily: "RedHatText", fontWeight: "bold" }}
				>
					{area}
				</Typography>
			</div>
		</div>
	);
};

export default function EventDialog(props: Props) {
	const { id, invited } = props.object;

	const { getCompanies, getEventFromID, getUsersByClubId } = useFirestore();
	let auth = useAuth();
	const user = auth.user;

	const [companies, setCompanies] = useState<Company[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showInvited, setShowInvited] = useState<boolean>(false);
	const [answer, setAnswer] = useState<number>(0);
	const [event, setEvent] = useState<Event>();

	const fetchUsers = async (club_id: string) => {
		try {
			let allUserIds: string[] = await getUsersByClubId(club_id);
			return allUserIds;
		} catch (err) {
			console.log(err);
		}
	};

	const handleAnswer = (e: SelectChangeEvent) => {
		const answerIndex = Number(e.target.value);
		const updateEventAnswer = httpsCallable(functions, "updateEventAnswer");
		if (event && event.id && user && user.firestoreUser.company_id) {
			setIsLoading(true);
			updateEventAnswer({
				event_id: event.id,
				sponsor_id: user.firestoreUser.company_id,
				answer: answerIndex,
			})
				.then(async (d) => {
					getEventFromID(id).then((event) => {
						setEvent(event);
					});
					setAnswer(answerIndex);
					const allUserIds = await fetchUsers(event.club_id);
					const filteredCompanyIds = event.sponsor_ids.filter(
						(sponsor_id) =>
							sponsor_id === user.firestoreUser.company_id
					);
					const companyId = filteredCompanyIds[0];
					const respondingCompany = await getCompanies([companyId]);

					const clubNotis: Notification = {
						event_name: event.title,
						responding_company: respondingCompany[0].name,
						response: answerIndex,
						responding_user: user.uid,
						read: false,
						isUpdate: false,
						type: "response",
					};
					const createNotifications = httpsCallable(
						functions,
						"createNotifications"
					);
					if (allUserIds) {
						createNotifications({
							users: allUserIds,
							response: clubNotis,
						});
					}
				})

				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		if (props.open && user) {
			setCompanies(undefined);
			const ids = invited.map((i) => i.sponsor_id);
			setIsLoading(true);

			Promise.all([getCompanies(ids), getEventFromID(id)])
				.then(([companies, event]) => {
					setCompanies(companies);
					setEvent(event);
					if (user.firestoreUser.company_id) {
						const myInvite = event.invited.find(
							(i) =>
								i.sponsor_id === user.firestoreUser.company_id
						);
						if (myInvite) {
							const myAnswer = myInvite.answer;
							setAnswer(myAnswer);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [props.open]);

	function AnswerSelect() {
		return (
			<div>
				<Box sx={{ minWidth: 120 }}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">
							Svar
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={answer.toString()}
							label="Svar"
							onChange={handleAnswer}
						>
							<MenuItem value={"1"}>Kommer</MenuItem>
							<MenuItem value={"-1"}>Kommer inte</MenuItem>
							<MenuItem value={"0"}>Inget svar</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</div>
		);
	}

	return (
		<div>
			<BootstrapDialog
				onClose={props.handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
			>
				<div className="min-w-[30vw]">
					<BootstrapDialogTitle
						id="customized-dialog-title"
						title={event ? event.club_name : "Titel"}
						onClose={props.handleClose}
					></BootstrapDialogTitle>
					{isLoading ? (
						<div className="flex items-center justify-center h-[200px]">
							<CircularProgress />
						</div>
					) : event ? (
						<DialogContent>
							<div className="px-[5%] sm:px-[10%] flex flex-col py-4">
								<img
									className={
										"my-8 object-scale-down max-h-[150px] min-h-[75px]"
									}
									src={event.image_url}
									alt={event.title}
								/>
								<Typography
									gutterBottom
									sx={{
										fontSize: "1.2rem",
										fontWeight: "bold",
										fontFamily: "RedHatText",
									}}
								>
									{event.title}
								</Typography>
								<div className="pt-2 flex flex-wrap items-center space-x-4 justify-between ">
									<div>{DateTimeRender(event.date)}</div>

									{LocationRender(event.street, event.area)}
								</div>

								{user &&
									user.firestoreUser.company_id &&
									AnswerSelect()}

								<div className="py-4 ">
									<Typography
										gutterBottom
										sx={{ fontFamily: "RedHatText" }}
									>
										{event.description}
									</Typography>
								</div>
								{event.invited && companies && (
									<div>
										<InvitationList
											open={showInvited}
											setOpen={setShowInvited}
											invited={event.invited}
											companies={companies}
										/>
									</div>
								)}
							</div>
						</DialogContent>
					) : (
						<p className="p-10">Något gick fel, försök igen</p>
					)}
				</div>
			</BootstrapDialog>
		</div>
	);
}
