import { usePageNavigation } from "../../hooks/usePageNavigation";
import { ParterData } from "../ParterData";
import { Ad, Club, Company, Event } from "../../DBTypes";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";

interface Category {
	text: string;
}

const CategoryContainer = (category: Category) => {
	return <div className="category-box text-xs">{category.text}</div>;
};
const SponsorContainer = () => {
	return <div className="sponsor-label text-xs">Sponsordeal</div>;
};

const isAd = (card: Ad | Club | Company | Event): card is Ad => {
	return true;
};
const isClub = (card: Ad | Club | Company | Event): card is Club => {
	return true;
};
const isCompany = (card: Ad | Club | Company | Event): card is Company => {
	return true;
};
const isEvent = (card: Ad | Club | Company | Event): card is Event => {
	return true;
};
const isExpired = (d: Date) => {
	var expiryDate = d;
	var todaysDate = new Date();
	todaysDate.setHours(0, 0, 0, 0);

	if (expiryDate.getTime() < todaysDate.getTime()) {
		return true;
	} else {
		return false;
	}
};

export const ParterCard = (props: ParterData) => {
	const {
		navigateToCompanyPage,
		navigateToClubPage,
		navigateToCompanyClubPage,
		navigateToManageAdsPage,
		navigateToManageEventsPage,
	} = usePageNavigation();
	const location = useLocation();

	const cardAction = () => {
		if (props.type === "company" && isCompany(props.object)) {
			if (props.companyDialog) {
				if (props.onClick) props.onClick(props.object);
			} else {
				navigateToCompanyPage(props.object.id);
			}
		} else if (
			(props.type === "event" || props.type === "editable_event") &&
			isEvent(props.object)
		) {
			if (props.onClick) props.onClick(props.object);
		} else if (props.type === "club" && isClub(props.object)) {
			navigateToClubPage(props.object.id);
		} else if (props.type === "company_club" && isClub(props.object)) {
			navigateToCompanyClubPage(props.object.id);
		} else if (
			(props.type === "ad" || props.type === "editable_ad") &&
			isAd(props.object)
		) {
			if (props.onClick) props.onClick(props.object);
		}
	};

	const EditButton = () => {
		return (
			<button
				className="edit-icon z-50 "
				onClick={() => {
					if (props.type === "editable_event") {
						navigateToManageEventsPage(props.object.id);
					} else {
						navigateToManageAdsPage(
							props.object.id,
							location.pathname === "/club-home"
						);
					}
				}}
			>
				<EditIcon />
			</button>
		);
	};

	return (
		<div
			className={
				"w-[270px] h-[142px] bg-white rounded-sm shadow-md cursor-hover higher-hover-opacity relative"
			}
		>
			{isAd(props.object) &&
				props.object.expiry_date &&
				isExpired(props.object.expiry_date) && (
					<div>
						<div className="absolute flex w-full h-full items-center justify-center z-50">
							<p className="text-3xl text-red-500">Utgången</p>
						</div>
						<div className="absolute bg-black opacity-50 w-full h-full z-20"></div>
					</div>
				)}
			{(props.type === "editable_ad" ||
				props.type === "editable_event") && (
				<div className="flex justify-start">
					<EditButton />
				</div>
			)}
			{isAd(props.object) && props.object.isForCompanies && (
				<div className="flex justify-center">
					<SponsorContainer />
				</div>
			)}

			{isAd(props.object) && props.object.category && (
				<div className="flex justify-end">
					<CategoryContainer text={props.object.category} />
				</div>
			)}
			<div
				className={"flex flex-col h-full justify-evenly items-center"}
				onClick={() => {
					cardAction();
				}}
			>
				<div>
					<img
						className={
							"px-4 object-scale-down h-[90px] max-w-[250px] " +
							(props.type !== "company" ? "pt-5 " : "mt-2 ") +
							(isCompany(props.object) && props.object.whiteLogo
								? "p-[10px] bg-slate-700 rounded"
								: "")
						}
						src={props.object.image_url}
						alt={
							(isCompany(props.object) || isClub(props.object)) &&
							props.object.name
								? props.object.name
								: ""
						}
					/>
				</div>
				{isAd(props.object) && (
					<p className={"font-normal text-center text-black"}>
						{props.object.title}
					</p>
				)}
				{props.type === "club" && isClub(props.object) && (
					<p className={" font-normal text-black"}>
						{props.object.name}
					</p>
				)}
			</div>
		</div>
	);
};
