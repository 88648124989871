export const REGIONS = [
	"Stockholm",
	"Uppsala",
	"Sörmland",
	"Östergötland",
	"Jönköpings län",
	"Kronoberg",
	"Kalmar län",
	"Gotland",
	"Blekinge",
	"Skåne",
	"Halland",
	"Västra Götalandsregionen",
	"Värmland",
	"Örebro län",
	"Västmanland",
	"Dalarna",
	"Gävleborg",
	"Västernorrland",
	"Jämtland Härjedalen",
	"Västerbotten",
	"Norrbotten",
];
