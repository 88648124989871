import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../components/InputField";
import { Nav } from "../components/Nav";
import { StandardButton } from "../components/StandardButton";
import { useAuth } from "../hooks/useAuth";
import { useErrorMessages } from "../hooks/useErrorMessages";
import { useFirestore } from "../hooks/useFirestore";
import { Pages, usePageNavigation } from "../hooks/usePageNavigation";
import {
	getAuth,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
} from "firebase/auth";

export const UpdatePasswordPage = () => {
	const { navigateToMainPage } = usePageNavigation();

	const auth = getAuth();
	const user = auth.currentUser;

	const { getErrorMessageLogin } = useErrorMessages();
	const [error, setError] = useState("");

	const firestore = useFirestore();

	useEffect(() => { }, []);

	const handleOnLoginClick = (e: any) => {
		e.preventDefault();

		const currentPassword = e.target.current_password.value;
		const newPassword = e.target.new_password.value;
		const confirmNewPassword = e.target.confirm_new_password.value;

		if (!currentPassword || !newPassword || !confirmNewPassword) {
			setError("Fyll i alla fält");
			return;
		}
		if (newPassword.length < 6) {
			setError("Lösenordet måste vara minst 6 tecken");
			return;
		} else if (newPassword !== confirmNewPassword) {
			setError("De nya lösenorden matchar inte");
			return;
		}

		if (user && user.email) {
			const credential = EmailAuthProvider.credential(
				user.email,
				currentPassword
			);
			reauthenticateWithCredential(user, credential)
				.then(() => {
					updatePassword(user, newPassword).then(() => {
						alert("Lösenordet uppdaterades");
						navigateToMainPage();
					});
				})
				.catch((error: FirebaseError) => {
					setError(getErrorMessageLogin(error.code));
				});
		}
	};

	return (
		<div>
			<Nav />
			<div style={{ width: "100%" }} className="flex justify-center">
				<form
					onSubmit={handleOnLoginClick}
					className="flex flex-col align-middle m-5 p-10 login-form"
				>
					<p
						style={{ marginBottom: "20px" }}
						className="flex font-bold text-2xl"
					>
						Byt lösenord
					</p>

					<div style={{ marginBottom: "15px" }}>
						<InputField
							placeholderText="Nuvarande lösenord"
							type="password"
							name="current_password"
						/>
					</div>
					<div style={{ marginBottom: "15px" }}>
						<InputField
							placeholderText="Nytt lösenord"
							type="password"
							name="new_password"
						/>
					</div>
					<div>
						<InputField
							placeholderText="Bekräfta nytt lösenord"
							type="password"
							name="confirm_new_password"
						/>
					</div>
					{error && (
						<div
							className="error-box px-5 py-2"
							style={{ marginTop: "10px" }}
						>
							<p className="color-primary-red">{error}</p>
						</div>
					)}
					<div
						style={{ marginBottom: "10px", marginTop: "30px" }}
					></div>
					<StandardButton text="Byt lösenord" />
				</form>
			</div>
		</div>
	);
};
