import { useState } from "react";
import { InputField } from "../components/InputField";
import { StandardButton } from "../components/StandardButton";
import { useFirestore } from "../hooks/useFirestore";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { usePageNavigation } from "../hooks/usePageNavigation";

interface Props {
	company_id: string;
}
export const CreateUserForm = (props: Props) => {
	const { createUserManually } = useFirestore();
	const { navigateToPolicyPage } = usePageNavigation();

	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (e: any) => {
		setError("");
		e.preventDefault();
		const email = e.target.email.value;
		const password = e.target.password.value;
		const confirmPassword = e.target.confirmPassword.value;
		const firstName = e.target.firstName.value;
		const lastName = e.target.lastName.value;
		const acceptTerms = e.target.acceptTerms.checked;
		setIsLoading(true);
		if (password.length < 6) {
			setError("Lösenordet måste vara minst 6 tecken");
			setIsLoading(false);
			return;
		} else if (password !== confirmPassword) {
			setError("Lösenorden matchar inte");
			setIsLoading(false);
			return;
		}
		if (!email || !firstName || !lastName) {
			setError("Var vänlig fyll i samtliga fält");
			setIsLoading(false);
			return;
		} else if (!acceptTerms) {
			setError("Acceptera användarvillkoren för att använda tjänsten");
			setIsLoading(false);
			return;
		}

		createUserManually(
			email,
			password,
			props.company_id,
			firstName,
			lastName
		)
			.then(() => {
				setOpen(false);
				alert("Skapade användare");
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div>
			<StandardButton
				text="Skapa konto manuellt"
				onClick={handleClickOpen}
			/>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Skapa användare</DialogTitle>
				<DialogContent>
					<form
						onSubmit={handleSubmit}
						className="flex flex-col align-center items-center m-5 p-10 login-form h-fit"
					>
						<div style={{ marginBottom: "10px" }}>
							<InputField
								placeholderText="Email"
								type="email"
								name="email"
								noAutoComplete
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<InputField
								placeholderText="Förnamn"
								type="text"
								name="firstName"
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<InputField
								placeholderText="Efternamn"
								type="text"
								name="lastName"
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<InputField
								placeholderText="Lösenord"
								type="text"
								name="password"
								noAutoComplete
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<InputField
								placeholderText="Bekräfta lösenord"
								type="password"
								name="confirmPassword"
							/>
						</div>
						<div style={{ marginBottom: "20px" }}>
							<input
								type="checkbox"
								style={{ marginRight: "10px", padding: "20px" }}
								name="acceptTerms"
							/>
							<span>
								Jag accepterar{" "}
								<span className="text-[#1e9ede]">
									<a
										href="https://parter.se/privacy_policy"
										target="_blank"
									>
										användarvillkoren
									</a>
								</span>
							</span>
						</div>

						{error && <p className="color-primary-red">{error}</p>}
						{isLoading ? (
							<div>
								<CircularProgress />
							</div>
						) : (
							<StandardButton text="Skapa konto" />
						)}
					</form>
				</DialogContent>
			</Dialog>
		</div>
	);
};
