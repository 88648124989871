import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Nav } from "../components/Nav";
import { AdForm } from "../components/AdForm";
import { DBUser, Notification, PostAd } from "../DBTypes";
import { useFirestore } from "../hooks/useFirestore";
import { useAuth } from "../hooks/useAuth";
import { Company, Ad, ClubOfferInstruction } from "../DBTypes";
import { usePageNavigation } from "../hooks/usePageNavigation";
import { httpsCallable } from "firebase/functions";
import { functions } from "../Firebase";

const titles: string[] = ["Privat", "Företag"];

interface Club {
	name: string;
	id: string;
}

/**
 * Page for company to add or edit ads.
 */
export const ManageAdsPage = () => {
	/**
	 * Input fields variables
	 */
	const [imageFile, setImageFile] = useState<File>();
	const [clubIds, setClubIds] = useState<string[]>();
	const [clubNames, setClubNames] = useState<string[]>();
	const [whiteLogo, setWhiteLogo] = useState<boolean>(false);

	const [category, setCategory] = useState<string>();
	const [adTitle, setAdTitle] = useState<string>();
	const [description, setDescription] = useState<string>();
	const [contactName, setContactName] = useState<string>();
	const [contactPhone, setContactPhone] = useState<string>();
	const [contactEmail, setContactEmail] = useState<string>();
	const [date, setDate] = useState<Date | null>(null);
	const [clubOfferInstructions, setClubOfferInstructions] = useState<
		ClubOfferInstruction[]
	>([]);
	const [clubAd, setClubAd] = useState<boolean>(false);

	const [selectedTitle, setSelectedTitle] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [oldImageURL, setOldImageURL] = useState<string>();
	const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

	const [company, setCompany] = useState<Company>();
	const [club, setClub] = useState<Club>();
	const [ad, setAd] = useState<Ad>();
	const [clubs, setClubs] = useState<Club[]>([]);

	const {
		getCompanies,
		getClubs,
		uploadAd,
		getAdFromID,
		updateAd,
		getCompaniesFromClub,
		getUsersByCompanyId,
		getAdsFromCompany,
	} = useFirestore();

	const { navigateToCompanyHomePage, navigateToClubHomePage } =
		usePageNavigation();

	let auth = useAuth();
	const user = auth.user;

	const { ad_id } = useParams<string>();
	const location: any = useLocation();

	let club_admin = false;
	if (location.state && location.state.club_admin) {
		club_admin = location.state.club_admin;
	}

	/**
	 * Get user's company and if trying to edit ad, get the ad object
	 */
	useEffect(() => {
		if (user) {
			if (club_admin && user.firestoreUser.club_id) {
				getClubs([user.firestoreUser.club_id])
					.then((resp) => {
						setClub(resp[0]);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				getCompanies([user.firestoreUser.company_id])
					.then((resp) => {
						setCompany(resp[0]);
					})
					.catch((error) => {
						setErrorMessage(error);
					});
			}
		}
		if (ad_id && user) {
			getAdFromID(ad_id)
				.then((resp) => {
					if (
						(user.firestoreUser.club_id &&
							resp.club_ids.length > 1) ||
						(user.firestoreUser.club_id &&
							!resp.club_ids.includes(user.firestoreUser.club_id))
					) {
						navigateToClubHomePage();
					}
					if (
						resp.company_id === user.firestoreUser.company_id ||
						resp.company_id === user.firestoreUser.club_id
					) {
						if (resp.isForCompanies) {
							setSelectedTitle(1);
						}
						setOldImageURL(resp.image_url);
						setAd(resp);
						if (resp.whiteLogo) {
							setWhiteLogo(resp.whiteLogo);
						}
						if (resp.expiry_date) {
							setDate(resp.expiry_date);
						}
					} else {
						goToHomePage();
					}
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		}
	}, []);

	/**
	 * Display alert if successfull and navigate to homepage
	 */
	useEffect(() => {
		if (uploadSuccess) {
			if (ad_id) {
				alert("Erbjudandet har uppdaterats");
			} else {
				alert("Erbjudandet har publicerats");
			}
			goToHomePage();
		}
	}, [uploadSuccess]);

	useEffect(() => {
		if (club) {
			setClubs([club]);
			setClubIds([club.id]);
			setClubNames([club.name]);
			setClubAd(true);
		}
	}, [club]);

	/**
	 * get the clubs connected to the company for the club dropdown menu
	 */
	useEffect(() => {
		if (company && user) {
			if (user.firestoreUser.club_id) {
				getClubs([user.firestoreUser.club_id])
					.then((resp) => {
						setClubs(resp);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				getClubs(company.relatedClubs)
					.then((resp) => {
						let _clubs: Club[] = [];

						resp.forEach((club) => {
							_clubs.push({ name: club.name, id: club.id });
						});

						setClubs(_clubs);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		}
	}, [company]);

	const fetchUsers = async (club_ids: string[]) => {
		try {
			let allUsers: DBUser[] = [];
			let companyIds: string[] = [];
			await Promise.all(
				club_ids.map(async (id) => {
					const resp = await getCompaniesFromClub(id);
					resp.forEach((company) => companyIds.push(company.id));
				})
			).then(async () => {
				await Promise.all(
					companyIds.map(async (cid) => {
						const resp = await getUsersByCompanyId(cid);
						resp.forEach((user) => allUsers.push(user));
					})
				);
			});
			return allUsers;
		} catch (err) {
			console.log(err);
		}
	};

	const goToHomePage = () => {
		if (clubAd) {
			navigateToClubHomePage();
		} else {
			navigateToCompanyHomePage();
		}
	};

	const validInput = () => {
		if (!clubIds || !category || !adTitle || !description) {
			return false;
		} else {
			if (clubIds.length < 1) {
				return false;
			} else {
				return true;
			}
		}
	};
	const fetchCompanyAdNames = async (companyId: string) => {
		try {
			let allAds: string[] = [];
			const resp = await getAdsFromCompany(companyId);
			resp.forEach((ad) => allAds.push(ad.title));
			return allAds;
		} catch (err) {
			console.log(err);
		}
	};

	const publishNewAd = async () => {
		if (company && adTitle) {
			const adNames = await fetchCompanyAdNames(company.id);
			if (adNames && adNames.includes(adTitle)) {
				alert("Ditt företag har redan ett erbjudande med detta namn");
				return;
			}
		}

		if (!imageFile || !validInput()) {
			setError(true);
		} else if (
			selectedTitle === 1 &&
			(!contactName || !contactPhone || !contactEmail)
		) {
			setError(true);
		} else {
			if (pageNumber === 1) {
				setPageNumber(2);
				setError(false);
			} else if (pageNumber === 2) {
				let id = "";
				let name = "";
				if (company) {
					id = company.id;
					name = company.name;
				} else if (club) {
					id = club.id;
					name = club.name;
				}
				if (user) {
					setIsLoading(true);
					setErrorMessage("");
					setUploadSuccess(false);
					const newAd: PostAd = {
						category: category || "",
						club_ids: clubIds || [""],
						club_names: clubNames || [""],
						company_id: id,
						company_name: name,
						isForCompanies: selectedTitle === 0 ? false : true,
						description: description || "",
						image: imageFile,
						whiteLogo: whiteLogo,
						clubOfferInstructions: clubOfferInstructions || [],
						title: adTitle || "",
						contactPerson: contactName,
						phoneNumber: contactPhone,
						email: contactEmail,
						expiry_date: date !== null ? date : undefined,
					};

					uploadAd(newAd)
						.then(async () => {
							if (clubIds && selectedTitle === 1) {
								const allUsers = await fetchUsers(clubIds);
								const _filteredUsers = allUsers?.filter(
									(user) => user.company_id !== id
								);

								const filteredUsers = _filteredUsers?.map(u => u.id);

								const notis: Notification = {
									ad_title: adTitle,
									ad_company: name,
									ad_clubs: clubNames,
									read: false,
									isUpdate: false,
									type: "ad",
								};
								const createNotifications = httpsCallable(
									functions,
									"createNotifications"
								);
								createNotifications({
									users: filteredUsers,
									ad: notis,
								});
							}
							setUploadSuccess(true);
						})
						.catch((error) => {
							alert(error);
							goToHomePage();
						})
						.finally(() => {
							setIsLoading(false);
						});
				}
			}
		}
	};

	const patchAd = () => {
		if (!validInput()) {
			setError(true);
		} else if (
			selectedTitle === 1 &&
			(!contactName || !contactPhone || !contactEmail)
		) {
			setError(true);
		} else {
			if (pageNumber === 1) {
				setPageNumber(2);
				setError(false);
			} else if (pageNumber === 2) {
				let name = "";
				if (company) {
					name = company.name;
				} else if (club) {
					name = club.name;
				}
				if (user && ad_id) {
					setIsLoading(true);
					setErrorMessage("");
					setUploadSuccess(false);

					updateAd(
						ad_id,
						name,
						category,
						clubIds,
						clubNames,
						description,
						imageFile,
						oldImageURL ? oldImageURL : undefined,
						whiteLogo,
						clubOfferInstructions,
						adTitle,
						contactName,
						contactPhone,
						contactEmail,
						date || date === null ? date : undefined
					)
						.then(async (image_url) => {
							if (clubIds && selectedTitle === 1) {
								const allUsers = await fetchUsers(clubIds);

								const _filteredUsers = allUsers?.filter(
									(user) => user.company_id !== company?.id
								);

								const filteredUsers = _filteredUsers?.map(u => u.id);

								const notis: Notification = {
									ad_title: adTitle,
									ad_company: name,
									ad_clubs: clubNames,
									read: false,
									isUpdate: true,
									type: "ad",
								};
								const createNotifications = httpsCallable(
									functions,
									"createNotifications"
								);
								createNotifications({
									users: filteredUsers,
									ad: notis,
								});
							}
							if (image_url) {
								setOldImageURL(image_url);
								setImageFile(undefined);
							}
							setUploadSuccess(true);
						})
						.catch((error) => {
							alert(error);
							goToHomePage();
						})
						.finally(() => {
							setIsLoading(false);
						});
				}
			}
		}
	};

	const handlePublishClick = () => {
		if (ad_id) {
			patchAd();
		} else {
			publishNewAd();
		}
	};

	// Return AdForm component
	return (
		<div className="flex flex-col h-screen">
			<Nav />
			<AdForm
				editAd={ad}
				selectedTitle={selectedTitle}
				setSelectedTitle={setSelectedTitle}
				pageNumber={pageNumber}
				error={error}
				setError={setError}
				titles={titles}
				setImageFile={setImageFile}
				whiteLogo={whiteLogo}
				setWhiteLogo={setWhiteLogo}
				clubs={clubs}
				clubIds={clubIds}
				setClubIds={setClubIds}
				setClubNames={setClubNames}
				setCategory={setCategory}
				setAdTitle={setAdTitle}
				setDescription={setDescription}
				setContactName={setContactName}
				setContactPhone={setContactPhone}
				setContactEmail={setContactEmail}
				setClubOfferInstructions={setClubOfferInstructions}
				isLoading={isLoading}
				handlePublishClick={handlePublishClick}
				clubAd={clubAd}
				date={date}
				setDate={setDate}
			/>
		</div>
	);
};
