import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useAuth } from "../hooks/useAuth";

import { Company } from "../DBTypes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

interface Props {
	open: boolean;
	handleClose: any;
	object: Company;
}

/**
 * Dialog based on MUI. Takes an object with Ad data and displays it when set to open in parent.
 *
 */

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const ShowContactInfo = (company: Company) => {
	if (company.contactName || company.contactPhone || company.contactEmail) {
		return (
			<div className="pb-5 ">
				<div className="flex flex-col">
					<Typography
						gutterBottom
						sx={{
							fontSize: "18px",
							fontWeight: "bold",
							fontFamily: "RedHatText",
						}}
					>
						Kontakt för sponsorer
					</Typography>
					<Typography
						fontSize="16px"
						sx={{
							fontFamily: "RedHatText",
						}}
					>
						{company.contactName}
					</Typography>
					<Typography
						fontSize="16px"
						sx={{
							fontFamily: "RedHatText",
						}}
					>
						{company.contactPhone}
					</Typography>
					<Typography
						fontSize="16px"
						sx={{
							fontFamily: "RedHatText",
						}}
					>
						{company.contactEmail}
					</Typography>
				</div>
			</div>
		);
	}
};
export default function CustomizedDialogs(props: Props) {
	const { image_url, name, description, address, phoneNumber, website_url } =
		props.object;

	let auth = useAuth();
	const user = auth.user;

	return (
		<div>
			<BootstrapDialog
				onClose={props.handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
			>
				<div className="min-w-[30vw]">
					<BootstrapDialogTitle
						id="customized-dialog-title"
						onClose={props.handleClose}
					></BootstrapDialogTitle>

					<DialogContent>
						<div className="px-[5%] sm:px-[10%] flex flex-col">
							<img
								className={
									"my-8 object-scale-down max-h-[150px] min-h-[75px] " +
									(props.object.whiteLogo
										? "p-[8px] bg-slate-700 rounded"
										: "")
								}
								src={image_url}
								alt={props.object.name ? props.object.name : ""}
							/>
							<Typography
								gutterBottom
								sx={{
									fontSize: "1.2rem",
									fontWeight: "bold",
									fontFamily: "RedHatText",
								}}
							>
								{name}
							</Typography>
							<Typography
								gutterBottom
								sx={{ 
									fontFamily: "RedHatText",
									whiteSpace: "pre-wrap",  
								}}
							>
								{description}
							</Typography>

							<div className="py-5 ">
								<div className="flex flex-col">
									<Typography
										fontSize="16px"
										sx={{
											fontFamily: "RedHatText",
										}}
									>
										{address}
									</Typography>
									<Typography
										fontSize="16px"
										sx={{ fontFamily: "RedHatText" }}
									>
										{phoneNumber}
									</Typography>
									<Typography
										fontSize="16px"
										sx={{ fontFamily: "RedHatText" }}
									>
										<a href={website_url}>{website_url}</a>
									</Typography>
								</div>
							</div>

							{user && ShowContactInfo(props.object)}
						</div>
					</DialogContent>
				</div>
			</BootstrapDialog>
		</div>
	);
}
