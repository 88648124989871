import * as React from "react";
import { Nav } from "../components/Nav";
import { BannerAd } from "../components/BannerAd";
import videoBg from "../videos/About_Parter.mp4";
import { Link, useNavigate } from "react-router-dom";
import { usePageNavigation } from "../hooks/usePageNavigation";

export const PrivacyPolicy = () => {
	const { navigateToPolicyPage } = usePageNavigation();
	return (
		<div>
			<div
				className={
					"w-[100%] h-[100%] lg:px-0 pt-[2vw] pb-[8vw] flex flex-col items-center"
				}
			>
				<div className="w-[80vw] lg:w-[50vw] flex flex-col gap-[16px]">
					<b className="text-2xl">
						Information om behandling av personuppgifter | Parter.se
					</b>
					<b className="text-xl">
						Ansvarig organisation och kontaktuppgifter
					</b>
					<p className="text-lg">
						Parter.se (“Parter”, “vi”, “oss”) är
						personuppgiftsansvarig för de personuppgifter som
						behandlas inom ramen för vår verksamhet. Vi bryr oss om
						våra användare och er integritet och förbinder oss att
						respektera och skydda dina personuppgifter i enlighet
						med gällande lagar och branschstandarder. För att hjälpa
						dig att förstå hur vi samlar in och använder information
						om dig har vi sammanställt denna information. Du är
						alltid välkommen att kontakta oss med frågor,
						funderingar eller förfrågningar som rör hur vi behandlar
						dina personuppgifter.
					</p>
					<p className="text-lg">
						<b>Kontaktuppgifter:</b> <br />
						Parter AB <br />
						Nordgårdsgatan 2412 85
						<br />
						Göteborg <br />
						hej@parter.se <br />
						0704929923
					</p>

					<b className="text-xl">Information som vi samlar in</b>
					<p className="text-lg">
						Det är viktigt för oss att du känner till vilken
						information vi samlar in om dig och hur vi samlar in
						den. Vi kan komma att samla in personuppgifter på
						följande sätt: <br /> <br />
						Du kan direkt eller indirekt lämna information om dig
						själv till oss i samband med att du använder våra
						produkter och tjänster samt vår webbplats
						https://parter.se/. <br /> <br />
						Information som vi kan komma att samla in i dessa
						sammanhang inkluderar: ditt namn, företagsnamn, dina
						kontaktuppgifter, information som behövs för fakturering
						(om det är relevant) och eventuella ytterligare
						uppgifter som du lämnar till oss. Vi samlar också in
						information i samband med eventuella betalningar och
						transaktioner, såsom betalningsmetod, kredit- och
						betalkortsuppgifter (kortnummer, giltighetsdatum och
						CVV-kod), fakturainformation, bankkontonummer etc.,
						beroende på vad som är relevant. <br /> <br />
						När du använder våra tjänster kan det innebära att vi, i
						tillägg till den information som du lämnar själv enligt
						ovan, också samlar in följande information om dig och
						dina aktiviteter:
					</p>

					<li>
						Information om vilka produkter/tjänster du beställer
						från oss.
					</li>
					<li>
						Information om din dator/mobila enhet: exempelvis
						IP-adress, enhetsidentifierare, val av webbläsare,
						språkval, vilken server din enhet är inloggad genom samt
						information om operativsystemet.
					</li>
					<li>Information om din geografiska plats.</li>
					<li>
						Information om hur du interagerar med oss och använder
						våra tjänster. Det kan till exempel vara information om
						svarstider på olika sidor, vilket innehåll och vilka
						sidor vi har visat för dig, om du visat intresse för
						något särskilt innehåll genom att exempelvis ladda ner
						material etc. All denna information samlar vi in för att
						lära oss mer om vilken information som är intressant för
						dig och för att förbättra våra produkter och tjänster.
					</li>
					<b className="text-2xl">
						Syfte och rättslig grund med personuppgiftsbehandlingen
					</b>
					<p className="text-lg">
						Behandlingen av dina personuppgifter är nödvändig för
						att kunna använda vår tjänst och ta del av, använda och
						hålla dig uppdaterad kring vår digitala samlingsplats
						för föreningar, medlemmar och sponsorer. De
						intresseavvägningar vi gjort finns alltid dokumenterade
						så att vi kan visa hur vi förhåller oss till och tar
						hänsyn till dina intressen, rättigheter och friheter som
						individ.
						<br /> <br /> Den information du lämnar till oss, liksom
						information om vilka produkter och tjänster du använder
						och köper samt fakturerings- och betalningsinformation
						(när det är relevant) krävs normalt sett för att ingå
						avtal med oss. <br /> <br />
						Utöver ovanstående information kan vi också komma att be
						om en del annan information som kan hjälpa oss att göra
						ditt besök på vår webbplats bättre och vår kommunikation
						så effektiv som möjligt. Det kan till exempel vara
						information som rör dina personliga eller professionella
						intressen/preferenser, var du bor och så vidare. Sådan
						kompletterande information kommer vi endast att samla in
						i den utsträckning du ger oss informationen, dvs med
						ditt samtycke. <br /> <br />
						De personuppgifter vi samlar in använder vi på följande
						sätt:
					</p>
					<li>För att registrera dig som kund eller användare.</li>
					<li>
						För att delge dig information, produkter eller tjänster
						som du efterfrågar och beställer, och möjliggöra
						fakturering.
					</li>
					<li>
						För marknadsföringsändamål. Detta kan vara aktiviteter
						kring marknadsundersökningar eller olika kampanjer i
						form av direktmarknadsföring. Du kan närsomhelst
						kontakta oss och begära att dina uppgifter inte längre
						ska användas för direktmarknadsföring. Gör detta genom
						att klicka på avregistreringslänken i våra e-postutskick
						eller kontakta oss direkt via e-post på hej@parter.se.
						Observera att dina kreditkortsupplysningar endast
						används för att ta betalt för produkter som du har köpt
						från oss. Dessa uppgifter sparas inte för några andra
						syften.
					</li>
					<li>
						För att kommunicera med dig, inklusive att erbjuda
						kundservice, skicka servicemeddelanden såsom
						aviseringar, bekräftelser och uppdateringar samt att
						skicka kampanjinformation om nya produkter eller
						tjänster som vi tror kan intressera dig. Vi hämtar
						alltid in ditt samtycke innan vi skickar ut reklam om
						det krävs enligt lag. Du kan, som sagt, närsomhelst
						tacka nej och välja bort att ta emot reklam.
					</li>
					<p className="text-lg">
						Vi tar din integritet på allvar. När du anmäler dig till
						nyhetsutskick eller andra uppdateringar från oss, kan du
						vara säker på att vi inte delar din e-postadress med
						externa aktörer eller någon annan utanför Parter.se. Du
						kan närsomhelst frånsäga dig våra e-postutskick genom
						att klicka på avregistreringslänken i något av
						utskicken.
					</p>
					<b className="text-xl">
						Hur vi delar personuppgifter vidare
					</b>
					<p className="text-lg">
						Parter.se kommer inte att, utan ditt samtycke, dela
						vidare information om dig till tredje part såvida det
						inte är förskrivet i lag att vi måste göra detta eller
						det är direkt nödvändigt för att kunna leverera eller
						uppfylla en produkt eller tjänst som du har bett om
						eller beställt från oss.
					</p>
					<b className="text-lg">Överföring till tredjeland</b>
					<p className="text-lg">
						Vi ser till att alltid behandla dina personuppgifter
						inom EU/EES. Om det skulle uppstå en situation där vi
						eller någon av våra leverantörer eller underleverantörer
						behöver överföra personuppgifter till ett land utanför
						EU/EES, så lovar vi att vi vidtar alla rimliga
						juridiska, tekniska och organisatoriska åtgärder för att
						säkerställa att din personliga information behandlas på
						ett säkert sätt och med en adekvat skyddsnivå som ligger
						åtminstone i linje med vad som gäller inom EU/EES.
						<br /> <br /> Vi kommer enbart att överföra dina
						personuppgifter till ett land, ett område eller en
						sektor i tredjeland om EU-kommissionen har beslutat att
						det har en tillräcklig skyddsnivå.
					</p>
					<b className="text-xl">Gallring av personuppgifter</b>
					<p className="text-lg">
						Vi behåller dina personuppgifter så länge du är medlem
						på vår sida och använder våra tjänster. Detta betyder
						att vi raderar din användare och därmed dina uppgifter i
						våra system när du som användare inte längre använder
						våra tjänster eller när personuppgifterna inte längre
						behövs för de syften som uppgifterna samlades in för. Om
						vi behåller dina personuppgifter för andra syften som
						inte direkt kan kopplas till användandet av våra
						tjänster, så sparar vi aldrig uppgifterna under längre
						tid än vad som är direkt nödvändigt med tanke på syftet
						eller vad som krävs enligt lag. <br /> <br />
						Parter.se skyddar dina personuppgifter genom tekniska
						såväl som organisatoriska säkerhetsåtgärder. För att
						förhindra att obehöriga får tillgång till uppgifterna
						och säkerställa korrekta uppgifter hos oss, så använder
						vi säkra servrar och har sekretessavtal med våra
						leverantörer.
					</p>
					<b className="text-xl">Dina rättigheter</b>
					<b className="text-lg">Rätt till registerutdrag</b>
					<p className="text-lg">
						Du har rätt att få information från oss om huruvida vi
						behandlar dina personuppgifter, och om så är fallet har
						du även rätt att få information om:
					</p>
					<li>syftet med att vi behandlar dina personuppgifter</li>
					<li>vilka kategorier av personuppgifter vi behandlar</li>
					<li>
						vilka personuppgifter vi lämnar ut till tredjepart
						(mottagare eller kategorier av mottagare) om det är
						aktuellt
					</li>
					<li>
						hur länge dina personuppgifter sparas och vilka
						gallringskriterier vi utgår ifrån
					</li>
					<li>
						dina rättigheter (rätten till radering, rättelse och
						begränsning av dina uppgifter, rätten till
						dataportabilitet, rätten att invända mot behandling och
						rätten att klaga till tillsynsmyndigheten).
					</li>
					<li>
						från vilka källor vi hämtar dina personuppgifter (i de
						fall vi hämtar dem någon annanstans ifrån än direkt från
						dig)
					</li>
					<li>
						ifall dina personuppgifter används för automatiskt
						beslutsfattande och vilka konsekvenser detta kan få för
						dig
					</li>
					<p className="text-lg">
						Om du vill begära ut information om dina personuppgifter
						och/eller få ett samlat registerutdrag med de uppgifter
						som vi har registrerat om dig, kontakta oss på mail via
						hej@parter.se. Din begäran kommer att behandlas så snart
						som möjligt, senast inom 30 dagar. Det kostar ingenting
						att begära och få ut ett registerutdrag. Om du däremot
						vill begära ut flera kopior förbehåller vi oss rätten
						att ta ut en rimlig administrationsavgift. När du
						skickar in din begäran elektroniskt, kommer du också att
						få registerutdraget tillbaka i digital form. Detta sker
						via hej@parter.se
					</p>
					<b className="text-lg">Rätten till rättelse</b>
					<p className="text-lg">
						Vi är måna om att all personlig information som finns
						lagrad hos oss är korrekt. Om du skulle upptäcka att vi
						trots detta har ofullständiga eller felaktiga
						personuppgifter om dig i våra register ber vi att du
						meddelar oss detta så vi har möjlighet att korrigera,
						komplettera eller ta bort information.
					</p>
					<b className="text-lg">Rätten till radering</b>
					<p className="text-lg">
						Du har rätt att begära att vi raderar dina
						personuppgifter under vissa förutsättningar:
					</p>
					<li>
						När dina personuppgifter inte längre behövs för de(t)
						ändamål som uppgifterna samlades in för
					</li>
					<li>
						Om du tagit tillbaka ditt samtycke, dvs du inte längre
						samtycker till behandlingen (förutsatt att behandlingen
						grundar sig på ditt samtycke som enda rättsliga grund)
					</li>
					<li>Om uppgifterna används för marknadsföringsändamål</li>
					<li>
						När din rätt till personlig integritet väger tyngre än
						vårt berättigade intresse av att fortsätta behandla
						personuppgifter om dig (förutsatt att behandlingen
						grundar sig på berättigat intresse som rättslig grund)
					</li>
					<li>
						Om vi har behandlat dina personuppgifter på ett sätt som
						är oförenligt med gällande lagstiftning
					</li>
					<li>
						Om personuppgifterna har använts för att erbjuda så
						kallade informationssamhällets tjänster till ett barn
					</li>
					<p className="text-lg">
						Vi kommer alltid att gallra och radera personuppgifter
						när det krävs enligt lag. Se även i avsnittet länge upp
						om gallring av personuppgifter. <br /> <br />
						Om vi inte kan tillmötesgå din begäran om att bli
						raderad, kommer vi att meddela dig detta beslut och
						informera om varför. Om du ändå vill gå vidare och
						försöka få dina uppgifter raderade kvarstår möjligheten
						för dig att lämna in klagomål till
						Integritetsskyddsmyndigheten eller gå vidare med ditt
						ärende till domstol.
					</p>
					<b className="text-lg">Rätten till begränsning</b>
					<p className="text-lg">
						Du har rätt att få behandling av dina personuppgifter
						begränsad under vissa omständigheter:
					</p>
					<li>
						Under en period medan vi kontrollerar att uppgifterna är
						korrekta efter att du har invänt mot behandlingen eller
						ifrågasatt uppgifternas korrekthet.
					</li>
					<li>
						Om behandlingen är olaglig, och du trots detta har
						motsatt dig att uppgifterna raderas och istället begärt
						att användningen av dem ska begränsas
					</li>
					<li>
						När vi inte längre behöver dina personuppgifter för det
						ursprungliga syftet, men de behöver finnas kvar för att
						du ska kunna fastställa, göra gällande eller försvara
						ett rättsligt anspråk.
					</li>
					<p className="text-lg">
						Om vi begränsar behandlingen av dina personuppgifter
						enligt ovan kommer vi fortfarande lagra uppgifterna men
						inte använda den annat än (i) med ditt samtycke, (ii)
						för att fastställa, göra gällande eller försvara
						rättsliga anspråk, (iii) för att skydda någon annan
						fysisk eller juridisk persons rättigheter eller (iv) för
						skäl som rör ett viktigt allmänintresse.
					</p>
					<b className="text-lg">Rätten till dataportabilitet</b>
					<p className="text-lg">
						När vi behandlar dina personuppgifter på ett
						automatiserat sätt, antingen med ditt samtycke som
						rättslig grund eller för att uppfylla ett avtal, så har
						du rätt att själv få ut de personuppgifter som du har
						lämnat till oss i ett strukturerat, allmänt använt och
						maskinläsbart format - så kallad dataportabilitet.
					</p>
					<b className="text-lg">Rätten att invända</b>
					<p className="text-lg">
						Om du invänder mot behandlingen av dina personuppgifter,
						inklusive eventuell profilering, där ändamålet är [Ange
						allmänt intresse/berättigat intresse] kommer vi att
						upphöra med behandlingen om vi inte kan visa på en
						faktisk berättigad anledning till att fortsätta. Vi kan
						också komma att fortsätta behandla dina personuppgifter
						om det är nödvändigt för att fastställa, göra gällande
						eller försvara rättsliga anspråk. <br /> <br />
						Om syftet med behandlingen är direktmarknadsföring
						kommer vi omedelbart att upphöra med den behandlingen om
						du begär detta.
					</p>
					<b className="text-xl">Frågor och klagomål</b>
					<p className="text-lg">
						Vi hoppas att den här informationen är användbar för dig
						och bidrar med förtydliganden när det gäller varför och
						hur vi behandlar dina personuppgifter. Om du har frågor
						om hur vi behandlar dina personuppgifter, tveka inte att
						ta kontakt med oss: hej@parter.se . Du har också rätt
						att lämna ett klagomål till tillsynsmyndigheten när det
						gäller hur vi behandlar dina uppgifter.
						<br /> <br /> Denna informationstext uppdaterades senast
						20230414.
					</p>
				</div>
			</div>
		</div>
	);
};
