import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { DBUser } from "../DBTypes";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { StandardButton } from "./StandardButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
import { useFirestore } from "../hooks/useFirestore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { confirmAlert } from "react-confirm-alert"; // Import

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

interface Props {
	companyId: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	superAdmin?: boolean;
}

export default function UsersList(props: Props) {
	const { getUsersFromCompany, deleteUser } = useFirestore();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [users, setUsers] = useState<DBUser[]>([]);

	useEffect(() => {
		if (props.open) {
			setIsLoading(true);
			getUsersFromCompany(props.companyId)
				.then((res) => {
					setUsers(res);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [props.open]);

	const onDeleteUserClick = (user_id: string) => {
		confirmAlert({
			message:
				"Är du säker på att du vill ta bort användaren? Detta går inte att ångra",
			buttons: [
				{
					label: "Avbryt",
				},
				{
					label: "Fortsätt",
					onClick: () => onDeleteUser(user_id),
				},
			],
		});
	};

	const onDeleteUser = (user_id: string) => {
		deleteUser(user_id)
			.then(() => {
				alert("Tog bort användare.");
			})
			.catch((error) => {
				alert(error);
			});
	};

	function generate() {
		const confirmedUsers = users.filter(
			(u) => u.email && u.first_name && u.last_name
		);
		return confirmedUsers.map((row) => (
			<TableRow
				key={row.id}
				sx={{
					"&:last-child td, &:last-child th": {
						border: 0,
					},
				}}
			>
				<TableCell component="th" scope="row">
					{row.first_name + " " + row.last_name}
				</TableCell>
				<TableCell align="right">{row.email}</TableCell>
				<TableCell align="right">
					{row.staff ? "Standard" : "Admin"}
				</TableCell>
				{row.staff || props.superAdmin ? (
					<TableCell align="right">
						<button
							className="color-secondary-red"
							onClick={() => {
								if (row.id && (row.staff || props.superAdmin)) {
									onDeleteUserClick(row.id);
									props.setOpen(false);
								} else {
									alert("Kan inte ta bort användare");
								}
							}}
						>
							Ta bort
						</button>
					</TableCell>
				) : (
					<TableCell align="right"> </TableCell>
				)}
			</TableRow>
		));
	}

	if (props.open) {
		return (
			<div>
				<BootstrapDialog
					onClose={() => props.setOpen(false)}
					aria-labelledby="customized-dialog-title"
					open={props.open}
				>
					<div className="min-w-[30vw]">
						<BootstrapDialogTitle
							id="customized-dialog-title"
							onClose={() => props.setOpen(false)}
						></BootstrapDialogTitle>

						<DialogContent>
							<div>
								<Box sx={{ flexGrow: 1 }}>
									<Grid item xs={12} md={6}>
										<Typography
											sx={{ mt: 4, mb: 2 }}
											variant="h6"
											component="div"
										>
											Användare
										</Typography>
										<Paper
											sx={{
												width: "100%",
												overflow: "hidden",
											}}
										>
											{isLoading ? (
												<div className="flex items-center justify-center h-[200px]">
													<CircularProgress />
												</div>
											) : (
												<div>
													{users &&
														users.length > 0 ? (
														<TableContainer
															sx={{
																maxHeight: 440,
															}}
														>
															<Table
																stickyHeader
																aria-label="sticky table"
															>
																<TableHead>
																	<TableRow>
																		<TableCell>
																			<b>
																				Namn
																			</b>
																		</TableCell>
																		<TableCell align="right">
																			<b>
																				Email
																			</b>
																		</TableCell>
																		<TableCell align="right">
																			<b>
																				Nivå
																			</b>
																		</TableCell>
																		<TableCell align="right"></TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{generate()}
																</TableBody>
															</Table>
														</TableContainer>
													) : (
														<p className="p-4">
															Hittade inga användare.
														</p>
													)}
												</div>
											)}
										</Paper>
									</Grid>
								</Box>
							</div>
						</DialogContent>
					</div>
				</BootstrapDialog>
			</div>
		);
	} else {
		return (
			<StandardButton
				text="Visa användare"
				onClick={() => {
					props.setOpen(true);
				}}
			/>
		);
	}
}
