import { useEffect, useState } from "react";
import { StandardButton } from "./StandardButton";
import Box from "@mui/material/Box";
import { SponsorGroup } from "../DBTypes";
import TextField from "@mui/material/TextField";

interface Props {
	groups: SponsorGroup[];
	setGroups: any;
}

export const MultipleInputFields = (props: Props) => {
	const [inputGrouplist, setInputGrouplist] = useState<SponsorGroup[]>([
		{ name: "", prioritizationNumber: 1, organisationNumbers: [] },
	]);
	const [numberTextValue, setNumberTextValue] = useState<string[]>([]);

	const MAX_NO_GROUPS = 10;

	useEffect(() => {
		const sortedGroups = sortGroups([...props.groups]);
		setInputGrouplist(sortedGroups);

		const newList = [...numberTextValue];
		sortedGroups.forEach((group, index) => {
			let string = stringify(group.organisationNumbers);
			newList[index] = string;
		});
		setNumberTextValue(newList);
	}, []);

	useEffect(() => {
		props.setGroups(inputGrouplist);
	}, [inputGrouplist]);

	const sortGroups = (groups: SponsorGroup[]) => {
		const sortedGroups = [...groups].sort(
			(a, b) => a.prioritizationNumber - b.prioritizationNumber
		);

		return updatePrio(sortedGroups);
	};
	const updatePrio = (groups: SponsorGroup[]) => {
		const updatedPrio = groups.map((i, index) => ({
			...i,
			prioritizationNumber: index + 1,
		}));

		return updatedPrio;
	};

	const handleGroupAdd = () => {
		setInputGrouplist([
			...inputGrouplist,
			{
				name: "",
				prioritizationNumber: inputGrouplist.length + 1,
				organisationNumbers: [],
			},
		]);
	};
	const handleGroupRemove = (index: number) => {
		let list = [...inputGrouplist];

		list.splice(index, 1);

		list = updatePrio(list);
		const newList = [...numberTextValue];
		let lastIndex = 0;
		list.forEach((group, index) => {
			let string = stringify(group.organisationNumbers);
			newList[index] = string;
			lastIndex = index;
		});

		newList.splice(lastIndex + 1);
		setNumberTextValue(newList);

		setInputGrouplist(list);
	};

	const handleNameChange = (newName: string, index: number) => {
		let newList = [...inputGrouplist];

		newList[index] = { ...newList[index], name: newName };

		setInputGrouplist(newList);
	};
	const stringify = (array: string[]) => {
		var string = "";
		array.forEach((element) => {
			string += element + "\r\n";
		});
		return string;
	};
	const handleNumberChange = (string: string, index: number) => {
		const str = string.split(/\r?\n/);
		const newStr: string[] = [];
		str.forEach((s) => {
			if (s !== "") newStr.push(s.trim());
		});

		let newList = [...inputGrouplist];

		newList[index] = { ...newList[index], organisationNumbers: newStr };

		setInputGrouplist(newList);
	};

	return (
		<div className="flex flex-col">
			{inputGrouplist.map((singleGroup, index) => (
				<div key={index} className="mb-4">
					<div className="flex items-center space-x-2">
						<div className="w-full mb-2 mt-2">
							<div className="mb-3">
								<TextField
									id="outlined-multiline-flexible"
									label={"Sponsorgruppens namn"}
									value={
										singleGroup.name ? singleGroup.name : ""
									}
									onChange={(e) =>
										handleNameChange(e.target.value, index)
									}
								/>
							</div>
							<Box
								sx={{
									"& .MuiTextField-root": { width: "100%" },
								}}
							>
								<div className="flex ">
									<TextField
										id="outlined-multiline-flexible"
										label="Sponsorers organisationsnummer"
										multiline={true}
										rows={4}
										value={
											numberTextValue[index]
												? numberTextValue[index]
												: ""
										}
										onChange={(e: any) => {
											let newList = [...numberTextValue];
											newList[index] = e.target.value;
											setNumberTextValue(newList);
											handleNumberChange(
												e.target.value,
												index
											);
										}}
									/>
								</div>
							</Box>
						</div>
						{inputGrouplist.length > 1 && (
							<div className="w-12">
								<StandardButton
									text="Ta bort"
									color={"red"}
									onClick={() => handleGroupRemove(index)}
								/>
							</div>
						)}
					</div>
					{inputGrouplist.length - 1 === index &&
						inputGrouplist.length < MAX_NO_GROUPS && (
							<div>
								<StandardButton
									text="Ny sponsorgrupp"
									onClick={() => handleGroupAdd()}
								/>
							</div>
						)}
				</div>
			))}
			{props.groups.length < 1 && (
				<div>
					<StandardButton
						text="Ny sponsorgrupp"
						onClick={() => handleGroupAdd()}
					/>
				</div>
			)}
		</div>
	);
};
