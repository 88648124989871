import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import { CustomInputField } from "./CustomInputField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface Props {
    adminLevel: number;
    setAdminLevel: React.Dispatch<React.SetStateAction<number>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;

}

export const NewSponsorUser = (props: Props) => {
    const { adminLevel, setAdminLevel, email, setEmail } = props
    const handleChange = (event: SelectChangeEvent) => {
        setAdminLevel(Number(event.target.value));
    };

    const AnswerSelect = () => {
        return (
            <div>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Nivå
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={adminLevel.toString()}
                            label="Nivå"
                            onChange={handleChange}
                        >
                            <MenuItem value={"0"}>Standard</MenuItem>
                            <MenuItem value={"1"}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
        );
    }

    return (
        <div className="p-8 mb-6 bg-stone-200 rounded-md flex flex-col">
            <b className="text-lg mb-4">
                Ny användare
            </b>
            <div className="flex mb-6 items-center space-x-2">
                {AnswerSelect()}
                <Tooltip
                    title="Användare med nivå admin har den högsta befogenheten och kan utöver att ändra företagets uppgifter även lägga till användare samt ta bort användare med standardnivån. Standardanvändare kan endast ändra företagets uppgifter."
                    placement="top"
                >
                    <HelpOutlineIcon />
                </Tooltip>
            </div>
            <CustomInputField
                label="Emailadress för ny användare"
                parentCallback={setEmail}
                defaultValue={email}
            />
        </div>
    );
};