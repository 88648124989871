export const CATEGORIES = [
	"Bygg",
	"Fordon",
	"Nöje & Fritid",
	"Mode",
	"Elektronik & IT",
	"Restaurang",
	"Butik",
	"Affärsverksamhet",
	"Barnartiklar",
	"Träning",
	"Hotell",
	"Matchdag",
	"Bostad",
	"Transport & Logistik",
	"Juridik",
	"Bank & Ekonomi",
	"Försäkring",
	"Industri",
	"Vård",
	"Resor",
	"Bemanning",
	"Marknadsföring & Kommunikation"
];
