import React, { useState, useEffect } from "react";
import { Nav } from "../components/Nav";
import { Header } from "../components/Header";
import { GridMenu } from "../components/GridMenu";
import { ParterGrid } from "../components/ParterGrid";
import { useFirestore } from "../hooks/useFirestore";
import { ParterData } from "../components/ParterData";
import { CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { BannerAd } from "../components/BannerAd";

export const SearchResultsPage = () => {
	const [searchParams] = useSearchParams();
	const { searchCompany, searchClub } = useFirestore();

	const searchPhrase = searchParams.get("q");
	const searchCategory = searchParams.get("category");

	const [data, setData] = useState<ParterData[]>();
	const [title, setTitle] = useState<string>("");

	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	function RenderResults() {
		if (errorMessage) {
			return <div className="text-white p-20">{errorMessage}</div>;
		} else if (data) {
			if (data.length > 0) {
				return <ParterGrid cards={data} />;
			}
		}
	}

	useEffect(() => {
		let cards: ParterData[] = [];
		setData([]);
		setIsLoading(true);
		setErrorMessage("");

		if (searchPhrase !== null && searchCategory) {
			setTitle(searchCategory);
			if (searchCategory === "Föreningar") {
				searchClub(searchPhrase)
					.then((resp) => {
						resp.forEach((club) => {
							const card: ParterData = {
								type: "club",
								object: club,
							};
							cards.push(card);
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else if (searchCategory === "Sponsorer") {
				searchCompany(searchPhrase)
					.then((resp) => {
						resp.forEach((company) => {
							const card: ParterData = {
								type: "company",
								object: company,
							};
							cards.push(card);
						});
						setData(cards);
					})
					.catch((error) => {
						setErrorMessage(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				setData([]);
				setIsLoading(false);
			}
		}
	}, [searchPhrase, searchCategory]);

	return (
		<div>
			<Nav />

			{/*Header*/}
			<div className={""}>
				<Header />
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />

			<GridMenu title={title} />

			{/*CARD GRID*/}

			<div className={"card-grid"}>
				{isLoading ? (
					<CircularProgress color="inherit" />
				) : (
					<div>{RenderResults()}</div>
				)}
			</div>
		</div>
	);
};
