import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFirestore } from "../hooks/useFirestore";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function DeleteDialog() {
	const navigate = useNavigate();
	const [open, setOpen] = useState<boolean>(false);
	const [deleteStatus, setDeleteStatus] = useState<string>("");
	const { ad_id } = useParams<string>();
	const { deleteAd } = useFirestore();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleDelete = () => {
		handleClose();
		if (ad_id) {
			deleteAd(ad_id)
				.then(() => {
					setDeleteStatus("Tog bort erbjudandet.");
				})
				.catch((error) => {
					setDeleteStatus(error);
				});
		}
	};

	useEffect(() => {
		if (deleteStatus) {
			alert(deleteStatus);
			navigate(-1);
		}
	}, [deleteStatus]);

	return (
		<div>
			<DeleteIcon onClick={handleClickOpen}></DeleteIcon>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Vill du ta bort erbjudandet?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Är du säker på att du vill ta bort erbjudandet? Detta
						går inte att ångra.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Avbryt</Button>
					<Button onClick={handleDelete}>Ta bort erbjudande</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
