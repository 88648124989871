import * as React from "react";
import { Nav } from "../components/Nav";
import { BannerAd } from "../components/BannerAd";
import videoBg from "../videos/About_Parter.mp4";
import { Link, useNavigate } from "react-router-dom";
import { usePageNavigation } from "../hooks/usePageNavigation";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import parterLogo from "../icons/main-logo.svg";

const ButtonMailto = ({ email, subject = "", body = "", label }: any) => {
	let params = subject || body ? "?" : "";
	if (subject) params += `subject=${encodeURIComponent(subject)}`;
	if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

	return (
		<a className="text-[#1e9ede]" href={`mailto:${email}${params}`}>
			{label}
		</a>
	);
};

/**
 * About page
 */
export const AboutPage = () => {
	const { navigateToPolicyPage } = usePageNavigation();
	return (
		<div>
			<Nav />

			{/*Header*/}
			<div className="video-background">
				<div className={"video-header"}>
					<video
						className={"videoInsert"}
						src={videoBg}
						autoPlay
						loop
						muted
						webkit-playsinline
					/>
				</div>
			</div>

			{/*Ad banner component, pass "show = true" to show on page*/}
			<BannerAd />

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						paddingLeft: "60px",
						paddingRight: "60px",
					}}
				>
					<h1
						style={{
							fontSize: "30px",
							marginBottom: "20px",
							marginTop: "20px",
						}}
					>
						Om oss
					</h1>
					<p>
						Föreställ dig en triangel med en byggsten i varje hörn.
						Varje byggsten ihopkopplad med en länk och beroende av
						varandra för att kunna verka. Parter.se är länken mellan
						dessa byggstenar. Vi drivs av att förenkla processerna
						och stärka banden mellan föreningar, sponsorer och
						föreningsmedlemmar. Med sin gemenskap, glädje och
						engagemang är föreningslivet något av det finaste vi har
						i Sverige. Vi är ett dedikerat team som jobbar varje dag
						för att fortsätta utveckla föreningslivets möjligheter.
					</p>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						marginTop: "50px",
						alignItems: "center",
						justifyContent: "space-evenly",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							flexBasis: "25%",
							height: "350px",
						}}
					>
						<SportsSoccerOutlinedIcon
							style={{ color: "#1f9edd", fontSize: "100px" }}
						/>
						<h1 style={{ color: "#1f9edd", fontSize: "28px" }}>
							Förening
						</h1>
						<p
							style={{
								maxWidth: "100%",
								wordBreak: "break-word",
								marginTop: "20px",
							}}
						>
							Få fler medlemmar och sponsorer genom ett vässat
							tjänsteerbjudande. Skapa en digital samlingsplats
							där ni ger möjligheten till affärsskapande och
							stärkta relationer.
						</p>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							flexBasis: "25%",
							height: "350px",
						}}
					>
						<VolunteerActivismOutlinedIcon
							style={{ color: "#1f9edd ", fontSize: "100px" }}
						/>
						<h1 style={{ color: "#1f9edd ", fontSize: "28px" }}>
							Sponsor/Företag
						</h1>
						<p
							style={{
								maxWidth: "100%",
								wordBreak: "break-word",
								marginTop: "20px",
							}}
						>
							Exponeras ytterligare tillsammans med din förening.
							Skapa via vårt enkla verktyg ett erbjudande till
							andra sponsorer eller medlemmar. Sponsrar du inte en
							förening idag? Klicka på Bli sponsor inne på
							förenings partersida så sätter vi er i kontakt.
						</p>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							flexBasis: "25%",
							height: "350px",
						}}
					>
						<GroupsOutlinedIcon
							style={{ color: "#1f9edd", fontSize: "100px" }}
						/>
						<h1 style={{ color: "#1f9edd", fontSize: "28px" }}>
							Medlem
						</h1>
						<p
							style={{
								maxWidth: "100%",
								wordBreak: "break-word",
								marginTop: "20px",
							}}
						>
							Stötta din förening genom att ta del av aktuella
							erbjudanden och rabatter. Är du inte medlem i någon
							förening – Klicka på Bli medlem knappen hos den
							förening där du vill nyttja erbjudanden.
						</p>
					</div>
				</div>
				<p style={{ fontSize: "24px" }}>
					Finns inte din förening?{" "}
					<ButtonMailto
						email="Patrik@parter.se"
						subject="Min förening saknas!"
						body="Hej! Min förening som heter *föreningsnamn* saknas och jag skulle vilja att de/vi hade en egen partersida."
						label="Klicka här"
					/>
				</p>

				<div
					style={{
						bottom: 0,
						left: 0,
						marginTop: "80px",
						width: "100%",
						height: "260px",
						backgroundColor: "#1f9edd",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<img
							src={parterLogo}
							alt="Parter"
							style={{
								height: "160px",
								width: "160px",
								marginLeft: "50px",
							}}
						/>

						<div
							style={{
								display: "flex",
								flexDirection: "row",

								justifyContent: "space-between",
								marginLeft: "150px",
								marginRight: "300px",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<p
									style={{
										color: "white",
										fontSize: "24px",
										marginBottom: "15px",
									}}
								>
									Adress
								</p>
								<p style={{ color: "white" }}>Parter AB</p>
								<p style={{ color: "white" }}>
									Nordgårdsgatan 2
								</p>
								<p style={{ color: "white" }}>
									412 85 Göteborg
								</p>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<p
									style={{
										color: "white",
										fontSize: "24px",
										marginBottom: "15px",
									}}
								>
									Kontakt
								</p>
								<p style={{ color: "white" }}>
									<span>
										<EmailOutlinedIcon
											style={{
												color: "white",
												height: "16px",
												width: "16px",
												marginRight: "5px",
											}}
										/>
									</span>
									<a href="mailto: Hej@parter.se">
										Hej@parter.se
									</a>
								</p>
								<p style={{ color: "white" }}>
									<span>
										<LocalPhoneOutlinedIcon
											style={{
												color: "white",
												height: "16px",
												width: "16px",
												marginRight: "5px",
											}}
										/>
									</span>
									0704929923
								</p>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",

							marginLeft: "80px",
						}}
					>
						<a
							href="https://parter.se/privacy_policy"
							target="_blank"
							style={{
								color: "white",
								fontSize: "18px",
								textDecoration: "underline",
							}}
						>
							Integritetspolicy
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
